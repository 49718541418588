<template>
  <TransitionRoot appear :show="open" @close="closeModal()">
    <Dialog class="fixed inset-0 z-40 overflow-hidden">
      <TransitionChild
        as="template"
        enter="duration-250 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-250 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/60" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="duration-250 ease-out"
        enter-from="opacity-0 scale-90"
        enter-to="opacity-100 scale-100"
        leave="duration-250 ease-in"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-90"
      >
        <DialogPanel class="flex min-h-screen items-center justify-center">
          <ShadowBox
            class="custom-table-scroll mx-2 mt-24 flex h-120 w-full items-center justify-center overflow-hidden !rounded-[20px] sm:mt-16 sm:w-16/20 md:w-14/20 lg:w-12/20 xl:w-11/20"
          >
            <div
              class="h-full w-1/2 bg-[url('@/assets/guided_tour/guided_tour_bg.jpg')] bg-cover bg-center bg-no-repeat px-7 py-9"
            >
              <span
                class="mb-7 inline-flex rounded-full border-2 border-violet-500 px-2 py-1.5 text-center font-artex text-xs font-medium leading-none text-violet-500"
              >
                NEW MENU
              </span>
              <div class="relative h-88 w-100 max-w-full overflow-hidden">
                <img
                  v-for="(image, index) in images"
                  :key="index"
                  :src="image"
                  alt="Slidable Image"
                  class="absolute inset-y-0 m-auto h-auto w-full transition-opacity duration-500"
                  :class="{
                    'opacity-0': index !== currentIndex,
                    'opacity-100': index === currentIndex,
                  }"
                />
              </div>
            </div>
            <div class="relative h-full w-1/2 overflow-hidden px-12 pb-9 pt-16">
              <button
                class="absolute right-0 top-0 mr-6 mt-7 text-gray-700 transition duration-200 hover:text-gray-900 focus:outline-none focus:ring-1"
                aria-label="Close modal"
                @click="
                  () => {
                    trackAsEvent('menu_guided_popup_close');
                    closeModal();
                  }
                "
              >
                <Icon name="times" class="h-5 w-5" />
              </button>

              <div
                v-for="(content, index) in GuidedMenu"
                :key="index"
                class="absolute h-auto w-full pr-24 transition-opacity duration-500"
                :class="{
                  'z-0 opacity-0': index !== currentIndex,
                  'z-50 opacity-100': index === currentIndex,
                }"
              >
                <h1 class="mb-7 font-artex text-2xl font-bold">
                  {{ content.title }}
                </h1>
                <!-- eslint-disable vue/no-v-html  -->
                <div
                  class="mb-7 min-h-36"
                  v-html="DOMPurify.sanitize(content.description || '')"
                ></div>
                <div class="mt-14 flex items-center justify-start gap-2">
                  <button
                    v-for="btnIndex in GuidedMenu.length"
                    :key="btnIndex"
                    class="h-2 w-2 rounded-full bg-gray-200 transition-all duration-300"
                    :class="[
                      {
                        'bg-gray-800 ring-1 ring-gray-200':
                          btnIndex - 1 === currentIndex,
                      },
                    ]"
                    @click="
                      () => {
                        trackAsEvent('menu_guided_popup_indicators');
                        indicatorClick(btnIndex - 1);
                      }
                    "
                  ></button>
                </div>
              </div>
              <div class="absolute bottom-0 right-0 mb-7 flex">
                <button
                  v-if="currentIndex !== 0"
                  aria-label="Previous slide"
                  class="btn-primary mr-6 bg-gray-300 px-4 ring-gray-300 hover:bg-gray-400 focus:ring-gray-400 active:bg-gray-400"
                  @click="
                    () => {
                      trackAsEvent('menu_guided_popup_previous');
                      previousContent();
                    }
                  "
                >
                  Previous
                </button>
                <button
                  v-if="currentIndex < GuidedMenu.length - 1"
                  aria-label="Next slide"
                  class="btn-primary mr-6 px-8"
                  @click="
                    () => {
                      trackAsEvent('menu_guided_popup_next');
                      changeContent();
                    }
                  "
                >
                  Next
                </button>
                <button
                  v-else
                  aria-label="Finish"
                  class="btn-primary mr-6 px-8"
                  @click="
                    () => {
                      trackAsEvent('menu_guided_popup_finish');
                      closeModal();
                    }
                  "
                >
                  Finish
                </button>
              </div>
            </div>
          </ShadowBox>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
<script setup lang="ts">
import ShadowBox from "@/components/Core/ShadowBox.vue";
import {
  Dialog,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";
import { computed, ref, watch } from "vue";
import Icon from "@/components/Core/Icon.vue";
import DOMPurify from "dompurify";
import { images, GuidedMenu } from "@/utils/menu_guide";
import { useTrack } from "@/services/gtm";

defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits({
  "update:menuItem": (_menuItem: string) => true,
  "update:open": (_open: boolean) => true,
});

function closeModal(): void {
  emit("update:open", false);
  emit("update:menuItem", "");
  localStorage.setItem("dismissedMenuGuidePopup", "true");
}

const currentIndex = ref(0);

function changeContent() {
  if (currentIndex.value < GuidedMenu.length - 1) {
    currentIndex.value = currentIndex.value + 1;
  } else {
    currentIndex.value = 0;
  }
}
function previousContent() {
  if (currentIndex.value !== 0) {
    currentIndex.value = currentIndex.value - 1;
  }
}
const trackAsEvent = useTrack();
function indicatorClick(index: number) {
  currentIndex.value = index;
}
const currentMenu = computed(() => {
  return GuidedMenu[currentIndex.value].id;
});
watch(
  () => currentMenu.value,
  (menuItem) => {
    emit("update:menuItem", menuItem);
  },
  { immediate: true },
);
</script>
