import api from "@/services/api";
import {
  TemporalDataFilter,
  ResourcesDatafilter,
  TimeUnit,
  SeparateResourcesFilter,
  TagsDataFilter,
  SelectionTemporalDataFilter,
  DateRangeSelection,
  IsConversionEventFilter,
  TemporalPeriodsType,
} from "@/services/data_filters";
import {
  useCalculatedResources,
  useDetachedTemporalFilter,
  useDataFilters,
  useDetachedTagFilter,
  useDetachedSelectionTemporalFilter,
  useDataFiltersStore,
} from "@/store/data_filters";
import {
  TimeSeriesDataset,
  ResponseTimeSeriesDatasets,
} from "@/utils/datasets";
import { QueryFunctionContext } from "@/utils/typing";
import { computed, reactive, Ref, ref } from "vue";
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/vue-query";
import { useResetPage } from "@/services/utils";
import { HeatmapWeekdayData } from "@/utils/heatmap";
import { metricDetails, MetricKey } from "@/services/metrics";
import {
  PublicDashboardWidgetFilters,
  usePublicDashboardWidgetQueryFilters,
} from "@/services/public_dashboard";
import { isAxiosError } from "axios";
import { sentenceCase } from "@/utils/strings";

// Note: Alphabetical
export const ANALYTICS4_DIMENSIONS = [
  "achievementId",
  "adSourceName",
  "adUnitName",
  "appVersion",
  "attributionDefaultChannelGrouping",
  "attributionMedium",
  "attributionSource",
  "attributionSourcePlatform",
  "audienceId",
  "audienceName",
  "brandingInterest",
  "browser",
  "campaign",
  "campaignId",
  "campaignName",
  "character",
  "city",
  "cityId",
  "cm360AccountId",
  "cm360AccountName",
  "cm360AdvertiserId",
  "cm360AdvertiserName",
  "cm360CampaignId",
  "cm360CampaignName",
  "cm360CreativeFormat",
  "cm360CreativeId",
  "cm360CreativeName",
  "cm360CreativeType",
  "cm360CreativeTypeId",
  "cm360CreativeVersion",
  "cm360Medium",
  "cm360PlacementCostStructure",
  "cm360PlacementId",
  "cm360PlacementName",
  "cm360RenderingId",
  "cm360SiteId",
  "cm360SiteName",
  "cm360Source",
  "cm360SourceMedium",
  "content",
  "contentGroup",
  "contentId",
  "contentType",
  "continent",
  "continentId",
  "country",
  "countryId",
  "creativeFormat",
  "currencyCode",
  "date",
  "dateHour",
  "dateHourMinute",
  "day",
  "dayOfWeek",
  "dayOfWeekName",
  "defaultChannelGrouping",
  "deviceCategory",
  "deviceModel",
  "dv360AdvertiserId",
  "dv360AdvertiserName",
  "dv360CampaignId",
  "dv360CampaignName",
  "dv360CreativeFormat",
  "dv360CreativeId",
  "dv360CreativeName",
  "dv360ExchangeId",
  "dv360ExchangeName",
  "dv360InsertionOrderId",
  "dv360InsertionOrderName",
  "dv360LineItemId",
  "dv360LineItemName",
  "dv360Medium",
  "dv360PartnerId",
  "dv360PartnerName",
  "dv360Source",
  "dv360SourceMedium",
  "eventName",
  "fileExtension",
  "fileName",
  "firstSessionDate",
  "firstUserCampaignId",
  "firstUserCampaignName",
  "firstUserCm360AccountId",
  "firstUserCm360AccountName",
  "firstUserCm360AdvertiserId",
  "firstUserCm360AdvertiserName",
  "firstUserCm360CampaignId",
  "firstUserCm360CampaignName",
  "firstUserCm360CreativeFormat",
  "firstUserCm360CreativeId",
  "firstUserCm360CreativeName",
  "firstUserCm360CreativeType",
  "firstUserCm360CreativeTypeId",
  "firstUserCm360CreativeVersion",
  "firstUserCm360Medium",
  "firstUserCm360PlacementCostStructure",
  "firstUserCm360PlacementId",
  "firstUserCm360PlacementName",
  "firstUserCm360RenderingId",
  "firstUserCm360SiteId",
  "firstUserCm360SiteName",
  "firstUserCm360Source",
  "firstUserCm360SourceMedium",
  "firstUserDefaultChannelGrouping",
  "firstUserDv360AdvertiserId",
  "firstUserDv360AdvertiserName",
  "firstUserDv360CampaignId",
  "firstUserDv360CampaignName",
  "firstUserDv360CreativeFormat",
  "firstUserDv360CreativeId",
  "firstUserDv360CreativeName",
  "firstUserDv360ExchangeId",
  "firstUserDv360ExchangeName",
  "firstUserDv360InsertionOrderId",
  "firstUserDv360InsertionOrderName",
  "firstUserDv360LineItemId",
  "firstUserDv360LineItemName",
  "firstUserDv360Medium",
  "firstUserDv360PartnerId",
  "firstUserDv360PartnerName",
  "firstUserDv360Source",
  "firstUserDv360SourceMedium",
  "firstUserGoogleAdsAccountName",
  "firstUserGoogleAdsAdGroupId",
  "firstUserGoogleAdsAdGroupName",
  "firstUserGoogleAdsAdNetworkType",
  "firstUserGoogleAdsCampaignId",
  "firstUserGoogleAdsCampaignName",
  "firstUserGoogleAdsCampaignType",
  "firstUserGoogleAdsCreativeId",
  "firstUserGoogleAdsCustomerId",
  "firstUserGoogleAdsKeyword",
  "firstUserGoogleAdsQuery",
  "firstUserManualAdContent",
  "firstUserManualCampaignId",
  "firstUserManualCampaignName",
  "firstUserManualCreativeFormat",
  "firstUserManualMarketingTactic",
  "firstUserManualMedium",
  "firstUserManualSource",
  "firstUserManualSourceMedium",
  "firstUserManualSourcePlatform",
  "firstUserManualTerm",
  "firstUserMedium",
  "firstUserPrimaryChannelGroup",
  "firstUserSa360AdGroupId",
  "firstUserSa360AdGroupName",
  "firstUserSa360CampaignId",
  "firstUserSa360CampaignName",
  "firstUserSa360CreativeFormat",
  "firstUserSa360EngineAccountId",
  "firstUserSa360EngineAccountName",
  "firstUserSa360EngineAccountType",
  "firstUserSa360KeywordText",
  "firstUserSa360ManagerAccountId",
  "firstUserSa360ManagerAccountName",
  "firstUserSa360Medium",
  "firstUserSa360Query",
  "firstUserSa360Source",
  "firstUserSa360SourceMedium",
  "firstUserSource",
  "firstUserSourceMedium",
  "firstUserSourcePlatform",
  "fullPageUrl",
  "googleAdsAccountName",
  "googleAdsAdGroupId",
  "googleAdsAdGroupName",
  "googleAdsAdNetworkType",
  "googleAdsCampaignId",
  "googleAdsCampaignName",
  "googleAdsCampaignType",
  "googleAdsCreativeId",
  "googleAdsCustomerId",
  "googleAdsKeyword",
  "googleAdsQuery",
  "groupId",
  "hostName",
  "hour",
  "isConversionEvent",
  "isoWeek",
  "isoYear",
  "isoYearIsoWeek",
  "itemAffiliation",
  "itemBrand",
  "itemCategory",
  "itemCategory2",
  "itemCategory3",
  "itemCategory4",
  "itemCategory5",
  "itemId",
  "itemListId",
  "itemListName",
  "itemListPosition",
  "itemLocationId",
  "itemName",
  "itemPromotionCreativeName",
  "itemPromotionCreativeSlot",
  "itemPromotionId",
  "itemPromotionName",
  "itemVariant",
  "landingPage",
  "landingPagePlusQueryString",
  "language",
  "languageCode",
  "level",
  "linkClasses",
  "linkDomain",
  "linkId",
  "linkText",
  "linkUrl",
  "manualAdContent",
  "manualCampaignId",
  "manualCampaignName",
  "manualCreativeFormat",
  "manualMarketingTactic",
  "manualMedium",
  "manualSource",
  "manualSourceMedium",
  "manualSourcePlatform",
  "manualTerm",
  "medium",
  "method",
  "minute",
  "mobileDeviceBranding",
  "mobileDeviceMarketingName",
  "mobileDeviceModel",
  "month",
  "newVsReturning",
  "nthDay",
  "nthHour",
  "nthMinute",
  "nthMonth",
  "nthWeek",
  "nthYear",
  "operatingSystem",
  "operatingSystemVersion",
  "operatingSystemWithVersion",
  "orderCoupon",
  "outbound",
  "page",
  "pageLocation",
  "pagePath",
  "pagePathPlusQueryString",
  "pageReferrer",
  "pageTitle",
  "percentScrolled",
  "platform",
  "platformDeviceCategory",
  "primaryChannelGroup",
  "region",
  "sa360AdGroupId",
  "sa360AdGroupName",
  "sa360CampaignId",
  "sa360CampaignName",
  "sa360CreativeFormat",
  "sa360EngineAccountId",
  "sa360EngineAccountName",
  "sa360EngineAccountType",
  "sa360KeywordText",
  "sa360ManagerAccountId",
  "sa360ManagerAccountName",
  "sa360Medium",
  "sa360Query",
  "sa360Source",
  "sa360SourceMedium",
  "screenResolution",
  "searchTerm",
  "sessionCampaignId",
  "sessionCm360AccountId",
  "sessionCm360AccountName",
  "sessionCm360AdvertiserId",
  "sessionCm360AdvertiserName",
  "sessionCm360CampaignId",
  "sessionCm360CampaignName",
  "sessionCm360CreativeFormat",
  "sessionCm360CreativeId",
  "sessionCm360CreativeName",
  "sessionCm360CreativeType",
  "sessionCm360CreativeTypeId",
  "sessionCm360CreativeVersion",
  "sessionCm360Medium",
  "sessionCm360PlacementCostStructure",
  "sessionCm360PlacementId",
  "sessionCm360PlacementName",
  "sessionCm360RenderingId",
  "sessionCm360SiteId",
  "sessionCm360SiteName",
  "sessionCm360Source",
  "sessionCm360SourceMedium",
  "sessionDv360AdvertiserId",
  "sessionDv360AdvertiserName",
  "sessionDv360CampaignId",
  "sessionDv360CampaignName",
  "sessionDv360CreativeFormat",
  "sessionDv360CreativeId",
  "sessionDv360CreativeName",
  "sessionDv360ExchangeId",
  "sessionDv360ExchangeName",
  "sessionDv360InsertionOrderId",
  "sessionDv360InsertionOrderName",
  "sessionDv360LineItemId",
  "sessionDv360LineItemName",
  "sessionDv360Medium",
  "sessionDv360PartnerId",
  "sessionDv360PartnerName",
  "sessionDv360Source",
  "sessionDv360SourceMedium",
  "sessionGoogleAdsAccountName",
  "sessionGoogleAdsAdGroupId",
  "sessionGoogleAdsAdGroupName",
  "sessionGoogleAdsAdNetworkType",
  "sessionGoogleAdsCampaignId",
  "sessionGoogleAdsCampaignName",
  "sessionGoogleAdsCampaignType",
  "sessionGoogleAdsCreativeId",
  "sessionGoogleAdsCustomerId",
  "sessionGoogleAdsKeyword",
  "sessionGoogleAdsQuery",
  "sessionManualCampaignId",
  "sessionManualCampaignName",
  "sessionManualCreativeFormat",
  "sessionManualMarketingTactic",
  "sessionManualMedium",
  "sessionManualSource",
  "sessionManualSourceMedium",
  "sessionManualSourcePlatform",
  "sessionPrimaryChannelGroup",
  "sessionSa360AdGroupId",
  "sessionSa360AdGroupName",
  "sessionSa360CampaignId",
  "sessionSa360CampaignName",
  "sessionSa360CreativeFormat",
  "sessionSa360EngineAccountId",
  "sessionSa360EngineAccountName",
  "sessionSa360EngineAccountType",
  "sessionSa360Keyword",
  "sessionSa360ManagerAccountId",
  "sessionSa360ManagerAccountName",
  "sessionSa360Medium",
  "sessionSa360Query",
  "sessionSa360Source",
  "sessionSa360SourceMedium",
  "sessionSourceMedium",
  "shippingTier",
  "signedInWithUserId",
  "source",
  "sourceMedium",
  "sourcePlatform",
  "streamId",
  "streamName",
  "term",
  "testDataFilterId",
  "testDataFilterName",
  "transactionId",
  "unifiedPagePathScreen",
  "unifiedPageScreen",
  "unifiedScreenClass",
  "unifiedScreenName",
  "userAgeBracket",
  "userGender",
  "videoProvider",
  "videoTitle",
  "videoUrl",
  "virtualCurrencyName",
  "visible",
  "week",
  "year",
  "yearMonth",
  "yearWeek",
] as const;
export type DimensionKey = (typeof ANALYTICS4_DIMENSIONS)[number];

export const GROUPED_ANALYTICS4_DIMENSIONS: Record<
  string,
  (typeof ANALYTICS4_DIMENSIONS)[number][]
> = {
  traffic: [
    "source",
    "medium",
    "campaign",
    "content",
    "term",
    "creativeFormat",
    "sourcePlatform",
    "defaultChannelGrouping",
  ],
  technology: ["browser", "deviceCategory", "operatingSystem"],
  geography: ["city", "country"],
  pages: ["page", "landingPage"],
};
// TODO: Uncomment when the UI can reasonable handle this many dimensions.
// export const GROUPED_ANALYTICS4_DIMENSIONS: Record<
//   string,
//   (typeof ANALYTICS4_DIMENSIONS)[number][]
// > = {
//   Attribution: [
//     "attributionDefaultChannelGrouping",
//     "attributionMedium",
//     "attributionSource",
//     "attributionSourcePlatform",
//     "campaignId",
//     "campaignName",
//     "cm360AccountId",
//     "cm360AccountName",
//     "cm360AdvertiserId",
//     "cm360AdvertiserName",
//     "cm360CampaignId",
//     "cm360CampaignName",
//     "cm360CreativeFormat",
//     "cm360CreativeId",
//     "cm360CreativeName",
//     "cm360CreativeType",
//     "cm360CreativeTypeId",
//     "cm360CreativeVersion",
//     "cm360Medium",
//     "cm360PlacementCostStructure",
//     "cm360PlacementId",
//     "cm360PlacementName",
//     "cm360RenderingId",
//     "cm360SiteId",
//     "cm360SiteName",
//     "cm360Source",
//     "cm360SourceMedium",
//     "dv360AdvertiserId",
//     "dv360AdvertiserName",
//     "dv360CampaignId",
//     "dv360CampaignName",
//     "dv360CreativeFormat",
//     "dv360CreativeId",
//     "dv360CreativeName",
//     "dv360ExchangeId",
//     "dv360ExchangeName",
//     "dv360InsertionOrderId",
//     "dv360InsertionOrderName",
//     "dv360LineItemId",
//     "dv360LineItemName",
//     "dv360Medium",
//     "dv360PartnerId",
//     "dv360PartnerName",
//     "dv360Source",
//     "dv360SourceMedium",
//     "googleAdsAccountName",
//     "googleAdsAdGroupId",
//     "googleAdsAdGroupName",
//     "googleAdsAdNetworkType",
//     "googleAdsCampaignId",
//     "googleAdsCampaignName",
//     "googleAdsCampaignType",
//     "googleAdsCreativeId",
//     "googleAdsCustomerId",
//     "googleAdsKeyword",
//     "googleAdsQuery",
//     "manualAdContent",
//     "manualCampaignId",
//     "manualCampaignName",
//     "manualCreativeFormat",
//     "manualMarketingTactic",
//     "manualMedium",
//     "manualSource",
//     "manualSourceMedium",
//     "manualSourcePlatform",
//     "manualTerm",
//     "primaryChannelGroup",
//     "sa360AdGroupId",
//     "sa360AdGroupName",
//     "sa360CampaignId",
//     "sa360CampaignName",
//     "sa360CreativeFormat",
//     "sa360EngineAccountId",
//     "sa360EngineAccountName",
//     "sa360EngineAccountType",
//     "sa360KeywordText",
//     "sa360ManagerAccountId",
//     "sa360ManagerAccountName",
//     "sa360Medium",
//     "sa360Query",
//     "sa360Source",
//     "sa360SourceMedium",
//     "sourceMedium",
//   ],
//   Demographics: ["brandingInterest", "userAgeBracket", "userGender"],
//   Ecommerce: [
//     "currencyCode",
//     "itemAffiliation",
//     "itemBrand",
//     "itemCategory",
//     "itemCategory2",
//     "itemCategory3",
//     "itemCategory4",
//     "itemCategory5",
//     "itemId",
//     "itemListId",
//     "itemListName",
//     "itemListPosition",
//     "itemLocationId",
//     "itemName",
//     "itemPromotionCreativeName",
//     "itemPromotionCreativeSlot",
//     "itemPromotionId",
//     "itemPromotionName",
//     "itemVariant",
//     "orderCoupon",
//     "shippingTier",
//     "transactionId",
//   ],
//   Event: ["eventName", "isConversionEvent"],
//   Gaming: ["achievementId", "character", "level", "virtualCurrencyName"],
//   General: [
//     "fileExtension",
//     "fileName",
//     "groupId",
//     "method",
//     "percentScrolled",
//     "searchTerm",
//     "testDataFilterId",
//     "testDataFilterName",
//     "visible",
//   ],
//   Geography: [
//     "city",
//     "cityId",
//     "continent",
//     "continentId",
//     "country",
//     "countryId",
//     "region",
//   ],
//   Link: [
//     "linkClasses",
//     "linkDomain",
//     "linkId",
//     "linkText",
//     "linkUrl",
//     "outbound",
//   ],
//   "Page / Screen": [
//     "contentGroup",
//     "contentId",
//     "contentType",
//     "fullPageUrl",
//     "hostName",
//     "landingPage",
//     "landingPagePlusQueryString",
//     "page",
//     "pageLocation",
//     "pagePathPlusQueryString",
//     "pageReferrer",
//     "pageTitle",
//     "unifiedPagePathScreen",
//     "unifiedPageScreen",
//     "unifiedScreenClass",
//     "unifiedScreenName",
//   ],
//   "Platform / Device": [
//     "appVersion",
//     "browser",
//     "deviceCategory",
//     "deviceModel",
//     "language",
//     "languageCode",
//     "mobileDeviceBranding",
//     "mobileDeviceMarketingName",
//     "mobileDeviceModel",
//     "operatingSystem",
//     "operatingSystemVersion",
//     "operatingSystemWithVersion",
//     "platform",
//     "platformDeviceCategory",
//     "screenResolution",
//     "streamId",
//     "streamName",
//   ],
//   Publisher: ["adSourceName", "adUnitName", "creativeFormat"],
//   Time: [
//     "date",
//     "dateHour",
//     "dateHourMinute",
//     "day",
//     "dayOfWeek",
//     "dayOfWeekName",
//     "hour",
//     "isoWeek",
//     "isoYear",
//     "isoYearIsoWeek",
//     "minute",
//     "month",
//     "nthDay",
//     "nthHour",
//     "nthMinute",
//     "nthMonth",
//     "nthWeek",
//     "nthYear",
//     "week",
//     "year",
//     "yearMonth",
//     "yearWeek",
//   ],
//   "Traffic Source": [
//     "campaign",
//     "content",
//     "defaultChannelGrouping",
//     "firstUserCampaignId",
//     "firstUserCampaignName",
//     "firstUserCm360AccountId",
//     "firstUserCm360AccountName",
//     "firstUserCm360AdvertiserId",
//     "firstUserCm360AdvertiserName",
//     "firstUserCm360CampaignId",
//     "firstUserCm360CampaignName",
//     "firstUserCm360CreativeFormat",
//     "firstUserCm360CreativeId",
//     "firstUserCm360CreativeName",
//     "firstUserCm360CreativeType",
//     "firstUserCm360CreativeTypeId",
//     "firstUserCm360CreativeVersion",
//     "firstUserCm360Medium",
//     "firstUserCm360PlacementCostStructure",
//     "firstUserCm360PlacementId",
//     "firstUserCm360PlacementName",
//     "firstUserCm360RenderingId",
//     "firstUserCm360SiteId",
//     "firstUserCm360SiteName",
//     "firstUserCm360Source",
//     "firstUserCm360SourceMedium",
//     "firstUserDefaultChannelGrouping",
//     "firstUserDv360AdvertiserId",
//     "firstUserDv360AdvertiserName",
//     "firstUserDv360CampaignId",
//     "firstUserDv360CampaignName",
//     "firstUserDv360CreativeFormat",
//     "firstUserDv360CreativeId",
//     "firstUserDv360CreativeName",
//     "firstUserDv360ExchangeId",
//     "firstUserDv360ExchangeName",
//     "firstUserDv360InsertionOrderId",
//     "firstUserDv360InsertionOrderName",
//     "firstUserDv360LineItemId",
//     "firstUserDv360LineItemName",
//     "firstUserDv360Medium",
//     "firstUserDv360PartnerId",
//     "firstUserDv360PartnerName",
//     "firstUserDv360Source",
//     "firstUserDv360SourceMedium",
//     "firstUserGoogleAdsAccountName",
//     "firstUserGoogleAdsAdGroupId",
//     "firstUserGoogleAdsAdGroupName",
//     "firstUserGoogleAdsAdNetworkType",
//     "firstUserGoogleAdsCampaignId",
//     "firstUserGoogleAdsCampaignName",
//     "firstUserGoogleAdsCampaignType",
//     "firstUserGoogleAdsCreativeId",
//     "firstUserGoogleAdsCustomerId",
//     "firstUserGoogleAdsKeyword",
//     "firstUserGoogleAdsQuery",
//     "firstUserManualAdContent",
//     "firstUserManualCampaignId",
//     "firstUserManualCampaignName",
//     "firstUserManualCreativeFormat",
//     "firstUserManualMarketingTactic",
//     "firstUserManualMedium",
//     "firstUserManualSource",
//     "firstUserManualSourceMedium",
//     "firstUserManualSourcePlatform",
//     "firstUserManualTerm",
//     "firstUserMedium",
//     "firstUserPrimaryChannelGroup",
//     "firstUserSa360AdGroupId",
//     "firstUserSa360AdGroupName",
//     "firstUserSa360CampaignId",
//     "firstUserSa360CampaignName",
//     "firstUserSa360CreativeFormat",
//     "firstUserSa360EngineAccountId",
//     "firstUserSa360EngineAccountName",
//     "firstUserSa360EngineAccountType",
//     "firstUserSa360KeywordText",
//     "firstUserSa360ManagerAccountId",
//     "firstUserSa360ManagerAccountName",
//     "firstUserSa360Medium",
//     "firstUserSa360Query",
//     "firstUserSa360Source",
//     "firstUserSa360SourceMedium",
//     "firstUserSource",
//     "firstUserSourceMedium",
//     "firstUserSourcePlatform",
//     "medium",
//     "sessionCampaignId",
//     "sessionCm360AccountId",
//     "sessionCm360AccountName",
//     "sessionCm360AdvertiserId",
//     "sessionCm360AdvertiserName",
//     "sessionCm360CampaignId",
//     "sessionCm360CampaignName",
//     "sessionCm360CreativeFormat",
//     "sessionCm360CreativeId",
//     "sessionCm360CreativeName",
//     "sessionCm360CreativeType",
//     "sessionCm360CreativeTypeId",
//     "sessionCm360CreativeVersion",
//     "sessionCm360Medium",
//     "sessionCm360PlacementCostStructure",
//     "sessionCm360PlacementId",
//     "sessionCm360PlacementName",
//     "sessionCm360RenderingId",
//     "sessionCm360SiteId",
//     "sessionCm360SiteName",
//     "sessionCm360Source",
//     "sessionCm360SourceMedium",
//     "sessionDv360AdvertiserId",
//     "sessionDv360AdvertiserName",
//     "sessionDv360CampaignId",
//     "sessionDv360CampaignName",
//     "sessionDv360CreativeFormat",
//     "sessionDv360CreativeId",
//     "sessionDv360CreativeName",
//     "sessionDv360ExchangeId",
//     "sessionDv360ExchangeName",
//     "sessionDv360InsertionOrderId",
//     "sessionDv360InsertionOrderName",
//     "sessionDv360LineItemId",
//     "sessionDv360LineItemName",
//     "sessionDv360Medium",
//     "sessionDv360PartnerId",
//     "sessionDv360PartnerName",
//     "sessionDv360Source",
//     "sessionDv360SourceMedium",
//     "sessionGoogleAdsAccountName",
//     "sessionGoogleAdsAdGroupId",
//     "sessionGoogleAdsAdGroupName",
//     "sessionGoogleAdsAdNetworkType",
//     "sessionGoogleAdsCampaignId",
//     "sessionGoogleAdsCampaignName",
//     "sessionGoogleAdsCampaignType",
//     "sessionGoogleAdsCreativeId",
//     "sessionGoogleAdsCustomerId",
//     "sessionGoogleAdsKeyword",
//     "sessionGoogleAdsQuery",
//     "sessionManualCampaignId",
//     "sessionManualCampaignName",
//     "sessionManualCreativeFormat",
//     "sessionManualMarketingTactic",
//     "sessionManualMedium",
//     "sessionManualSource",
//     "sessionManualSourceMedium",
//     "sessionManualSourcePlatform",
//     "sessionPrimaryChannelGroup",
//     "sessionSa360AdGroupId",
//     "sessionSa360AdGroupName",
//     "sessionSa360CampaignId",
//     "sessionSa360CampaignName",
//     "sessionSa360CreativeFormat",
//     "sessionSa360EngineAccountId",
//     "sessionSa360EngineAccountName",
//     "sessionSa360EngineAccountType",
//     "sessionSa360Keyword",
//     "sessionSa360ManagerAccountId",
//     "sessionSa360ManagerAccountName",
//     "sessionSa360Medium",
//     "sessionSa360Query",
//     "sessionSa360Source",
//     "sessionSa360SourceMedium",
//     "sessionSourceMedium",
//     "source",
//     "sourcePlatform",
//     "term",
//   ],
//   User: ["audienceId", "audienceName", "newVsReturning", "signedInWithUserId"],
//   "User Lifetime": ["firstSessionDate"],
//   Video: ["videoProvider", "videoTitle", "videoUrl"],
// };

export const ANALYTICS4_DIMENSION_LABELS: Record<Analytics4Dimension, string> =
  {
    achievementId: "Achievement ID",
    adSourceName: "Ad source",
    adUnitName: "Ad unit",
    appVersion: "App version",
    attributionDefaultChannelGrouping: "Default channel group",
    attributionMedium: "Medium",
    attributionSource: "Source",
    attributionSourcePlatform: "Source platform",
    audienceId: "Audience ID",
    audienceName: "Audience name",
    brandingInterest: "Interests",
    browser: "Browser",
    campaign: "Session campaign",
    campaignId: "Campaign ID",
    campaignName: "Campaign",
    character: "Character",
    city: "City",
    cityId: "City ID",
    cm360AccountId: "CM360 account ID",
    cm360AccountName: "CM360 account name",
    cm360AdvertiserId: "CM360 advertiser ID",
    cm360AdvertiserName: "CM360 advertiser name",
    cm360CampaignId: "CM360 campaign ID",
    cm360CampaignName: "CM360 campaign name",
    cm360CreativeFormat: "CM360 creative format",
    cm360CreativeId: "CM360 creative ID",
    cm360CreativeName: "CM360 creative name",
    cm360CreativeType: "CM360 creative type",
    cm360CreativeTypeId: "CM360 creative type ID",
    cm360CreativeVersion: "CM360 creative version",
    cm360Medium: "CM360 medium",
    cm360PlacementCostStructure: "CM360 placement cost structure",
    cm360PlacementId: "CM360 placement ID",
    cm360PlacementName: "CM360 placement name",
    cm360RenderingId: "CM360 rendering ID",
    cm360SiteId: "CM360 site ID",
    cm360SiteName: "CM360 site name",
    cm360Source: "CM360 source",
    cm360SourceMedium: "CM360 source / medium",
    content: "Session manual content",
    contentGroup: "Content group",
    contentId: "Content ID",
    contentType: "Content type",
    continent: "Continent",
    continentId: "Continent ID",
    country: "Country",
    countryId: "Country ID",
    creativeFormat: "Ad format",
    currencyCode: "Currency",
    date: "Date",
    dateHour: "Date + hour (YYYYMMDDHH)",
    dateHourMinute: "Date hour and minute",
    day: "Day",
    dayOfWeek: "Day of week",
    dayOfWeekName: "Day of week name",
    defaultChannelGrouping: "Session default channel group",
    deviceCategory: "Device category",
    deviceModel: "Device model",
    dv360AdvertiserId: "DV360 advertiser ID",
    dv360AdvertiserName: "DV360 advertiser name",
    dv360CampaignId: "DV360 campaign ID",
    dv360CampaignName: "DV360 campaign name",
    dv360CreativeFormat: "DV360 creative format",
    dv360CreativeId: "DV360 creative ID",
    dv360CreativeName: "DV360 creative name",
    dv360ExchangeId: "DV360 exchange ID",
    dv360ExchangeName: "DV360 exchange name",
    dv360InsertionOrderId: "DV360 insertion order ID",
    dv360InsertionOrderName: "DV360 insertion order name",
    dv360LineItemId: "DV360 line item ID",
    dv360LineItemName: "DV360 line item name",
    dv360Medium: "DV360 medium",
    dv360PartnerId: "DV360 partner ID",
    dv360PartnerName: "DV360 partner name",
    dv360Source: "DV360 source",
    dv360SourceMedium: "DV360 source / medium",
    eventName: "Event name",
    fileExtension: "File extension",
    fileName: "File name",
    firstSessionDate: "First session date",
    firstUserCampaignId: "First user campaign ID",
    firstUserCampaignName: "First user campaign",
    firstUserCm360AccountId: "First user CM360 account ID",
    firstUserCm360AccountName: "First user CM360 account name",
    firstUserCm360AdvertiserId: "First user CM360 advertiser ID",
    firstUserCm360AdvertiserName: "First user CM360 advertiser name",
    firstUserCm360CampaignId: "First user CM360 campaign ID",
    firstUserCm360CampaignName: "First user CM360 campaign name",
    firstUserCm360CreativeFormat: "First user CM360 creative format",
    firstUserCm360CreativeId: "First user CM360 creative ID",
    firstUserCm360CreativeName: "First user CM360 creative name",
    firstUserCm360CreativeType: "First user CM360 creative type",
    firstUserCm360CreativeTypeId: "First user CM360 creative type ID",
    firstUserCm360CreativeVersion: "First user CM360 creative version",
    firstUserCm360Medium: "First user CM360 medium",
    firstUserCm360PlacementCostStructure:
      "First user CM360 placement cost structure",
    firstUserCm360PlacementId: "First user CM360 placement ID",
    firstUserCm360PlacementName: "First user CM360 placement name",
    firstUserCm360RenderingId: "First user CM360 rendering ID",
    firstUserCm360SiteId: "First user CM360 site ID",
    firstUserCm360SiteName: "First user CM360 site name",
    firstUserCm360Source: "First user CM360 source",
    firstUserCm360SourceMedium: "First user CM360 source / medium",
    firstUserDefaultChannelGrouping: "First user default channel group",
    firstUserDv360AdvertiserId: "First user DV360 advertiser ID",
    firstUserDv360AdvertiserName: "First user DV360 advertiser name",
    firstUserDv360CampaignId: "First user DV360 campaign ID",
    firstUserDv360CampaignName: "First user DV360 campaign name",
    firstUserDv360CreativeFormat: "First user DV360 creative format",
    firstUserDv360CreativeId: "First user DV360 creative ID",
    firstUserDv360CreativeName: "First user DV360 creative name",
    firstUserDv360ExchangeId: "First user DV360 exchange ID",
    firstUserDv360ExchangeName: "First user DV360 exchange name",
    firstUserDv360InsertionOrderId: "First user DV360 insertion order ID",
    firstUserDv360InsertionOrderName: "First user DV360 insertion order name",
    firstUserDv360LineItemId: "First user DV360 line item ID",
    firstUserDv360LineItemName: "First user DV360 line item name",
    firstUserDv360Medium: "First user DV360 medium",
    firstUserDv360PartnerId: "First user DV360 partner ID",
    firstUserDv360PartnerName: "First user DV360 partner name",
    firstUserDv360Source: "First user DV360 source",
    firstUserDv360SourceMedium: "First user DV360 source / medium",
    firstUserGoogleAdsAccountName: "First user Google Ads account name",
    firstUserGoogleAdsAdGroupId: "First user Google Ads ad group ID",
    firstUserGoogleAdsAdGroupName: "First user Google Ads ad group name",
    firstUserGoogleAdsAdNetworkType: "First user Google Ads ad network type",
    firstUserGoogleAdsCampaignId: "First user Google Ads campaign ID",
    firstUserGoogleAdsCampaignName: "First user Google Ads campaign",
    firstUserGoogleAdsCampaignType: "First user Google Ads campaign type",
    firstUserGoogleAdsCreativeId: "First user Google Ads creative ID",
    firstUserGoogleAdsCustomerId: "First user Google Ads customer ID",
    firstUserGoogleAdsKeyword: "First user Google Ads keyword text",
    firstUserGoogleAdsQuery: "First user Google Ads query",
    firstUserManualAdContent: "First user manual ad content",
    firstUserManualCampaignId: "First user manual campaign ID",
    firstUserManualCampaignName: "First user manual campaign name",
    firstUserManualCreativeFormat: "First user manual creative format",
    firstUserManualMarketingTactic: "First user manual marketing tactic",
    firstUserManualMedium: "First user manual medium",
    firstUserManualSource: "First user manual source",
    firstUserManualSourceMedium: "First user manual source / medium",
    firstUserManualSourcePlatform: "First user manual source platform",
    firstUserManualTerm: "First user manual term",
    firstUserMedium: "First user medium",
    firstUserPrimaryChannelGroup: "First user primary channel group",
    firstUserSa360AdGroupId: "First user SA360 ad group ID",
    firstUserSa360AdGroupName: "First user SA360 ad group name",
    firstUserSa360CampaignId: "First user SA360 campaign ID",
    firstUserSa360CampaignName: "First user SA360 campaign",
    firstUserSa360CreativeFormat: "First user SA360 creative format",
    firstUserSa360EngineAccountId: "First user SA360 engine account ID",
    firstUserSa360EngineAccountName: "First user SA360 engine account name",
    firstUserSa360EngineAccountType: "First user SA360 engine account type",
    firstUserSa360KeywordText: "First user SA360 keyword text",
    firstUserSa360ManagerAccountId: "First user SA360 manager account ID",
    firstUserSa360ManagerAccountName: "First user SA360 manager account name",
    firstUserSa360Medium: "First user SA360 medium",
    firstUserSa360Query: "First user SA360 query",
    firstUserSa360Source: "First user SA360 source",
    firstUserSa360SourceMedium: "First user SA360 source / medium",
    firstUserSource: "First user source",
    firstUserSourceMedium: "First user source / medium",
    firstUserSourcePlatform: "First user source platform",
    fullPageUrl: "Full page URL",
    googleAdsAccountName: "Google Ads account name",
    googleAdsAdGroupId: "Google Ads ad group ID",
    googleAdsAdGroupName: "Google Ads ad group name",
    googleAdsAdNetworkType: "Google Ads ad network type",
    googleAdsCampaignId: "Google Ads campaign ID",
    googleAdsCampaignName: "Google Ads campaign",
    googleAdsCampaignType: "Google Ads campaign type",
    googleAdsCreativeId: "Google Ads creative ID",
    googleAdsCustomerId: "Google Ads customer ID",
    googleAdsKeyword: "Google Ads keyword text",
    googleAdsQuery: "Google Ads query",
    groupId: "Group ID",
    hostName: "Hostname",
    hour: "Hour",
    isConversionEvent: "Is conversion event",
    isoWeek: "ISO week of the year",
    isoYear: "ISO year",
    isoYearIsoWeek: "ISO week of ISO year",
    itemAffiliation: "Item affiliation",
    itemBrand: "Item brand",
    itemCategory: "Item category",
    itemCategory2: "Item category 2",
    itemCategory3: "Item category 3",
    itemCategory4: "Item category 4",
    itemCategory5: "Item category 5",
    itemId: "Item ID",
    itemListId: "Item list ID",
    itemListName: "Item list name",
    itemListPosition: "Item list position",
    itemLocationId: "Item location ID",
    itemName: "Item name",
    itemPromotionCreativeName: "Item promotion creative name",
    itemPromotionCreativeSlot: "Item promotion creative slot",
    itemPromotionId: "Item promotion ID",
    itemPromotionName: "Item promotion name",
    itemVariant: "Item variant",
    landingPage: "Landing page",
    landingPagePlusQueryString: "Landing page + query string",
    language: "Language",
    languageCode: "Language code",
    level: "Level",
    linkClasses: "Link classes",
    linkDomain: "Link domain",
    linkId: "Link ID",
    linkText: "Link text",
    linkUrl: "Link URL",
    manualAdContent: "Manual ad content",
    manualCampaignId: "Manual campaign ID",
    manualCampaignName: "Manual campaign name",
    manualCreativeFormat: "Manual creative format",
    manualMarketingTactic: "Manual marketing tactic",
    manualMedium: "Manual medium",
    manualSource: "Manual source",
    manualSourceMedium: "Manual source / medium",
    manualSourcePlatform: "Manual source platform",
    manualTerm: "Manual term",
    medium: "Session medium",
    method: "Method",
    minute: "Minute",
    mobileDeviceBranding: "Device brand",
    mobileDeviceMarketingName: "Device",
    mobileDeviceModel: "Mobile model",
    month: "Month",
    newVsReturning: "New / returning",
    nthDay: "Nth day",
    nthHour: "Nth hour",
    nthMinute: "Nth minute",
    nthMonth: "Nth month",
    nthWeek: "Nth week",
    nthYear: "Nth year",
    operatingSystem: "Operating system",
    operatingSystemVersion: "OS version",
    operatingSystemWithVersion: "Operating system with version",
    orderCoupon: "Order coupon",
    outbound: "Outbound",
    page: "Page",
    pageLocation: "Page location",
    pagePath: "Page path",
    pagePathPlusQueryString: "Page path + query string",
    pageReferrer: "Page referrer",
    pageTitle: "Page title",
    percentScrolled: "Percent scrolled",
    platform: "Platform",
    platformDeviceCategory: "Platform / device category",
    primaryChannelGroup: "Primary channel group",
    region: "Region",
    sa360AdGroupId: "SA360 ad group ID",
    sa360AdGroupName: "SA360 ad group name",
    sa360CampaignId: "SA360 campaign ID",
    sa360CampaignName: "SA360 campaign",
    sa360CreativeFormat: "SA360 creative format",
    sa360EngineAccountId: "SA360 engine account ID",
    sa360EngineAccountName: "SA360 engine account name",
    sa360EngineAccountType: "SA360 engine account type",
    sa360KeywordText: "SA360 keyword text",
    sa360ManagerAccountId: "SA360 manager account ID",
    sa360ManagerAccountName: "SA360 manager account name",
    sa360Medium: "SA360 medium",
    sa360Query: "SA360 query",
    sa360Source: "SA360 source",
    sa360SourceMedium: "SA360 source / medium",
    screenResolution: "Screen resolution",
    searchTerm: "Search term",
    sessionCampaignId: "Session campaign ID",
    sessionCm360AccountId: "Session CM360 account ID",
    sessionCm360AccountName: "Session CM360 account name",
    sessionCm360AdvertiserId: "Session CM360 advertiser ID",
    sessionCm360AdvertiserName: "Session CM360 advertiser name",
    sessionCm360CampaignId: "Session CM360 campaign ID",
    sessionCm360CampaignName: "Session CM360 campaign name",
    sessionCm360CreativeFormat: "Session CM360 creative format",
    sessionCm360CreativeId: "Session CM360 creative ID",
    sessionCm360CreativeName: "Session CM360 creative name",
    sessionCm360CreativeType: "Session CM360 creative type",
    sessionCm360CreativeTypeId: "Session CM360 creative type ID",
    sessionCm360CreativeVersion: "Session CM360 creative version",
    sessionCm360Medium: "Session CM360 medium",
    sessionCm360PlacementCostStructure:
      "Session CM360 placement cost structure",
    sessionCm360PlacementId: "Session CM360 placement ID",
    sessionCm360PlacementName: "Session CM360 placement name",
    sessionCm360RenderingId: "Session CM360 rendering ID",
    sessionCm360SiteId: "Session CM360 site ID",
    sessionCm360SiteName: "Session CM360 site name",
    sessionCm360Source: "Session CM360 source",
    sessionCm360SourceMedium: "Session CM360 source / medium",
    sessionDv360AdvertiserId: "Session DV360 advertiser ID",
    sessionDv360AdvertiserName: "Session DV360 advertiser name",
    sessionDv360CampaignId: "Session DV360 campaign ID",
    sessionDv360CampaignName: "Session DV360 campaign name",
    sessionDv360CreativeFormat: "Session DV360 creative format",
    sessionDv360CreativeId: "Session DV360 creative ID",
    sessionDv360CreativeName: "Session DV360 creative name",
    sessionDv360ExchangeId: "Session DV360 exchange ID",
    sessionDv360ExchangeName: "Session DV360 exchange name",
    sessionDv360InsertionOrderId: "Session DV360 insertion order ID",
    sessionDv360InsertionOrderName: "Session DV360 insertion order name",
    sessionDv360LineItemId: "Session DV360 line item ID",
    sessionDv360LineItemName: "Session DV360 line item name",
    sessionDv360Medium: "Session DV360 medium",
    sessionDv360PartnerId: "Session DV360 partner ID",
    sessionDv360PartnerName: "Session DV360 partner name",
    sessionDv360Source: "Session DV360 source",
    sessionDv360SourceMedium: "Session DV360 source / medium",
    sessionGoogleAdsAccountName: "Session Google Ads account name",
    sessionGoogleAdsAdGroupId: "Session Google Ads ad group ID",
    sessionGoogleAdsAdGroupName: "Session Google Ads ad group name",
    sessionGoogleAdsAdNetworkType: "Session Google Ads ad network type",
    sessionGoogleAdsCampaignId: "Session Google Ads campaign ID",
    sessionGoogleAdsCampaignName: "Session Google Ads campaign",
    sessionGoogleAdsCampaignType: "Session Google Ads campaign type",
    sessionGoogleAdsCreativeId: "Session Google Ads creative ID",
    sessionGoogleAdsCustomerId: "Session Google Ads customer ID",
    sessionGoogleAdsKeyword: "Session Google Ads keyword text",
    sessionGoogleAdsQuery: "Session Google Ads query",
    sessionManualCampaignId: "Session manual campaign ID",
    sessionManualCampaignName: "Session manual campaign name",
    sessionManualCreativeFormat: "Session manual creative format",
    sessionManualMarketingTactic: "Session manual marketing tactic",
    sessionManualMedium: "Session manual medium",
    sessionManualSource: "Session manual source",
    sessionManualSourceMedium: "Session manual source / medium",
    sessionManualSourcePlatform: "Session manual source platform",
    sessionPrimaryChannelGroup: "Session primary channel group",
    sessionSa360AdGroupId: "Session SA360 ad group ID",
    sessionSa360AdGroupName: "Session SA360 ad group name",
    sessionSa360CampaignId: "Session SA360 campaign ID",
    sessionSa360CampaignName: "Session SA360 campaign",
    sessionSa360CreativeFormat: "Session SA360 creative format",
    sessionSa360EngineAccountId: "Session SA360 engine account ID",
    sessionSa360EngineAccountName: "Session SA360 engine account name",
    sessionSa360EngineAccountType: "Session SA360 engine account type",
    sessionSa360Keyword: "Session SA360 keyword text",
    sessionSa360ManagerAccountId: "Session SA360 manager account ID",
    sessionSa360ManagerAccountName: "Session SA360 manager account name",
    sessionSa360Medium: "Session SA360 medium",
    sessionSa360Query: "Session SA360 query",
    sessionSa360Source: "Session SA360 source",
    sessionSa360SourceMedium: "Session SA360 source / medium",
    sessionSourceMedium: "Session source / medium",
    shippingTier: "Shipping tier",
    signedInWithUserId: "Signed in with user ID",
    source: "Session source",
    sourceMedium: "Source / medium",
    sourcePlatform: "Session source platform",
    streamId: "Stream ID",
    streamName: "Stream name",
    term: "Session manual term",
    testDataFilterId: "Test data filter ID",
    testDataFilterName: "Test data filter name",
    transactionId: "Transaction ID",
    unifiedPagePathScreen: "Page path and screen class",
    unifiedPageScreen: "Page path + query string and screen class",
    unifiedScreenClass: "Page title and screen class",
    unifiedScreenName: "Page title and screen name",
    userAgeBracket: "Age",
    userGender: "Gender",
    videoProvider: "Video provider",
    videoTitle: "Video title",
    videoUrl: "Video URL",
    virtualCurrencyName: "Virtual currency name",
    visible: "Visible",
    week: "Week",
    year: "Year",
    yearMonth: "Year month",
    yearWeek: "Year week",
  };

export const ANALYTICS4_DIMENSION_DESCRIPTIONS: Record<
  Analytics4Dimension,
  string
> = {
  achievementId:
    "The achievement ID in a game for an event. Populated by the event parameter `achievement_id`.",
  adSourceName:
    "The source network that served the ad. Typical sources include `AdMob Network`, `Liftoff`, `Facebook Audience Network`, and `Mediated house ads`.",
  adUnitName:
    "The name you chose to describe this Ad unit. Ad units are containers you place in your apps to show ads to users.",
  appVersion: "The app's versionName (Android) or short bundle version (iOS).",
  attributionDefaultChannelGrouping:
    "The conversion's default channel group is based primarily on source and medium. An enumeration which includes `Direct`, `Organic Search`, `Paid Social`, `Organic Social`, `Email`, `Affiliates`, `Referral`, `Paid Search`, `Video`, and `Display`.",
  attributionMedium: "The medium attributed to the conversion.",
  attributionSource: "The source attributed to the conversion.",
  attributionSourcePlatform:
    "The source platform of the conversion's campaign. Don't depend on this field returning `Manual` for traffic that uses UTMs; this field will update from returning `Manual` to returning `(not set)` for an upcoming feature launch.",
  audienceId:
    "The numeric identifier of an Audience. Users are reported in the audiences to which they belonged during the report's date range. Current user behavior does not affect historical audience membership in reports.",
  audienceName:
    "The given name of an Audience. Users are reported in the audiences to which they belonged during the report's date range. Current user behavior does not affect historical audience membership in reports.",
  brandingInterest:
    "Interests demonstrated by users who are higher in the shopping funnel. Users can be counted in multiple interest categories. For example, `Shoppers`, `Lifestyles & Hobbies/Pet Lovers`, or `Travel/Travel Buffs/Beachbound Travelers`.",
  browser: "The browsers used to view your website.",
  campaign:
    "The marketing campaign name for a session. Includes Google Ads Campaigns, Manual Campaigns, & other Campaigns.",
  campaignId:
    "The identifier of the marketing campaign. Present only for conversions. Includes Google Ads Campaigns, Manual Campaigns, & other Campaigns.",
  campaignName:
    "The name of the marketing campaign. Present only for conversions. Includes Google Ads Campaigns, Manual Campaigns, & other Campaigns.",
  character:
    "The player character in a game for an event. Populated by the event parameter `character`.",
  city: "The city from which the user activity originated.",
  cityId:
    "The geographic ID of the city from which the user activity originated, derived from their IP address.",
  cm360AccountId:
    "The CM360 Account ID that led to the conversion. Identifies the CM360 Account.",
  cm360AccountName:
    "The CM360 Account Name that led to the conversion. A CM360 account consists of advertisers, sites, campaigns, and user profiles.",
  cm360AdvertiserId:
    "The CM360 Advertiser ID that led to the conversion. A CM360 Advertiser contains a group of campaigns, creative assets, and other settings.",
  cm360AdvertiserName:
    "The CM360 Advertiser Name that led to the conversion. A CM360 Advertiser contains a group of campaigns, creative assets, and other settings.",
  cm360CampaignId:
    "The CM360 Campaign ID that led to the conversion. A CM360 campaign can be configured to specify when your ads run, what landing pages are used, and other properties.",
  cm360CampaignName:
    "The CM360 Campaign Name that led to the conversion. A CM360 campaign can be configured to specify when your ads run, what landing pages are used, and other properties.",
  cm360CreativeFormat:
    "The CM360 Creative Format that led to the conversion. CM360 creative formats are also referred to as creative types.",
  cm360CreativeId:
    "The CM360 Creative ID that led to the conversion. Identifies a CM360 creative.",
  cm360CreativeName:
    "The CM360 Creative Name that led to the conversion. The name given to a CM360 creative.",
  cm360CreativeType:
    "The CM360 Creative Type that led to the conversion. A category of CM360 creatives like 'Display' or 'Tracking'.",
  cm360CreativeTypeId:
    "The CM360 Creative Type ID that led to the conversion. Identifies a CM360 creative type.",
  cm360CreativeVersion:
    "The CM360 Creative Version that led to the conversion. The version number helps you keep track of multiple versions of your creative in your reports. If you upload a new asset to an existing creative, the version number is increased by one.",
  cm360Medium:
    "The CM360 Medium that led to the conversion. The CM360 medium is also referred to as the placement cost structure.",
  cm360PlacementCostStructure:
    "The CM360 Placement Cost Structure that led to the conversion. Placement cost structures regulate how media cost will be calculated. For example 'CPM'.",
  cm360PlacementId:
    "The CM360 Placement ID that led to the conversion. Identifies a CM360 placement. A placement is the part of a website where your ad appears.",
  cm360PlacementName:
    "The CM360 Placement Name that led to the conversion. The given name for a CM360 placement. A placement is the part of a website where your ad appears.",
  cm360RenderingId:
    "The CM360 Rendering ID that led to the conversion. Identifies a CM360 creative.",
  cm360SiteId:
    "The CM360 Site ID that led to the conversion. Identifies a CM360 site.",
  cm360SiteName:
    "The CM360 Site Name that led to the conversion. The CM360 Site name from which the ad space was purchased.",
  cm360Source:
    "The CM360 Source that led to the conversion. The CM360 source is also referred to as the site name.",
  cm360SourceMedium:
    "The CM360 Source Medium that led to the conversion. A combination of the source and medium.",
  content:
    "The ad content that led to a session. Populated by the utm_content parameter.",
  contentGroup:
    "A category that applies to items of published content. Populated by the event parameter `content_group`.",
  contentId:
    "The identifier of the selected content. Populated by the event parameter `content_id`.",
  contentType:
    "The category of the selected content. Populated by the event parameter `content_type`.",
  continent:
    "The continent from which the user activity originated. For example, `Americas` or `Asia`.",
  continentId:
    "The geographic ID of the continent from which the user activity originated, derived from their IP address.",
  country: "The country from which the user activity originated.",
  countryId:
    "The geographic ID of the country from which the user activity originated, derived from their IP address. Formatted according to ISO 3166-1 alpha-2 standard.",
  creativeFormat:
    "Describes the way ads looked and where they were located. Typical formats include `Interstitial`, `Banner`, `Rewarded`, and `Native advanced`.",
  currencyCode:
    "The local currency code (based on ISO 4217 standard) of the eCommerce event. For example, `USD` or `GBP`. Currency is specified in tagging by the `currency` parameter. Businesses that transact in more than one currency can specify a local currency code when sending eCommerce events to Analytics, and this dimension shows those currencies.",
  date: "The date of the event, formatted as YYYYMMDD.",
  dateHour: "The combined values of date and hour formatted as YYYYMMDDHH.",
  dateHourMinute:
    "The combined values of date, hour, and minute formatted as YYYYMMDDHHMM.",
  day: "The day of the month, a two-digit number from 01 to 31.",
  dayOfWeek:
    "The integer day of the week. It returns values in the range 0 to 6 with Sunday as the first day of the week.",
  dayOfWeekName:
    "The day of the week in English. This dimension has values such as Sunday or Monday.",
  defaultChannelGrouping:
    "The session's default channel group is based primarily on source and medium. An enumeration which includes `Direct`, `Organic Search`, `Paid Social`, `Organic Social`, `Email`, `Affiliates`, `Referral`, `Paid Search`, `Video`, and `Display`.",
  deviceCategory: "The type of device: Desktop, Tablet, or Mobile.",
  deviceModel: "The mobile device model (example: iPhone 10,6).",
  dv360AdvertiserId:
    "The DV360 Advertiser ID that led to the conversion. Identifies the DV360 advertiser.",
  dv360AdvertiserName:
    "The DV360 Advertiser Name that led to the conversion. DV360 advertisers represent real-life businesses that run advertising campaigns.",
  dv360CampaignId:
    "The DV360 Campaign ID that led to the conversion. Identifies the DV360 campaign.",
  dv360CampaignName:
    "The DV360 Campaign Name that led to the conversion. DV360 campaigns group together related insertion orders with a common business goal.",
  dv360CreativeFormat:
    "The DV360 Creative Format that led to the conversion. Also referred to as the creative type. For example, expandable, video, or native.",
  dv360CreativeId:
    "The DV360 Creative ID that led to the conversion. Identifies the DV360 creative.",
  dv360CreativeName:
    "The DV360 Creative Name that led to the conversion. The name given to a DV360 creative.",
  dv360ExchangeId:
    "The DV360 Exchange ID that led to the conversion. Identifies the DV360 exchange.",
  dv360ExchangeName:
    "The DV360 Exchange Name that led to the conversion. The DV360 ad exchange involved in the ad click.",
  dv360InsertionOrderId:
    "The DV360 Insertion Order ID that led to the conversion. Identifies the DV360 insertion order.",
  dv360InsertionOrderName:
    "The DV360 Insertion Order Name that led to the conversion. A DV360 insertion order contains a set of line items that are related to the same advertising campaign.",
  dv360LineItemId:
    "The DV360 Line Item ID that led to the conversion. Identifies the DV360 line item.",
  dv360LineItemName:
    "The DV360 Line Item Name that led to the conversion. A DV360 line item bids on impressions and serves creatives to inventory sources.",
  dv360Medium:
    "The DV360 Medium that led to the conversion. The billable outcome of the insertion order. For example, `cpm`.",
  dv360PartnerId:
    "The DV360 Partner ID that led to the conversion. Identifies the DV360 partner.",
  dv360PartnerName:
    "The DV360 Partner Name that led to the conversion. DV360 partners represent agencies, trading desks, or large individual advertisers.",
  dv360Source:
    "The DV360 Source that led to the conversion. The DV360 site name where your ad was displayed.",
  dv360SourceMedium:
    "The DV360 Source Medium that led to the conversion. A combination of the source and medium.",
  eventName: "The name of the event.",
  fileExtension:
    "The extension of the downloaded file (for example, `pdf` or `txt`). Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `file_extension`.",
  fileName:
    "The page path of the downloaded file (for example, `/menus/dinner-menu.pdf`). Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `file_name`.",
  firstSessionDate:
    "The date the user's first session occurred, formatted as YYYYMMDD.",
  firstUserCampaignId:
    "Identifier of the marketing campaign that first acquired the user. Includes Google Ads Campaigns, Manual Campaigns, & other Campaigns.",
  firstUserCampaignName:
    "Name of the marketing campaign that first acquired the user. Includes Google Ads Campaigns, Manual Campaigns, & other Campaigns.",
  firstUserCm360AccountId:
    "The CM360 Account ID that originally acquired the user. Identifies the CM360 account.",
  firstUserCm360AccountName:
    "The CM360 Account Name that originally acquired the user. A CM360 account consists of advertisers, sites, campaigns, and user profiles.",
  firstUserCm360AdvertiserId:
    "The CM360 Advertiser ID that originally acquired the user. Identifies the CM360 advertiser.",
  firstUserCm360AdvertiserName:
    "The CM360 Advertiser Name that originally acquired the user. A CM360 Advertiser contains a group of campaigns, creative assets, and other settings.",
  firstUserCm360CampaignId:
    "The CM360 Campaign ID that originally acquired the user. Identifies the CM360 campaign.",
  firstUserCm360CampaignName:
    "The CM360 Campaign Name that originally acquired the user. A CM360 campaign can be configured to specify when your ads run, what landing pages are used, and other properties.",
  firstUserCm360CreativeFormat:
    "The CM360 Creative Format that originally acquired the user. CM360 creative formats are also referred to as creative types.",
  firstUserCm360CreativeId:
    "The CM360 Creative ID that originally acquired the user. Identifies a CM360 creative.",
  firstUserCm360CreativeName:
    "The CM360 Creative Name that originally acquired the user. The name given to a CM360 creative.",
  firstUserCm360CreativeType:
    "The CM360 Creative Type that originally acquired the user. A category of CM360 creatives like 'Display' or 'Tracking'.",
  firstUserCm360CreativeTypeId:
    "The CM360 Creative Type ID that originally acquired the user. Identifies a CM360 creative type.",
  firstUserCm360CreativeVersion:
    "The CM360 Creative Version that originally acquired the user. The version number helps you keep track of multiple versions of your creative in your reports. If you upload a new asset to an existing creative, the version number is increased by one.",
  firstUserCm360Medium:
    "The CM360 Medium that originally acquired the user. The CM360 medium is also referred to as the placement cost structure.",
  firstUserCm360PlacementCostStructure:
    "The CM360 Placement Cost Structure that originally acquired the user. Placement cost structures regulate how media cost will be calculated. For example 'CPM'.",
  firstUserCm360PlacementId:
    "The CM360 Placement ID that originally acquired the user. Identifies a CM360 placement. A placement is the part of a website where your ad appears.",
  firstUserCm360PlacementName:
    "The CM360 Placement Name that originally acquired the user. The given name for a CM360 placement. A placement is the part of a website where your ad appears.",
  firstUserCm360RenderingId:
    "The CM360 Rendering ID that originally acquired the user. Identifies a CM360 creative.",
  firstUserCm360SiteId:
    "The CM360 Site ID that originally acquired the user. Identifies a CM360 site.",
  firstUserCm360SiteName:
    "The CM360 Site Name that originally acquired the user. The CM360 Site name from which the ad space was purchased.",
  firstUserCm360Source:
    "The CM360 Source that originally acquired the user. The CM360 source is also referred to as the site name.",
  firstUserCm360SourceMedium:
    "The CM360 Source Medium that originally acquired the user. A combination of the source and medium.",
  firstUserDefaultChannelGrouping:
    "The default channel group that first acquired the user. Default channel group is based primarily on source and medium. An enumeration which includes `Direct`, `Organic Search`, `Paid Social`, `Organic Social`, `Email`, `Affiliates`, `Referral`, `Paid Search`, `Video`, and `Display`.",
  firstUserDv360AdvertiserId:
    "The DV360 Advertiser ID that originally acquired the user. Identifies the DV360 advertiser.",
  firstUserDv360AdvertiserName:
    "The DV360 Advertiser Name that originally acquired the user. DV360 advertisers represent real-life businesses that run advertising campaigns.",
  firstUserDv360CampaignId:
    "The DV360 Campaign ID that originally acquired the user. Identifies the DV360 campaign.",
  firstUserDv360CampaignName:
    "The DV360 Campaign Name that originally acquired the user. DV360 campaigns group together related insertion orders with a common business goal.",
  firstUserDv360CreativeFormat:
    "The DV360 Creative Format that originally acquired the user. Also referred to as the creative type. For example, expandable, video, or native.",
  firstUserDv360CreativeId:
    "The DV360 Creative ID that originally acquired the user. Identifies the DV360 creative.",
  firstUserDv360CreativeName:
    "The DV360 Creative Name that originally acquired the user. The name given to a DV360 creative.",
  firstUserDv360ExchangeId:
    "The DV360 Exchange ID that originally acquired the user. Identifies the DV360 exchange.",
  firstUserDv360ExchangeName:
    "The DV360 Exchange Name that originally acquired the user. The DV360 ad exchange involved in the ad click.",
  firstUserDv360InsertionOrderId:
    "The DV360 Insertion Order ID that originally acquired the user. Identifies the DV360 insertion order.",
  firstUserDv360InsertionOrderName:
    "The DV360 Insertion Order Name that originally acquired the user. A DV360 insertion order contains a set of line items that are related to the same advertising campaign.",
  firstUserDv360LineItemId:
    "The DV360 Line Item ID that originally acquired the user. Identifies the DV360 line item.",
  firstUserDv360LineItemName:
    "The DV360 Line Item Name that originally acquired the user. A DV360 line item bids on impressions and serves creatives to inventory sources.",
  firstUserDv360Medium:
    "The DV360 Medium that originally acquired the user. The billable outcome of the insertion order. For example, `cpm`.",
  firstUserDv360PartnerId:
    "The DV360 Partner ID that originally acquired the user. Identifies the DV360 partner.",
  firstUserDv360PartnerName:
    "The DV360 Partner Name that originally acquired the user. DV360 partners represent agencies, trading desks, or large individual advertisers.",
  firstUserDv360Source:
    "The DV360 Source that originally acquired the user. The DV360 site name where your ad was displayed.",
  firstUserDv360SourceMedium:
    "The DV360 Source Medium that originally acquired the user. A combination of the source and medium.",
  firstUserGoogleAdsAccountName:
    "The Account name from Google Ads that first acquired the user.",
  firstUserGoogleAdsAdGroupId:
    "The Ad Group Id in Google Ads that first acquired the user.",
  firstUserGoogleAdsAdGroupName:
    "The Ad Group Name in Google Ads that first acquired the user.",
  firstUserGoogleAdsAdNetworkType:
    "The advertising network that first acquired the user. An enumeration which includes `Google search`, `Search partners`, `Google Display Network`, `Youtube Search`, `Youtube Videos`, `Cross-network`, `Social`, and `(universal campaign)`.",
  firstUserGoogleAdsCampaignId:
    "Identifier of the Google Ads marketing campaign that first acquired the user.",
  firstUserGoogleAdsCampaignName:
    "Name of the Google Ads marketing campaign that first acquired the user.",
  firstUserGoogleAdsCampaignType:
    "The campaign type of the Google Ads campaign that first acquired the user. Campaign types determine where customers see your ads and the settings and options available to you in Google Ads. Campaign type is an enumeration that includes: Search, Display, Shopping, Video, Demand Gen, App, Smart, Hotel, Local, and Performance Max.",
  firstUserGoogleAdsCreativeId:
    "The ID of the Google Ads creative that first acquired the user. Creative IDs identify individual ads.",
  firstUserGoogleAdsCustomerId:
    "The Customer ID from Google Ads that first acquired the user. Customer IDs in Google Ads uniquely identify Google Ads accounts.",
  firstUserGoogleAdsKeyword:
    "First user Google Ads keyword text | The matched keyword that first acquired the user. Keywords are words or phrases describing your product or service that you choose to get your ad in front of the right customers.",
  firstUserGoogleAdsQuery: "The search query that first acquired the user.",
  firstUserManualAdContent:
    "The ad content that first acquired the user. Populated by the utm_content parameter.",
  firstUserManualCampaignId:
    "The manual Campaign ID that originally acquired the user. Identifies the manual campaign. Populated by `utm_id` URL parameter.",
  firstUserManualCampaignName:
    "The manual Campaign Name that originally acquired the user. The name of the manual campaign. Populated by `utm_campaign` URL parameter.",
  firstUserManualCreativeFormat:
    "The manual Creative Format that originally acquired the user. Identifies the creative format used in the ad. Populated by `utm_creative_format` URL parameter.",
  firstUserManualMarketingTactic:
    "The manual Marketing Tactic that originally acquired the user. The targeting criteria applied to a campaign. For example, remarketing or prospecting. Populated by `utm_marketing_tactic` URL parameter.",
  firstUserManualMedium:
    "The manual Medium that originally acquired the user. The marketing medium used in the referral. For example, `cpc`. Populated by `utm_medium` URL parameter.",
  firstUserManualSource:
    "The manual Source that originally acquired the user. The referrer. Populated by `utm_source` URL parameter.",
  firstUserManualSourceMedium:
    "The manual Source Medium that originally acquired the user. A combination of the source and medium.",
  firstUserManualSourcePlatform:
    "The manual Source Platform that originally acquired the user. The platform responsible for directing traffic to a given Analytics property. Populated by `utm_source_platform` URL parameter.",
  firstUserManualTerm:
    "The term that first acquired the user. Populated by the utm_term parameter.",
  firstUserMedium:
    "The medium that first acquired the user to your website or app.",
  firstUserPrimaryChannelGroup:
    "The primary channel group that originally acquired a user. Primary channel groups are the channel groups used in standard reports in Google Analytics and serve as an active record of your property's data in alignment with channel grouping over time.",
  firstUserSa360AdGroupId:
    "The SA360 Ad Group ID that originally acquired the user. Identifies the SA360 ad group.",
  firstUserSa360AdGroupName:
    "The SA360 Ad Group Name that originally acquired the user. SA360 Ad groups contain ads and keywords that relate to each other.",
  firstUserSa360CampaignId:
    "The SA360 Campaign ID that originally acquired the user. Identifies the SA360 campaign.",
  firstUserSa360CampaignName:
    "The SA360 Campaign Name that originally acquired the user. A SA360 campaign lets you organize your ads and define a goal for your advertising.",
  firstUserSa360CreativeFormat:
    "The SA360 Creative Format that originally acquired the user. The creative format is the specific layout or design of the advertisement used in a campaign.",
  firstUserSa360EngineAccountId:
    "The SA360 Engine Account ID that originally acquired the user. Identifies the SA360 engine account.",
  firstUserSa360EngineAccountName:
    "The SA360 Engine Account Name that originally acquired the user. A SA360 engine account contains campaigns, ad groups, and other items from an advertising account.",
  firstUserSa360EngineAccountType:
    "The SA360 Engine Account Type that originally acquired the user. The type of engine used by the search engine account.",
  firstUserSa360KeywordText:
    "The SA360 Keyword Text that originally acquired the user. The keywords that matched the search query.",
  firstUserSa360ManagerAccountId:
    "The SA360 Manager Account ID that originally acquired the user. Identifies the SA360 manager account.",
  firstUserSa360ManagerAccountName:
    "The SA360 Manager Account Name that originally acquired the user. The top level of the Search Ads 360 account hierarchy and is used for administration and reporting across the lower-level sub-manager and client accounts.",
  firstUserSa360Medium:
    "The SA360 Medium that originally acquired the user. The payment mode used in ad buying. For example, `cpc`.",
  firstUserSa360Query:
    "The SA360 Query that originally acquired the user. The search query typed by the user.",
  firstUserSa360Source:
    "The SA360 Source that originally acquired the user. The search query happened on this site.",
  firstUserSa360SourceMedium:
    "The SA360 Source Medium that originally acquired the user. A combination of the source and medium.",
  firstUserSource:
    "The source that first acquired the user to your website or app.",
  firstUserSourceMedium:
    "The combined values of the dimensions `firstUserSource` and `firstUserMedium`.",
  firstUserSourcePlatform:
    "The source platform that first acquired the user. Don't depend on this field returning `Manual` for traffic that uses UTMs; this field will update from returning `Manual` to returning `(not set)` for an upcoming feature launch.",
  fullPageUrl:
    "The hostname, page path, and query string for web pages visited; for example, the `fullPageUrl` portion of `https://www.example.com/store/contact-us?query_string=true` is `www.example.com/store/contact-us?query_string=true`.",
  googleAdsAccountName:
    "The Account name from Google Ads for the campaign that led to the conversion. Corresponds to `customer.descriptive_name` in the Google Ads API.",
  googleAdsAdGroupId:
    "The Google Ads ad group ID attributed to the conversion.",
  googleAdsAdGroupName: "The ad group name attributed to the conversion.",
  googleAdsAdNetworkType:
    "The advertising network type of the conversion. An enumeration which includes `Google search`, `Search partners`, `Google Display Network`, `Youtube Search`, `Youtube Videos`, `Cross-network`, `Social`, and `(universal campaign)`.",
  googleAdsCampaignId:
    "The campaign ID for the Google Ads campaign attributed to the conversion.",
  googleAdsCampaignName:
    "The campaign name for the Google Ads campaign attributed to the conversion.",
  googleAdsCampaignType:
    "The campaign type for the Google Ads campaign attributed to the conversion. Campaign types determine where customers see your ads and the settings and options available to you in Google Ads. Campaign type is an enumeration that includes: Search, Display, Shopping, Video, Demand Gen, App, Smart, Hotel, Local, and Performance Max.",
  googleAdsCreativeId:
    "The ID of the Google Ads creative attributed to the conversion. Creative IDs identify individual ads.",
  googleAdsCustomerId:
    "The Customer ID from Google Ads for the campaign that led to conversion. Customer IDs in Google Ads uniquely identify Google Ads accounts.",
  googleAdsKeyword:
    "The matched keyword that led to the conversion. Keywords are words or phrases describing your product or service that you choose to get your ad in front of the right customers.",
  googleAdsQuery: "The search query that led to the conversion.",
  groupId:
    "The player group ID in a game for an event. Populated by the event parameter `group_id`.",
  hostName:
    "Includes the subdomain and domain names of a URL; for example, the Host Name of www.example.com/contact.html is www.example.com.",
  hour: "The two-digit hour of the day that the event was logged. This dimension ranges from 0-23 and is reported in your property's timezone.",
  isConversionEvent:
    "The string `true` if the event is a conversion. Marking an event as a conversion affects reports from time of creation. It doesn't change historic data. You can mark any event as a conversion in Google Analytics, and some events (such as `first_open` or `purchase`) are marked as conversions by default.",
  isoWeek:
    "ISO week number, where each week starts on Monday. Example values include 01, 02, & 53.",
  isoYear: "The ISO year of the event. Example values include 2022 & 2023.",
  isoYearIsoWeek:
    "The combined values of isoWeek and isoYear. Example values include 201652 & 201701.",
  itemAffiliation:
    "The name or code of the affiliate (partner/vendor; if any) associated with an individual item. Populated by the `affiliation` item parameter.",
  itemBrand: "Brand name of the item.",
  itemCategory:
    "The hierarchical category in which the item is classified. For example, in Apparel/Mens/Summer/Shirts/T-shirts, Apparel is the item category.",
  itemCategory2:
    "The hierarchical category in which the item is classified. For example, in Apparel/Mens/Summer/Shirts/T-shirts, Mens is the item category 2.",
  itemCategory3:
    "The hierarchical category in which the item is classified. For example, in Apparel/Mens/Summer/Shirts/T-shirts, Summer is the item category 3.",
  itemCategory4:
    "The hierarchical category in which the item is classified. For example, in Apparel/Mens/Summer/Shirts/T-shirts, Shirts is the item category 4.",
  itemCategory5:
    "The hierarchical category in which the item is classified. For example, in Apparel/Mens/Summer/Shirts/T-shirts, T-shirts is the item category 5.",
  itemId: "The ID of the item.",
  itemListId: "The ID of the item list.",
  itemListName: "The name of the item list.",
  itemListPosition:
    "The position of an item in a list. For example, a product you sell in a list. This dimension is populated in tagging by the `index` parameter in the items array.",
  itemLocationId:
    "The physical location associated with the item. For example, the physical store location. It's recommended to use the Google Place ID that corresponds to the associated item. A custom location ID can also be used. This field is populated in tagging by the `location_id` parameter in the items array.",
  itemName: "The name of the item.",
  itemPromotionCreativeName: "The name of the item-promotion creative.",
  itemPromotionCreativeSlot:
    "The name of the promotional creative slot associated with the item. This dimension can be specified in tagging by the `creative_slot` parameter at the event or item level. If the parameter is specified at both the event & item level, the item-level parameter is used.",
  itemPromotionId: "The ID of the item promotion.",
  itemPromotionName: "The name of the promotion for the item.",
  itemVariant:
    "The specific variation of a product. For example, XS, S, M, or L for size; or Red, Blue, Green, or Black for color. Populated by the `item_variant` parameter.",
  landingPage: "The page path associated with the first pageview in a session.",
  landingPagePlusQueryString:
    "The page path + query string associated with the first pageview in a session.",
  language:
    "The language setting of the user's browser or device. For example, `English`.",
  languageCode:
    "The language setting (ISO 639) of the user's browser or device. For example, `en-us`.",
  level:
    "The player's level in a game. Populated by the event parameter `level`.",
  linkClasses:
    'The HTML class attribute for an outbound link. For example if a user clicks a link `<a class="center" href="www.youtube.com">`, this dimension will return `center`. Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `link_classes`.',
  linkDomain:
    'The destination domain of the outbound link. For example if a user clicks a link `<a href="www.youtube.com">`, this dimension will return `youtube.com`. Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `link_domain`.',
  linkId:
    'The HTML ID attribute for an outbound link or file download. For example if a user clicks a link `<a id="socialLinks" href="www.youtube.com">`, this dimension will return `socialLinks`. Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `link_id`.',
  linkText:
    "The link text of the file download. Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `link_text`.",
  linkUrl:
    'The full URL for an outbound link or file download. For example if a user clicks a link `<a href="https://www.youtube.com/results?search_query=analytics">`, this dimension will return `https://www.youtube.com/results?search_query=analytics`. Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `link_url`.',
  manualAdContent:
    "The ad content attributed to the conversion. Populated by the utm_content parameter.",
  manualCampaignId:
    "The manual Campaign ID that led to the conversion. Identifies the manual campaign. Populated by `utm_id` URL parameter.",
  manualCampaignName:
    "The manual Campaign Name that led to the conversion. The name of the manual campaign. Populated by `utm_campaign` URL parameter.",
  manualCreativeFormat:
    "The manual Creative Format that led to the conversion. Identifies the creative format used in the ad. Populated by `utm_creative_format` URL parameter.",
  manualMarketingTactic:
    "The manual Marketing Tactic that led to the conversion. The targeting criteria applied to a campaign. For example, remarketing or prospecting. Populated by `utm_marketing_tactic` URL parameter.",
  manualMedium:
    "The manual Medium that led to the conversion. The marketing medium used in the referral. For example, `cpc`. Populated by `utm_medium` URL parameter.",
  manualSource:
    "The manual Source that led to the conversion. The referrer. Populated by `utm_source` URL parameter.",
  manualSourceMedium:
    "The manual Source Medium that led to the conversion. A combination of the source and medium.",
  manualSourcePlatform:
    "The manual Source Platform that led to the conversion. The platform responsible for directing traffic to a given Analytics property. Populated by `utm_source_platform` URL parameter.",
  manualTerm:
    "The term attributed to the conversion. Populated by the utm_term parameter.",
  medium: "The medium that initiated a session on your website or app.",
  method:
    "The method by which an event was triggered. Populated by the event parameter `method`.",
  minute:
    "The two-digit minute of the hour that the event was logged. This dimension ranges from 0-59 and is reported in your property's timezone.",
  mobileDeviceBranding:
    "Manufacturer or branded name (examples: Samsung, HTC, Verizon, T-Mobile).",
  mobileDeviceMarketingName:
    "The branded device name (examples: Galaxy S10 or P30 Pro).",
  mobileDeviceModel:
    "The mobile device model name (examples: iPhone X or SM-G950F).",
  month: "The month of the event, a two digit integer from 01 to 12.",
  newVsReturning:
    "New users have 0 previous sessions, and returning users have 1 or more previous sessions. This dimension returns two values: `new` or `returning`.",
  nthDay: "The number of days since the start of the date range.",
  nthHour:
    "The number of hours since the start of the date range. The starting hour is 0000.",
  nthMinute:
    "The number of minutes since the start of the date range. The starting minute is 0000.",
  nthMonth:
    "The number of months since the start of a date range. The starting month is 0000.",
  nthWeek:
    "A number representing the number of weeks since the start of a date range.",
  nthYear:
    "The number of years since the start of the date range. The starting year is 0000.",
  operatingSystem:
    "The operating systems used by visitors to your app or website. Includes desktop and mobile operating systems such as Windows and Android.",
  operatingSystemVersion:
    "The operating system versions used by visitors to your website or app. For example, Android 10's version is 10, and iOS 13.5.1's version is 13.5.1.",
  operatingSystemWithVersion:
    "The operating system and version. For example, Android 10 or Windows 7.",
  orderCoupon: "Code for the order-level coupon.",
  outbound:
    "Returns `true` if the link led to a site that is not a part of the property's domain. Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `outbound`.",
  page: "The hostname and page path for web pages visited, prefixed with `https://`; for example, the `page` portion of `https://www.example.com/store/contact-us?query_string=true` is `https://www.example.com/store/contact-us`.",
  pageLocation:
    "The protocol, hostname, page path, and query string for web pages visited; for example, the `pageLocation` portion of `https://www.example.com/store/contact-us?query_string=true` is `https://www.example.com/store/contact-us?query_string=true`. Populated by the event parameter `page_location`.",
  pagePath:
    "The portion of the URL between the hostname and query string for web pages visited; for example, the `pagePath` portion of `https://www.example.com/store/contact-us?query_string=true` is `/store/contact-us`.",
  pagePathPlusQueryString:
    "The portion of the URL following the hostname for web pages visited; for example, the `pagePathPlusQueryString` portion of `https://www.example.com/store/contact-us?query_string=true` is `/store/contact-us?query_string=true`.",
  pageReferrer:
    "The full referring URL including the hostname and path. This referring URL is the user's previous URL and can be this website's domain or other domains. Populated by the event parameter `page_referrer`.",
  pageTitle: "The web page titles used on your site.",
  percentScrolled:
    "The percentage down the page that the user has scrolled (for example, `90`). Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `percent_scrolled`.",
  platform:
    "The platform on which your app or website ran; for example, web, iOS, or Android. To determine a stream's type in a report, use both platform and streamId.",
  platformDeviceCategory:
    "The platform and type of device on which your website or mobile app ran. (example: Android / mobile)",
  primaryChannelGroup:
    "The primary channel group attributed to the conversion. Primary channel groups are the channel groups used in standard reports in Google Analytics and serve as an active record of your property's data in alignment with channel grouping over time.",
  region:
    "The geographic region from which the user activity originated, derived from their IP address.",
  sa360AdGroupId:
    "The SA360 Ad Group ID that led to the conversion. Identifies the SA360 ad group.",
  sa360AdGroupName:
    "The SA360 Ad Group Name that led to the conversion. SA360 Ad groups contain ads and keywords that relate to each other.",
  sa360CampaignId:
    "The SA360 Campaign ID that led to the conversion. Identifies the SA360 campaign.",
  sa360CampaignName:
    "The SA360 Campaign Name that led to the conversion. A SA360 campaign lets you organize your ads and define a goal for your advertising.",
  sa360CreativeFormat:
    "The SA360 Creative Format that led to the conversion. The creative format is the specific layout or design of the advertisement used in a campaign.",
  sa360EngineAccountId:
    "The SA360 Engine Account ID that led to the conversion. Identifies the SA360 engine account.",
  sa360EngineAccountName:
    "The SA360 Engine Account Name that led to the conversion. A SA360 engine account contains campaigns, ad groups, and other items from an advertising account.",
  sa360EngineAccountType:
    "The SA360 Engine Account Type that led to the conversion. The type of engine used by the search engine account.",
  sa360KeywordText:
    "The SA360 Keyword Text that led to the conversion. The keywords that matched the search query.",
  sa360ManagerAccountId:
    "The SA360 Manager Account ID that led to the conversion. Identifies the SA360 manager account.",
  sa360ManagerAccountName:
    "The SA360 Manager Account Name that led to the conversion. The top level of the Search Ads 360 account hierarchy and is used for administration and reporting across the lower-level sub-manager and client accounts.",
  sa360Medium:
    "The SA360 Medium that led to the conversion. The payment mode used in ad buying. For example, `cpc`.",
  sa360Query:
    "The SA360 Query that led to the conversion. The search query typed by the user.",
  sa360Source:
    "The SA360 Source that led to the conversion. The search query happened on this site.",
  sa360SourceMedium:
    "The SA360 Source Medium that led to the conversion. A combination of the source and medium.",
  screenResolution:
    "The screen resolution of the user's monitor. For example, 1920x1080.",
  searchTerm:
    "The term searched by the user. For example if the user visits `/some-page.html?q=some-term`, this dimension returns `some-term`. Automatically populated if Enhanced Measurement is enabled. Populated by the event parameter `search_term`.",
  sessionCampaignId:
    "The marketing campaign ID for a session. Includes Google Ads Campaigns, Manual Campaigns, & other Campaigns.",
  sessionCm360AccountId:
    "The CM360 Account ID that led to the session. Identifies the CM360 account.",
  sessionCm360AccountName:
    "The CM360 Account Name that led to the session. A CM360 account consists of advertisers, sites, campaigns, and user profiles.",
  sessionCm360AdvertiserId:
    "The CM360 Advertiser ID that led to the session. Identifies the CM360 advertiser.",
  sessionCm360AdvertiserName:
    "The CM360 Advertiser Name that led to the session. A CM360 Advertiser contains a group of campaigns, creative assets, and other settings.",
  sessionCm360CampaignId:
    "The CM360 Campaign ID that led to the session. Identifies the CM360 campaign.",
  sessionCm360CampaignName:
    "The CM360 Campaign Name that led to the session. A CM360 campaign can be configured to specify when your ads run, what landing pages are used, and other properties.",
  sessionCm360CreativeFormat:
    "The CM360 Creative Format that led to the session. CM360 creative formats are also referred to as creative types.",
  sessionCm360CreativeId:
    "The CM360 Creative ID that led to the session. Identifies a CM360 creative.",
  sessionCm360CreativeName:
    "The CM360 Creative Name that led to the session. The name given to a CM360 creative.",
  sessionCm360CreativeType:
    "The CM360 Creative Type that led to the session. A category of CM360 creatives like 'Display' or 'Tracking'.",
  sessionCm360CreativeTypeId:
    "The CM360 Creative Type ID that led to the session. Identifies a CM360 creative type.",
  sessionCm360CreativeVersion:
    "The CM360 Creative Version that led to the session. The version number helps you keep track of multiple versions of your creative in your reports. If you upload a new asset to an existing creative, the version number is increased by one.",
  sessionCm360Medium:
    "The CM360 Medium that led to the session. The CM360 medium is also referred to as the placement cost structure.",
  sessionCm360PlacementCostStructure:
    "The CM360 Placement Cost Structure that led to the session. Placement cost structures regulate how media cost will be calculated. For example 'CPM'.",
  sessionCm360PlacementId:
    "The CM360 Placement ID that led to the session. Identifies a CM360 placement. A placement is the part of a website where your ad appears.",
  sessionCm360PlacementName:
    "The CM360 Placement Name that led to the session. The given name for a CM360 placement. A placement is the part of a website where your ad appears.",
  sessionCm360RenderingId:
    "The CM360 Rendering ID that led to the session. Identifies a CM360 creative.",
  sessionCm360SiteId:
    "The CM360 Site ID that led to the session. Identifies a CM360 site.",
  sessionCm360SiteName:
    "The CM360 Site Name that led to the session. The CM360 Site name from which the ad space was purchased.",
  sessionCm360Source:
    "The CM360 Source that led to the session. The CM360 source is also referred to as the site name.",
  sessionCm360SourceMedium:
    "The CM360 Source Medium that led to the session. A combination of the source and medium.",
  sessionDv360AdvertiserId:
    "The DV360 Advertiser ID that led to the session. Identifies the DV360 advertiser.",
  sessionDv360AdvertiserName:
    "The DV360 Advertiser Name that led to the session. DV360 advertisers represent real-life businesses that run advertising campaigns.",
  sessionDv360CampaignId:
    "The DV360 Campaign ID that led to the session. Identifies the DV360 campaign.",
  sessionDv360CampaignName:
    "The DV360 Campaign Name that led to the session. DV360 campaigns group together related insertion orders with a common business goal.",
  sessionDv360CreativeFormat:
    "The DV360 Creative Format that led to the session. Also referred to as the creative type. For example, expandable, video, or native.",
  sessionDv360CreativeId:
    "The DV360 Creative ID that led to the session. Identifies the DV360 creative.",
  sessionDv360CreativeName:
    "The DV360 Creative Name that led to the session. The name given to a DV360 creative.",
  sessionDv360ExchangeId:
    "The DV360 Exchange ID that led to the session. Identifies the DV360 exchange.",
  sessionDv360ExchangeName:
    "The DV360 Exchange Name that led to the session. The DV360 ad exchange involved in the ad click.",
  sessionDv360InsertionOrderId:
    "The DV360 Insertion Order ID that led to the session. Identifies the DV360 insertion order.",
  sessionDv360InsertionOrderName:
    "The DV360 Insertion Order Name that led to the session. A DV360 insertion order contains a set of line items that are related to the same advertising campaign.",
  sessionDv360LineItemId:
    "The DV360 Line Item ID that led to the session. Identifies the DV360 line item.",
  sessionDv360LineItemName:
    "The DV360 Line Item Name that led to the session. A DV360 line item bids on impressions and serves creatives to inventory sources.",
  sessionDv360Medium:
    "The DV360 Medium that led to the session. The billable outcome of the insertion order. For example, `cpm`.",
  sessionDv360PartnerId:
    "The DV360 Partner ID that led to the session. Identifies the DV360 partner.",
  sessionDv360PartnerName:
    "The DV360 Partner Name that led to the session. DV360 partners represent agencies, trading desks, or large individual advertisers.",
  sessionDv360Source:
    "The DV360 Source that led to the session. The DV360 site name where your ad was displayed.",
  sessionDv360SourceMedium:
    "The DV360 Source Medium that led to the session. A combination of the source and medium.",
  sessionGoogleAdsAccountName:
    "The Account name from Google Ads that led to the session. Corresponds to customer.descriptive_name in the Google Ads API.",
  sessionGoogleAdsAdGroupId: "The Ad Group Id in Google Ads for a session.",
  sessionGoogleAdsAdGroupName: "The Ad Group Name in Google Ads for a session.",
  sessionGoogleAdsAdNetworkType:
    "The advertising network that led to the session. An enumeration which includes `Google search`, `Search partners`, `Google Display Network`, `Youtube Search`, `Youtube Videos`, `Cross-network`, `Social`, and `(universal campaign)`.",
  sessionGoogleAdsCampaignId:
    "The Campaign ID for the Google Ads Campaign that led to this session.",
  sessionGoogleAdsCampaignName:
    "The Campaign name for the Google Ads Campaign that led to this session.",
  sessionGoogleAdsCampaignType:
    "The campaign type for the Google Ads campaign that led to this session. Campaign types determine where customers see your ads and the settings and options available to you in Google Ads. Campaign type is an enumeration that includes: Search, Display, Shopping, Video, Demand Gen, App, Smart, Hotel, Local, and Performance Max.",
  sessionGoogleAdsCreativeId:
    "The ID of the Google Ads creative that led to a session on your website or app. Creative IDs identify individual ads.",
  sessionGoogleAdsCustomerId:
    "The Customer ID from Google Ads that led to the session. Customer IDs in Google Ads uniquely identify Google Ads accounts.",
  sessionGoogleAdsKeyword:
    "The matched keyword that led to the session. Keywords are words or phrases describing your product or service that you choose to get your ad in front of the right customers.",
  sessionGoogleAdsQuery: "The search query that led to the session.",
  sessionManualCampaignId:
    "The manual Campaign ID that led to the session. Identifies the manual campaign. Populated by `utm_id` URL parameter.",
  sessionManualCampaignName:
    "The manual Campaign Name that led to the session. The name of the manual campaign. Populated by `utm_campaign` URL parameter.",
  sessionManualCreativeFormat:
    "The manual Creative Format that led to the session. Identifies the creative format used in the ad. Populated by `utm_creative_format` URL parameter.",
  sessionManualMarketingTactic:
    "The manual Marketing Tactic that led to the session. The targeting criteria applied to a campaign. For example, remarketing or prospecting. Populated by `utm_marketing_tactic` URL parameter.",
  sessionManualMedium:
    "The manual Medium that led to the session. The marketing medium used in the referral. For example, `cpc`. Populated by `utm_medium` URL parameter.",
  sessionManualSource:
    "The manual Source that led to the session. The referrer. Populated by `utm_source` URL parameter.",
  sessionManualSourceMedium:
    "The manual Source Medium that led to the session. A combination of the source and medium.",
  sessionManualSourcePlatform:
    "The manual Source Platform that led to the session. The platform responsible for directing traffic to a given Analytics property. Populated by `utm_source_platform` URL parameter.",
  sessionPrimaryChannelGroup:
    "The primary channel group that led to the session. Primary channel groups are the channel groups used in standard reports in Google Analytics and serve as an active record of your property's data in alignment with channel grouping over time.",
  sessionSa360AdGroupId:
    "The SA360 Ad Group ID that led to the session. Identifies the SA360 ad group.",
  sessionSa360AdGroupName:
    "The SA360 Ad Group Name that led to the session. SA360 Ad groups contain ads and keywords that relate to each other.",
  sessionSa360CampaignId:
    "The SA360 Campaign ID that led to the session. Identifies the SA360 campaign.",
  sessionSa360CampaignName:
    "The SA360 Campaign Name that led to the session. A SA360 campaign lets you organize your ads and define a goal for your advertising.",
  sessionSa360CreativeFormat:
    "The SA360 Creative Format that led to the session. The creative format is the specific layout or design of the advertisement used in a campaign.",
  sessionSa360EngineAccountId:
    "The SA360 Engine Account ID that led to the session. Identifies the SA360 engine account.",
  sessionSa360EngineAccountName:
    "The SA360 Engine Account Name that led to the session. A SA360 engine account contains campaigns, ad groups, and other items from an advertising account.",
  sessionSa360EngineAccountType:
    "The SA360 Engine Account Type that led to the session. The type of engine used by the search engine account. For example, `google ads`, `bing`, or `baidu`.",
  sessionSa360Keyword:
    "The SA360 Keyword Text that led to the session. The keywords that matched the search query.",
  sessionSa360ManagerAccountId:
    "The SA360 Manager Account ID that led to the session. Identifies the SA360 manager account.",
  sessionSa360ManagerAccountName:
    "The SA360 Manager Account Name that led to the session. The top level of the Search Ads 360 account hierarchy and is used for administration and reporting across the lower-level sub-manager and client accounts.",
  sessionSa360Medium:
    "The SA360 Medium that led to the session. The payment mode used in ad buying. For example, `cpc`.",
  sessionSa360Query:
    "The SA360 Query that led to the session. The search query typed by the user.",
  sessionSa360Source:
    "The SA360 Source that led to the session. The search query happened on this site.",
  sessionSa360SourceMedium:
    "The SA360 Source Medium that led to the session. A combination of the source and medium.",
  sessionSourceMedium:
    "The combined values of the dimensions `sessionSource` and `sessionMedium`.",
  shippingTier:
    "The shipping tier selected for delivery of the purchased item. For example, `Ground`, `Air`, or `Next-day`. Populated by the `shipping_tier` event parameter.",
  signedInWithUserId:
    "The string `yes` if the user signed in with the User-ID feature.",
  source: "The source that initiated a session on your website or app.",
  sourceMedium: "The combined values of the dimensions `source` and `medium`.",
  sourcePlatform:
    "The source platform of the session's campaign. Don't depend on this field returning `Manual` for traffic that uses UTMs; this field will update from returning `Manual` to returning `(not set)` for an upcoming feature launch.",
  streamId: "The numeric data stream identifier for your app or website.",
  streamName: "The data stream name for your app or website.",
  term: "The term that led to a session. Populated by the utm_term parameter.",
  testDataFilterId:
    "The numeric identifier of a data filter in testing state. You use data filters to include or exclude event data from your reports based on event-parameter values.",
  testDataFilterName:
    "The name of data filters in testing state. You use data filters to include or exclude event data from your reports based on event-parameter values.",
  transactionId: "The ID of the ecommerce transaction.",
  unifiedPagePathScreen:
    "The page path (web) or screen class (app) on which the event was logged.",
  unifiedPageScreen:
    "The page path and query string (web) or screen class (app) on which the event was logged.",
  unifiedScreenClass:
    "The page title (web) or screen class (app) on which the event was logged.",
  unifiedScreenName:
    "The page title (web) or screen name (app) on which the event was logged.",
  userAgeBracket: "User age brackets.",
  userGender: "User gender.",
  videoProvider:
    "The source of the video (for example, `youtube`). Automatically populated for embedded videos if Enhanced Measurement is enabled. Populated by the event parameter `video_provider`.",
  videoTitle:
    "The title of the video. Automatically populated for embedded videos if Enhanced Measurement is enabled. Populated by the event parameter `video_title`.",
  videoUrl:
    "The URL of the video. Automatically populated for embedded videos if Enhanced Measurement is enabled. Populated by the event parameter `video_url`.",
  virtualCurrencyName:
    "The name of a virtual currency with which the user is interacting. Such as spending or purchasing gems in a game. Populated by the `virtual_currency_name` event parameter.",
  visible:
    "Returns `true` if the content is visible. Automatically populated for embedded videos if Enhanced Measurement is enabled. Populated by the event parameter `visible`.",
  week: "The week of the event, a two-digit number from 01 to 53. Each week starts on Sunday. January 1st is always in week 01. The first and last week of the year have fewer than 7 days in most years. Weeks other than the first and the last week of the year always have 7 days. For years where January 1st is a Sunday, the first week of that year and the last week of the prior year have 7 days.",
  year: "The four-digit year of the event. For example, 2020 or 2024.",
  yearMonth:
    "The combined values of year and month. Example values include 202212 or 202301.",
  yearWeek:
    "The combined values of year and week. Example values include 202253 or 202301.",
};

export type Analytics4Dimension = (typeof ANALYTICS4_DIMENSIONS)[number];
export const ANALYTICS_4_METRIC_SET_NAMES = ["default", "ecommerce"] as const;
export type Analytics4MetricSetName =
  (typeof ANALYTICS_4_METRIC_SET_NAMES)[number];

const defaultAnalytics4MetricSet: MetricKey[] = [
  "pageViews",
  "sessions",
  "activeUsers",
  "newUsers",
  "pageViewsPerActiveUser",
  "engagementTimePerSession",
  "engagementTimePerPage",
  "conversions",
  "conversionRate",
  "bounceRate",
];

const ecommerceAnalytics4MetricSet: MetricKey[] = [
  "addToCarts",
  "checkouts",
  "purchases",
  "purchaseRevenue",
  "transactions",
];

const analytics4MetricsWithoutMetricSet: MetricKey[] = [
  "users",
  "adUnitExposure",
  "adsClicks",
  "adsCost",
  "adsCostPerClick",
  "costPerConversion",
  "adsImpressions",
  "averagePurchaseRevenue",
  "averagePurchaseRevenuePerPayingUser",
  "averagePurchaseRevenuePerUser",
  "averageRevenuePerUser",
  "averageSessionDuration",
  "crashAffectedUsers",
  "crashFreeUsersRate",
  "dauPerMau",
  "dauPerWau",
  "engagedSessions",
  "engagementRate",
  "eventCountPerUser",
  "eventsPerSession",
  "firstTimePurchaserRate",
  "firstTimePurchasers",
  "firstTimePurchasersPerNewUser",
  "grossPurchaseRevenue",
  "itemDiscountAmount",
  "itemListClickEvents",
  "itemListClickthroughRate",
  "itemListViewEvents",
  "itemPromotionClickthroughRate",
  "itemViewEvents",
  "itemRefundAmount",
  "itemRevenue",
  "itemsAddedToCart",
  "itemsCheckedOut",
  "itemsClickedInList",
  "itemsClickedInPromotion",
  "itemsPurchased",
  "itemsViewed",
  "itemsViewedInList",
  "itemsViewedInPromotion",
  "organicGoogleSearchAveragePosition",
  "organicGoogleSearchClickthroughRate",
  "organicGoogleSearchClicks",
  "organicGoogleSearchImpressions",
  "promotionClicks",
  "promotionViews",
  "publisherAdClicks",
  "publisherAdImpressions",
  "purchaseToViewRate",
  "purchaserRate",
  "refundAmount",
  "returnOnAdSpend",
  "returnOnAdSpendRatio",
  "pageViewsPerSession",
  "scrolledUsers",
  "sessionsPerUser",
  "shippingAmount",
  "taxAmount",
  "totalAdRevenue",
  "totalPurchasers",
  "totalRevenue",
  "transactionsPerPurchaser",
  "userEngagement",
  "userConversionRate",
  "wauPerMau",
];

export const ANALYTICS_4_METRIC_SETS = {
  default: defaultAnalytics4MetricSet,
  ecommerce: ecommerceAnalytics4MetricSet,
} as const;

export const ANALYTICS_4_METRICS = [
  ...ANALYTICS_4_METRIC_SETS.default,
  ...ANALYTICS_4_METRIC_SETS.ecommerce,
  ...analytics4MetricsWithoutMetricSet,
];
export type Analytics4Metric =
  (typeof ANALYTICS_4_METRIC_SETS)[Analytics4MetricSetName];

export type GoogleAnalytics4WebsitePerformanceMetricsData = Record<
  MetricKey,
  number | null
>;

export interface PaginatedGoogleAnalytics4Result<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
  pageTotals: GoogleAnalytics4WebsitePerformanceMetricsData;
  overallTotals: GoogleAnalytics4WebsitePerformanceMetricsData;
}

export type GoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensionsRow =
  GoogleAnalytics4WebsitePerformanceMetricsData &
    Partial<Record<Analytics4Dimension, string>>;

export type GoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensions =
  PaginatedGoogleAnalytics4Result<GoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensionsRow>;

export type GoogleAnalytics4WebsitePerformanceMetricsByPageRow =
  GoogleAnalytics4WebsitePerformanceMetricsData & {
    page: string;
    pageId: number;
    tags: number[];
    title: string | null;
  };

export type GoogleAnalytics4WebsitePerformanceMetricsByPage =
  PaginatedGoogleAnalytics4Result<GoogleAnalytics4WebsitePerformanceMetricsByPageRow>;

export interface GoogleAnalytics4WebsitePerformanceMetricsByDateDataset
  extends ResponseTimeSeriesDatasets {
  datasets: Required<
    Pick<TimeSeriesDataset, "label" | "data" | "resource" | "period">
  >[];
}

export type GoogleAnalytics4WebsitePerformanceMetricsByDate = Record<
  MetricKey,
  GoogleAnalytics4WebsitePerformanceMetricsByDateDataset
>;

export interface GoogleAnalytics4WebsitePerformanceMetricsByDateWithExtraDetails
  extends GoogleAnalytics4WebsitePerformanceMetricsByDate {
  latestDate: string | null;
}

export type TemporalGoogleAnalytics4WebsitePerformanceMetricsByDate = Record<
  TemporalPeriodsType,
  GoogleAnalytics4WebsitePerformanceMetricsByDateWithExtraDetails
>;

export interface GoogleAnalytics4WebsitePerformanceMetricsByComparisonPeriod
  extends GoogleAnalytics4WebsitePerformanceMetricsData {
  label: "current" | "previous";
}

export interface GoogleAnalytics4WebsitePerformanceMetricsByComparison {
  currentPeriod: GoogleAnalytics4WebsitePerformanceMetricsByComparisonPeriod;
  previousPeriod: GoogleAnalytics4WebsitePerformanceMetricsByComparisonPeriod;
}

export interface IncompatibleMetricsOrDimensionsError {
  message: string;
  metrics: MetricKey[];
  dimensions: Analytics4Dimension[];
  code: string;
}

function listGoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensions({
  queryKey: [, params],
  ...context
}: QueryFunctionContext<
  {
    fields: MetricKey[];
    dimensions: string[];
    metricFilterDimensions?: string[];
    metricFilterDimensionValues?: string[][];
    ordering: string;
    search: string | undefined;
    page?: number;
    pageSize: number;
    pageId: number | undefined;
    includeUniversalAnalyticsData?: boolean;
  } & SelectionTemporalDataFilter &
    ResourcesDatafilter &
    PublicDashboardWidgetFilters
>): Promise<GoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensions> {
  return api
    .get(
      "/resources/website_performance/ganalytics_4_metrics_by_arbitrary_dimensions/",
      {
        params: {
          page: "pageParam" in context ? context.pageParam : params.page,
          ...params,
          metricFilterDimensionValues:
            (params.metricFilterDimensionValues ?? []).length > 0
              ? JSON.stringify(params.metricFilterDimensionValues)
              : undefined,
        },
      },
    )
    .then((response) => response.data);
}

export function useGoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensionsQuery({
  fields,
  dimensions,
  metricFilterDimensions,
  metricFilterDimensionValues,
  ordering,
  search,
  page,
  pageSize,
  selectedResourceIds,
  pageId,
  temporalFilter,
  includeUniversalAnalyticsData,
  useGlobalFilters = true,
  enabled,
}: {
  fields: Ref<MetricKey[]>;
  dimensions: Ref<string[]>;
  metricFilterDimensions?: Ref<string[]>;
  metricFilterDimensionValues?: string[][] | undefined;
  ordering: Ref<string>;
  search: Ref<string | undefined>;
  page: Ref<number>;
  pageSize: Ref<number>;
  selectedResourceIds?: Ref<number[]>;
  pageId?: Ref<number | undefined>;
  temporalFilter?: Ref<DateRangeSelection>;
  includeUniversalAnalyticsData?: Ref<boolean>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
}) {
  const calculatedResources = useCalculatedResources(
    selectedResourceIds,
    useGlobalFilters,
  );
  const queryKey = [
    "googleAnalytics4WebsitePerformanceMetricsByArbitraryDimensions",
    {
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...calculatedResources,
      ...usePublicDashboardWidgetQueryFilters(),
      dimensions,
      metricFilterDimensions,
      metricFilterDimensionValues,
      fields,
      ordering,
      search,
      page,
      pageSize,
      pageId,
      includeUniversalAnalyticsData,
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn:
        listGoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensions,
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          calculatedResources.resource__in.value?.length === 1,
      ),
      retry: (failureCount, error) => {
        if (
          isAxiosError(error) &&
          error.response?.data.nonFieldErrors?.code ===
            "invalid_metrics_or_dimensions"
        ) {
          return false;
        }
        return failureCount < 3;
      },
    }),
  );
}

export function useGoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensionsInfiniteQuery({
  fields,
  dimensions,
  ordering,
  search,
  pageSize,
  selectedResourceIds,
  pageId,
  temporalFilter,
  useGlobalFilters = true,
  enabled,
}: {
  fields: Ref<MetricKey[]>;
  dimensions: Ref<string[]>;
  ordering: Ref<string>;
  search: Ref<string | undefined>;
  pageSize: Ref<number>;
  selectedResourceIds?: Ref<number[]>;
  pageId?: Ref<number | undefined>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
}) {
  const calculatedResources = useCalculatedResources(
    selectedResourceIds,
    useGlobalFilters,
  );
  const queryKey = [
    "googleAnalytics4WebsitePerformanceMetricsByArbitraryDimensions",
    {
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...calculatedResources,
      ...usePublicDashboardWidgetQueryFilters(),
      dimensions,
      fields,
      ordering,
      search,
      pageSize,
      pageId,
    },
  ] as const;
  return reactive(
    useInfiniteQuery({
      queryKey,
      queryFn:
        listGoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensions,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          calculatedResources.resource__in.value?.length === 1,
      ),
      initialPageParam: undefined,
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length + 1 : undefined,
    }),
  );
}
export const useGoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensionsQueryWithMetrics =
  {
    query:
      useGoogleAnalytics4WebsitePerformanceMetricsByArbitraryDimensionsQuery,
    metrics: ANALYTICS_4_METRICS,
  };

function listGoogleAnalytics4WebsitePerformanceMetricsByPage({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    fields: MetricKey[];
    ordering: string;
    search: string | undefined;
    page: number;
    pageSize: number;
    dimensionValues: string[][] | undefined;
    metricFilterDimensions: string[] | undefined;
    metricFilterDimensionValues: string[][] | undefined;
    includeUniversalAnalyticsData?: boolean;
  } & SelectionTemporalDataFilter &
    ResourcesDatafilter &
    TagsDataFilter &
    PublicDashboardWidgetFilters
>): Promise<GoogleAnalytics4WebsitePerformanceMetricsByPage> {
  return api
    .get("/resources/website_performance/ganalytics_4_metrics_by_page/", {
      params: {
        ...params,
        dimensionValues:
          (params.dimensionValues ?? []).length > 0
            ? JSON.stringify(params.dimensionValues)
            : undefined,
        metricFilterDimensionValues:
          (params.metricFilterDimensionValues ?? []).length > 0
            ? JSON.stringify(params.metricFilterDimensionValues)
            : undefined,
      },
    })
    .then((response) => response.data);
}

export function useGoogleAnalytics4WebsitePerformanceMetricsByPageQuery({
  fields,
  ordering,
  search,
  page,
  pageSize,
  selectedResourceIds,
  dimensionValues,
  metricFilterDimensions,
  metricFilterDimensionValues,
  enabled,
  includedTags,
  excludedTags,
  temporalFilter,
  includeUniversalAnalyticsData,
  useGlobalFilters = true,
}: {
  fields: Ref<MetricKey[]>;
  ordering: Ref<string>;
  search?: Ref<string | undefined>;
  page: Ref<number>;
  pageSize: Ref<number>;
  selectedResourceIds?: Ref<number[]>;
  dimensionValues?: Ref<string[][] | undefined>;
  metricFilterDimensions?: Ref<string[]>;
  metricFilterDimensionValues?: string[][] | undefined;
  enabled?: Ref<boolean>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  includeUniversalAnalyticsData?: Ref<boolean>;
  useGlobalFilters?: boolean;
}) {
  const calculatedResources = useCalculatedResources(
    selectedResourceIds,
    useGlobalFilters,
  );
  const queryKey = [
    "googleAnalytics4WebsitePerformanceMetricsByPage",
    {
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...calculatedResources,
      ...usePublicDashboardWidgetQueryFilters(),
      fields,
      ordering,
      search,
      page,
      pageSize,
      dimensionValues,
      metricFilterDimensions,
      metricFilterDimensionValues,
      includeUniversalAnalyticsData,
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listGoogleAnalytics4WebsitePerformanceMetricsByPage,
      placeholderData: keepPreviousData,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          calculatedResources.resource__in.value?.length === 1,
      ),
      staleTime: 60 * 60 * 1000,
    }),
  );
}

export const useGoogleAnalytics4WebsitePerformanceMetricsByPageQueryWithMetrics =
  {
    query: useGoogleAnalytics4WebsitePerformanceMetricsByPageQuery,
    metrics: ANALYTICS_4_METRICS,
  };

function listGoogleAnalytics4WebsitePerformanceMetricsByDate({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    fields: MetricKey[];
    timeUnit: TimeUnit;
    pageId: number | undefined;
    dimensions: string[] | undefined;
    dimensionValues: string[][] | undefined;
  } & SeparateResourcesFilter &
    TemporalDataFilter &
    ResourcesDatafilter &
    PublicDashboardWidgetFilters
>): Promise<TemporalGoogleAnalytics4WebsitePerformanceMetricsByDate> {
  return api
    .get("/resources/website_performance/ganalytics_4_metrics_by_date/", {
      params: {
        ...params,
        dimensionValues: JSON.stringify(params.dimensionValues),
      },
    })
    .then((response) => response.data);
}

export function useGoogleAnalytics4WebsitePerformanceMetricsByDateQuery({
  dimensions,
  dimensionValues,
  fields,
  selectedResourceIds,
  timeUnit = ref(TimeUnit.DAY),
  pageId,
  enabled,
  temporalFilter,
  useGlobalFilters = true,
}: {
  fields: Ref<MetricKey[]>;
  dimensions?: Ref<string[] | undefined>;
  dimensionValues?: Ref<string[][] | undefined>;
  selectedResourceIds?: Ref<number[]>;
  timeUnit: Ref<TimeUnit>;
  pageId?: Ref<number | undefined>;
  enabled?: Ref<boolean>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
}) {
  const calculatedResources = useCalculatedResources(
    selectedResourceIds,
    useGlobalFilters,
  );
  const queryKey = [
    "googleAnalytics4WebsitePerformanceMetricsByDate",
    {
      ...useDataFilters(["separateResources"], useGlobalFilters),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...calculatedResources,
      ...usePublicDashboardWidgetQueryFilters(),
      dimensions,
      dimensionValues,
      timeUnit,
      fields,
      pageId,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listGoogleAnalytics4WebsitePerformanceMetricsByDate,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          calculatedResources.resource__in.value?.length === 1,
      ),
    }),
  );
}
export const useGoogleAnalytics4WebsitePerformanceMetricsByDateQueryWithMetrics =
  {
    query: useGoogleAnalytics4WebsitePerformanceMetricsByDateQuery,
    metrics: ANALYTICS_4_METRICS,
  };

function listGoogleAnalytics4WebsitePerformanceMetricsByComparison({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    fields: MetricKey[];
    dimensions: string[] | undefined;
    dimensionValues: string[][] | undefined;
    pageId: number | undefined;
    conversionId: number | undefined;
  } & TemporalDataFilter &
    ResourcesDatafilter &
    PublicDashboardWidgetFilters
>): Promise<GoogleAnalytics4WebsitePerformanceMetricsByComparison> {
  return api
    .get(
      "/resources/website_performance/ganalytics_4_metrics_compared_to_previous_period/",
      {
        params: {
          ...params,
          dimensionValues: JSON.stringify(params.dimensionValues),
        },
      },
    )
    .then((response) => response.data);
}

export function useGoogleAnalytics4WebsitePerformanceMetricsByComparisonQuery({
  dimensions,
  dimensionValues,
  selectedResourceIds,
  pageId,
  conversionId,
  fields,
  temporalFilter,
  enabled,
  useGlobalFilters = true,
}: {
  fields: Ref<MetricKey[]>;
  dimensions: Ref<string[] | undefined>;
  dimensionValues: Ref<string[][] | undefined>;
  selectedResourceIds?: Ref<number[]>;
  pageId?: Ref<number | undefined>;
  conversionId?: Ref<number | undefined>;
  temporalFilter?: Ref<DateRangeSelection>;
  enabled?: Ref<boolean>;
  useGlobalFilters?: boolean;
}) {
  const calculatedResources = useCalculatedResources(
    selectedResourceIds,
    useGlobalFilters,
  );
  const queryKey = [
    "googleAnalytics4websiteMetricsByComparison",
    {
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...calculatedResources,
      ...usePublicDashboardWidgetQueryFilters(),
      dimensions,
      dimensionValues,
      fields,
      pageId,
      conversionId,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listGoogleAnalytics4WebsitePerformanceMetricsByComparison,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          calculatedResources.resource__in.value?.length === 1,
      ),
    }),
  );
}
export const useGoogleAnalytics4WebsitePerformanceMetricsByComparisonQueryWithMetrics =
  {
    query: useGoogleAnalytics4WebsitePerformanceMetricsByComparisonQuery,
    metrics: ANALYTICS_4_METRICS,
  };

function listGoogleAnalytics4WebsitePerformancePageMetricsByComparison({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    fields: MetricKey[];
    metricFilterDimensions?: string[];
    metricFilterDimensionValues?: string[][];
    includeUniversalAnalyticsData?: boolean;
  } & TemporalDataFilter &
    ResourcesDatafilter &
    TagsDataFilter &
    PublicDashboardWidgetFilters
>): Promise<GoogleAnalytics4WebsitePerformanceMetricsByComparison> {
  return api
    .get(
      "/resources/website_performance/ganalytics_4_page_metrics_compared_to_previous_period/",
      {
        params: {
          ...params,
          metricFilterDimensionValues:
            (params.metricFilterDimensionValues ?? []).length > 0
              ? JSON.stringify(params.metricFilterDimensionValues)
              : undefined,
        },
      },
    )
    .then((response) => response.data);
}

export function useGoogleAnalytics4WebsitePerformancePageMetricsByComparisonQuery({
  selectedResourceIds,
  fields,
  metricFilterDimensions,
  metricFilterDimensionValues,
  temporalFilter,
  includedTags,
  excludedTags,
  includeUniversalAnalyticsData,
  enabled,
  useGlobalFilters = true,
}: {
  fields: Ref<MetricKey[]>;
  metricFilterDimensions?: Ref<string[]>;
  metricFilterDimensionValues?: string[][] | undefined;
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  includeUniversalAnalyticsData?: Ref<boolean>;
  enabled?: Ref<boolean>;
  useGlobalFilters?: boolean;
}) {
  const calculatedResources = useCalculatedResources(
    selectedResourceIds,
    useGlobalFilters,
  );
  const queryKey = [
    "googleAnalytics4websitePageMetricsByComparison",
    {
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...calculatedResources,
      ...usePublicDashboardWidgetQueryFilters(),
      fields,
      metricFilterDimensions,
      metricFilterDimensionValues,
      includeUniversalAnalyticsData,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listGoogleAnalytics4WebsitePerformancePageMetricsByComparison,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          calculatedResources.resource__in.value?.length === 1,
      ),
    }),
  );
}

export const useGoogleAnalytics4WebsitePerformancePageMetricsByComparisonQueryWithMetrics =
  {
    query: useGoogleAnalytics4WebsitePerformancePageMetricsByComparisonQuery,
    metrics: ANALYTICS_4_METRICS,
  };

function listGoogleAnalytics4WebsitePerformanceComparisonMetricsByDate({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    fields: MetricKey[];
    dimensions: string[];
    dimensionValues: string[][];
    timeUnit: TimeUnit;
    pageId: number | undefined;
    conversionId: number | undefined;
  } & SeparateResourcesFilter &
    TemporalDataFilter &
    ResourcesDatafilter
>): Promise<TemporalGoogleAnalytics4WebsitePerformanceMetricsByDate> {
  return api
    .get(
      "/resources/website_performance/ganalytics_4_comparison_metrics_by_date/",
      {
        params: {
          ...params,
          dimensionValues: JSON.stringify(params.dimensionValues),
        },
      },
    )
    .then((response) => response.data);
}

export function useGoogleAnalytics4WebsitePerformanceComparisonMetricsByDateQuery({
  dimensions,
  dimensionValues,
  selectedResourceIds,
  timeUnit = ref(TimeUnit.DAY),
  pageId,
  conversionId,
  fields,
}: {
  fields: Ref<MetricKey[]>;
  dimensions: Ref<string[] | undefined>;
  dimensionValues: Ref<string[][] | undefined>;
  selectedResourceIds?: Ref<number[]>;
  timeUnit: Ref<TimeUnit>;
  pageId?: Ref<number | undefined>;
  conversionId?: Ref<number | undefined>;
}) {
  const calculatedResources = useCalculatedResources(selectedResourceIds);
  const queryKey = [
    "googleAnalytics4WebsitePerformanceComparisonMetricsByDate",
    {
      ...useDataFilters(["temporalFilter", "separateResources"]),
      ...calculatedResources,
      dimensions: dimensions as Ref<string[]>,
      dimensionValues: dimensionValues as Ref<string[][]>,
      timeUnit,
      fields,
      pageId,
      conversionId,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listGoogleAnalytics4WebsitePerformanceComparisonMetricsByDate,
      enabled: computed(
        () =>
          !!(
            dimensions.value &&
            dimensionValues.value &&
            calculatedResources.resource__in.value?.length === 1
          ),
      ),
    }),
  );
}

function listGoogleAnalytics4WebsitePerformancePageComparisonMetricsByDate({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    fields: MetricKey[];
    dimensionValues: string[][];
    timeUnit: TimeUnit;
  } & SeparateResourcesFilter &
    TemporalDataFilter &
    ResourcesDatafilter
>): Promise<TemporalGoogleAnalytics4WebsitePerformanceMetricsByDate> {
  return api
    .get(
      "/resources/website_performance/ganalytics_4_page_comparison_metrics_by_date/",
      {
        params: {
          ...params,
          dimensionValues: JSON.stringify(params.dimensionValues),
        },
      },
    )
    .then((response) => response.data);
}

export function useGoogleAnalytics4WebsitePerformancePageComparisonMetricsByDateQuery({
  dimensionValues,
  selectedResourceIds,
  timeUnit = ref(TimeUnit.DAY),
  fields,
  enabled,
}: {
  fields: Ref<MetricKey[]>;
  dimensionValues: Ref<string[][] | undefined>;
  selectedResourceIds?: Ref<number[]>;
  timeUnit: Ref<TimeUnit>;
  enabled?: Ref<boolean>;
}) {
  const calculatedResources = useCalculatedResources(selectedResourceIds);
  const queryKey = [
    "googleAnalytics4WebsitePerformancePageComparisonMetricsByDate",
    {
      ...useDataFilters(["temporalFilter", "separateResources"]),
      ...calculatedResources,
      dimensionValues: dimensionValues as Ref<string[][]>,
      timeUnit,
      fields,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn:
        listGoogleAnalytics4WebsitePerformancePageComparisonMetricsByDate,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          dimensionValues.value !== undefined &&
          calculatedResources.resource__in.value?.length === 1,
      ),
      staleTime: 60 * 60 * 1000,
    }),
  );
}

function listGoogleAnalytics4WebsitePerformanceComparisonMetricsByWeekdayAndHour({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    fields: MetricKey[];
    dimensions: string[];
    dimensionValues: string[][];
    conversionId: number | undefined;
  } & SelectionTemporalDataFilter &
    ResourcesDatafilter
>): Promise<Record<MetricKey, HeatmapWeekdayData>> {
  return api
    .get(
      "/resources/website_performance/ganalytics_4_comparison_metrics_by_weekday_and_hour/",
      {
        params: {
          ...params,
          dimensionValues: JSON.stringify(params.dimensionValues),
        },
      },
    )
    .then((response) => response.data);
}

export function useGoogleAnalytics4WebsitePerformanceComparisonMetricsByWeekdayAndHourQuery({
  fields,
  dimensions,
  dimensionValues,
  selectedResourceIds,
  conversionId,
}: {
  fields: Ref<MetricKey[]>;
  dimensions: Ref<string[] | undefined>;
  dimensionValues: Ref<string[][] | undefined>;
  selectedResourceIds?: Ref<number[]>;
  conversionId?: Ref<number | undefined>;
}) {
  const calculatedResources = useCalculatedResources(selectedResourceIds);
  const queryKey = [
    "googleAnalytics4WebsitePerformanceComparisonMetricsByWeekdayAndHour",
    {
      ...useDataFilters(["selectionTemporalFilter"]),
      ...calculatedResources,
      fields,
      dimensions: dimensions as Ref<string[]>,
      dimensionValues: dimensionValues as Ref<string[][]>,
      conversionId,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn:
        listGoogleAnalytics4WebsitePerformanceComparisonMetricsByWeekdayAndHour,
      enabled: computed(
        () =>
          !!(
            dimensions.value &&
            dimensionValues.value &&
            calculatedResources.resource__in.value?.length === 1
          ),
      ),
    }),
  );
}

export enum ConversionCategory {
  PURCHASE = "PURCHASE",
  ADD_TO_CART = "ADD_TO_CART",
  BEGIN_CHECKOUT = "BEGIN_CHECKOUT",
  SUBSCRIPTION = "SUBSCRIPTION",
  START_TRIAL = "START_TRIAL",
  CONTACT = "CONTACT",
  SUBMIT_LEAD_FORM = "SUBMIT_LEAD_FORM",
  BOOK_APPOINTMENT = "BOOK_APPOINTMENT",
  SIGN_UP = "SIGN_UP",
  REQUEST_QUOTE = "REQUEST_QUOTE",
  GET_DIRECTIONS = "GET_DIRECTIONS",
  VIEWED_PAGE = "VIEWED_PAGE",
  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  OUTBOUND_CLICK = "OUTBOUND_CLICK",
  SUBSCRIBE_TO_NEWSLETTER = "SUBSCRIBE_TO_NEWSLETTER",
  CONTACT_SUPPORT = "CONTACT_SUPPORT",
  OTHER = "OTHER",
}

export interface ConversionMetricsByDateMetric {
  labels: string[];
  datasets: {
    label: string;
    data: (number | null)[];
  }[];
}

export interface ConversionMetricsByDate {
  conversions: ConversionMetricsByDateMetric;
  value: ConversionMetricsByDateMetric;
}

export interface Conversion {
  pk: number;
  // name automatically gets set to the alias if one is set, otherwise it's equal to externalName
  name: string;
  alias: string;
  externalName: string;
  externalId: string;
  description: string;
  resource: number;
  customValue: number | null;
  category: ConversionCategory | "";
  isConversionEvent: boolean;
  currentConversions: number | null;
  previousConversions: number | null;
  currentValue: number | null;
  previousValue: number | null;
  metricsByDate: ConversionMetricsByDate;
}

export interface GoogleAnalyticsConversion {
  pk: number;
  name: string;
  externalName: string;
  resource: number;
  externalId: string;
}

function listConversions({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    timeUnit: TimeUnit;
  } & TemporalDataFilter &
    ResourcesDatafilter &
    IsConversionEventFilter
>): Promise<Conversion[]> {
  return api
    .get("/resources/website_performance/conversions/", { params })
    .then((response) => response.data);
}

export function useConversionsQuery({
  timeUnit,
  selectedResourceIds,
  temporalFilter,
  search,
  useGlobalFilters = true,
}: {
  timeUnit: Ref<TimeUnit>;
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  search?: Ref<string>;
  useGlobalFilters?: boolean;
}) {
  const calculatedResources = useCalculatedResources(
    selectedResourceIds,
    useGlobalFilters,
  );
  const params = {
    ...useDataFilters(["includeAllEvents"], useGlobalFilters),
    ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
    ...calculatedResources,
    timeUnit,
    search,
  } as const;
  return reactive(
    useQuery({
      queryKey: ["conversions", params] as const,
      queryFn: listConversions,
      enabled: true,
    }),
  );
}

function getConversion({
  queryKey: [, { pk, ...params }],
}: QueryFunctionContext<
  { pk: number | undefined; timeUnit: TimeUnit } & TemporalDataFilter &
    PublicDashboardWidgetFilters
>): Promise<Conversion> {
  return api
    .get(`/resources/website_performance/conversions/${pk}/`, { params })
    .then((response) => response.data);
}

export function useConversionQuery<T = Conversion>({
  pk,
  timeUnit,
  enabled,
  select,
  temporalFilter,
  useGlobalFilters = true,
}: {
  pk: Ref<number | undefined>;
  timeUnit: Ref<TimeUnit>;
  enabled?: Ref<boolean>;
  temporalFilter?: Ref<DateRangeSelection>;
  select?: (conversion: Conversion) => T;
  useGlobalFilters?: boolean;
}) {
  const queryClient = useQueryClient();
  const dataFilters = useDetachedTemporalFilter(
    temporalFilter,
    useGlobalFilters,
  );
  return reactive(
    useQuery({
      queryKey: [
        "conversion",
        {
          ...dataFilters,
          ...usePublicDashboardWidgetQueryFilters(),
          timeUnit,
          pk,
        },
      ] as const,
      queryFn: getConversion,
      enabled: computed(() => pk.value !== undefined && enabled?.value),
      initialData: () => {
        return queryClient
          .getQueriesData<Conversion[] | undefined>({
            queryKey: ["conversions", { ...dataFilters, timeUnit }],
          })
          .flatMap(([_, conversions]) => conversions ?? [])
          .find((conversion) => conversion.pk === pk.value);
      },
      select,
    }),
  );
}

function useConversionComparisonQuery({
  pk,
  enabled,
  temporalFilter,
  dimensions,
  dimensionValues,
  useGlobalFilters = true,
}: {
  pk: Ref<number | undefined>;
  enabled?: Ref<boolean>;
  temporalFilter?: Ref<DateRangeSelection>;
  dimensions: Ref<string[]> | undefined;
  dimensionValues: Ref<string[][]> | undefined;
  useGlobalFilters?: boolean;
}) {
  const dataFilters = useDetachedTemporalFilter(
    temporalFilter,
    useGlobalFilters,
  );
  return reactive(
    useQuery({
      queryKey: [
        "conversionComparison",
        {
          ...dataFilters,
          ...usePublicDashboardWidgetQueryFilters(),
          pk,
          dimensions,
          dimensionValues,
        },
      ] as const,
      queryFn: (context) => {
        // We have two possible endpoints for this data,
        // one that fetches data straight from the GA4 API and supports dimension filtering
        // and one that fetches cached data from our database and does not support dimension filtering.
        if (context.queryKey[1].dimensions?.length) {
          return listGoogleAnalytics4WebsitePerformanceMetricsByComparison({
            ...context,
            queryKey: [
              context.queryKey[0],
              {
                ...context.queryKey[1],
                fields: ["eventCount", "value"],
                pageId: undefined,
                conversionId: context.queryKey[1].pk,
                resource__in: null,
              },
            ],
          }).then((conversion) => ({
            currentPeriod: {
              label: "current" as const,
              eventCount: conversion.currentPeriod.eventCount,
              conversionValue: conversion.currentPeriod.value,
            },
            previousPeriod: {
              label: "previous" as const,
              eventCount: conversion.currentPeriod.eventCount,
              conversionValue: conversion.currentPeriod.value,
            },
          }));
        } else {
          return getConversion({
            ...context,
            queryKey: [
              context.queryKey[0],
              {
                ...context.queryKey[1],
                timeUnit: TimeUnit.DAY,
              },
            ],
          }).then((conversion) => ({
            currentPeriod: {
              label: "current" as const,
              eventCount: conversion.currentConversions,
              conversionValue: conversion.currentValue,
            },
            previousPeriod: {
              label: "previous" as const,
              eventCount: conversion.previousConversions,
              conversionValue: conversion.previousValue,
            },
          }));
        }
      },
      enabled: computed(() => pk.value !== undefined && enabled?.value),
    }),
  );
}

export const useConversionComparisonQueryWithMetrics = {
  query: useConversionComparisonQuery,
  metrics: ["eventCount", "conversionValue"],
};

export interface UpdateConversionPayload {
  pk: number;
  alias?: string;
  description?: string;
  customValue?: number | null;
  category?: ConversionCategory | "";
}

function updateConversion(
  payload: UpdateConversionPayload,
  params: { timeUnit: TimeUnit } & TemporalDataFilter,
): Promise<Conversion> {
  return api
    .patch(
      `/resources/website_performance/conversions/${payload.pk}/`,
      payload,
      { params },
    )
    .then((response) => response.data);
}

export function useUpdateConversionMutation(timeUnit: Ref<TimeUnit>) {
  const queryClient = useQueryClient();
  const params = reactive({
    ...useDataFilters(["includedResources", "temporalFilter"]),
    timeUnit,
  });
  return reactive(
    useMutation({
      mutationFn: (payload: UpdateConversionPayload) =>
        updateConversion(payload, params),
      onSuccess: (newConversion) => {
        queryClient.setQueryData<Conversion[]>(
          ["conversions", params],
          (conversions) =>
            conversions?.map((conversion) =>
              conversion.pk === newConversion.pk ? newConversion : conversion,
            ),
        );
        queryClient.setQueryData(
          [
            "conversion",
            { pk: newConversion.pk, ...params, resource__in: undefined },
          ],
          newConversion,
        );
        queryClient.invalidateQueries({ queryKey: ["conversions"] });
        queryClient.invalidateQueries({
          queryKey: ["conversion", { pk: newConversion.pk }],
        });
        queryClient.invalidateQueries({
          queryKey: [
            "googleAnalytics4SpecificConversionMetricsByArbitraryDimensions",
            { pk: newConversion.pk },
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ["conversionDatapointsByWeekdayAndHourQuery"],
        });
        queryClient.invalidateQueries({
          queryKey: ["googleAnalytics4ConversionMetricsByDate"],
        });
        queryClient.invalidateQueries({
          queryKey: ["googleAnalytics4ConversionMetricsByArbitraryDimensions"],
        });
      },
    }),
  );
}

export type ConversionDatapointsByWeekdayAndHourForMetric = HeatmapWeekdayData;

export interface ConversionDatapointsByWeekdayAndHour {
  eventCount: ConversionDatapointsByWeekdayAndHourForMetric;
  value: ConversionDatapointsByWeekdayAndHourForMetric;
}

function listConversionDatapointsByWeekdayAndHour({
  queryKey: [, params],
}: QueryFunctionContext<
  { conversion__in: number[] | null } & SelectionTemporalDataFilter &
    ResourcesDatafilter &
    IsConversionEventFilter
>): Promise<ConversionDatapointsByWeekdayAndHour> {
  return api
    .get(
      "/resources/website_performance/conversion_datapoints_by_weekday_and_hour/",
      {
        params,
      },
    )
    .then((response) => response.data);
}

export function useConversionDatapointsByWeekdayAndHourQuery({
  conversions,
  temporalFilter,
  enabled,
}: {
  conversions?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  enabled?: Ref<boolean>;
} = {}) {
  // A conversion belongs to a specific resource, so if we're filtering
  // by conversions we don't want to also filter by resources.
  const calculatedResources = useCalculatedResources(
    conversions ? computed(() => []) : undefined,
  );
  const dataFiltersStore = useDataFiltersStore();
  const queryKey = [
    "conversionDatapointsByWeekdayAndHourQuery",
    {
      ...useDetachedSelectionTemporalFilter(temporalFilter),
      ...calculatedResources,
      conversion__in: computed(() => conversions?.value ?? null),
      // If we're asking for specific conversions/events we want to include all of them
      // regardless of the `includeAllEvents` setting.
      is_conversion_event: computed(() =>
        conversions || dataFiltersStore.includeAllEvents ? null : true,
      ),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listConversionDatapointsByWeekdayAndHour,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          ((conversions !== undefined && conversions.value.length > 0) ||
            calculatedResources.resource__in.value?.length === 1),
      ),
    }),
  );
}

export interface GoogleAnalytics4ConversionMetricsByDateDataset {
  labels: string[];
  datasets: Required<Pick<TimeSeriesDataset, "label" | "data">>[];
}

export type GoogleAnalytics4ConversionMetricsByDate = Record<
  "eventCount" | "value",
  GoogleAnalytics4ConversionMetricsByDateDataset
>;

function listGoogleAnalytics4ConversionMetricsByDate({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    timeUnit: TimeUnit;
    breakdown: null | "event" | "category";
    conversion__in: number[] | null;
    metricFilterDimensions?: string[];
    metricFilterDimensionValues?: string[][];
  } & TemporalDataFilter &
    ResourcesDatafilter &
    IsConversionEventFilter &
    PublicDashboardWidgetFilters
>): Promise<GoogleAnalytics4ConversionMetricsByDate> {
  return api
    .get("/resources/website_performance/conversion_datapoints_by_date/", {
      params: {
        ...params,
        metricFilterDimensionValues:
          (params.metricFilterDimensionValues ?? []).length > 0
            ? JSON.stringify(params.metricFilterDimensionValues)
            : undefined,
      },
    })
    .then((response) => response.data);
}

export function useGoogleAnalytics4ConversionMetricsByDateQuery({
  metricFilterDimensions,
  metricFilterDimensionValues,
  selectedResourceIds,
  timeUnit = ref(TimeUnit.DAY),
  enabled,
  breakdown,
  pks,
  useGlobalFilters = true,
  temporalFilter,
}: {
  metricFilterDimensions?: Ref<string[]>;
  metricFilterDimensionValues?: string[][] | undefined;
  selectedResourceIds?: Ref<number[]>;
  timeUnit: Ref<TimeUnit>;
  enabled?: Ref<boolean>;
  breakdown: Ref<null | "event" | "category">;
  pks?: Ref<number[]>;
  useGlobalFilters?: boolean;
  temporalFilter?: Ref<DateRangeSelection>;
}) {
  const calculatedResources = useCalculatedResources(selectedResourceIds);
  const queryKey = [
    "googleAnalytics4ConversionMetricsByDate",
    {
      ...useDataFilters(["includeAllEvents"], useGlobalFilters),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      ...calculatedResources,
      metricFilterDimensions,
      metricFilterDimensionValues,
      timeUnit,
      breakdown,
      conversion__in: computed(() => pks?.value ?? null),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listGoogleAnalytics4ConversionMetricsByDate,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          calculatedResources.resource__in.value?.length === 1,
      ),
    }),
  );
}

export interface GoogleAnalytics4ConversionMetricsByArbitraryDimensionsConversions {
  eventCount: number | null;
  conversions: { name: string; eventCount: number | null }[];
}

export type GoogleAnalytics4ConversionMetricsByArbitraryDimensionsRow =
  GoogleAnalytics4ConversionMetricsByArbitraryDimensionsConversions &
    Partial<Record<Analytics4Dimension, string>>;

export interface GoogleAnalytics4ConversionMetricsByArbitraryDimensions {
  count: number;
  next: string | null;
  previous: string | null;
  conversions: string[];
  results: GoogleAnalytics4ConversionMetricsByArbitraryDimensionsRow[];
  pageTotals: GoogleAnalytics4ConversionMetricsByArbitraryDimensionsConversions;
  overallTotals: GoogleAnalytics4ConversionMetricsByArbitraryDimensionsConversions;
}

function listGoogleAnalytics4ConversionMetricsByArbitraryDimensions({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    dimensions: string[];
    metricFilterDimensions?: string[];
    metricFilterDimensionValues?: string[][];
    ordering: string;
    search: string | undefined;
    page: number;
    pageSize: number;
    conversion__in: number[] | null;
  } & SelectionTemporalDataFilter &
    ResourcesDatafilter &
    IsConversionEventFilter &
    PublicDashboardWidgetFilters
>): Promise<GoogleAnalytics4ConversionMetricsByArbitraryDimensions> {
  return api
    .get(
      "/resources/website_performance/ganalytics_4_conversions_by_arbitrary_dimensions/",
      {
        params: {
          ...params,
          metricFilterDimensionValues:
            (params.metricFilterDimensionValues ?? []).length > 0
              ? JSON.stringify(params.metricFilterDimensionValues)
              : undefined,
        },
      },
    )
    .then((response) => response.data);
}

export function useGoogleAnalytics4ConversionMetricsByArbitraryDimensionsQuery({
  dimensions,
  metricFilterDimensions,
  metricFilterDimensionValues,
  ordering,
  search,
  page,
  pageSize,
  selectedResourceIds,
  pks,
  enabled,
  useGlobalFilters = true,
  temporalFilter,
}: {
  dimensions: Ref<string[]>;
  metricFilterDimensions?: Ref<string[]>;
  metricFilterDimensionValues?: string[][] | undefined;
  ordering: Ref<string>;
  search: Ref<string | undefined>;
  page: Ref<number>;
  pageSize: Ref<number>;
  selectedResourceIds?: Ref<number[]>;
  pks?: Ref<number[]>;
  enabled?: Ref<boolean>;
  useGlobalFilters?: boolean;
  temporalFilter?: Ref<DateRangeSelection>;
}) {
  const calculatedResources = useCalculatedResources(selectedResourceIds);
  const queryKey = [
    "googleAnalytics4ConversionMetricsByArbitraryDimensions",
    {
      ...useDataFilters(["includeAllEvents"], useGlobalFilters),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      ...calculatedResources,
      dimensions,
      metricFilterDimensions,
      metricFilterDimensionValues,
      ordering,
      search,
      page,
      pageSize,
      conversion__in: computed(() => pks?.value ?? null),
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listGoogleAnalytics4ConversionMetricsByArbitraryDimensions,
      staleTime: 60 * 60 * 1000,
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
      enabled: computed(
        () =>
          (enabled?.value ?? true) &&
          calculatedResources.resource__in.value?.length === 1,
      ),
      retry: (failureCount, error) => {
        if (
          isAxiosError(error) &&
          error.response?.data.nonFieldErrors?.code ===
            "invalid_metrics_or_dimensions"
        ) {
          return false;
        }
        return failureCount < 3;
      },
    }),
  );
}

export type GoogleAnalytics4SpecificConversionMetricsByArbitraryDimensionsRow =
  Record<"eventCount" | "value", number | null> &
    Partial<Record<Analytics4Dimension, string>>;

export type GoogleAnalytics4SpecificConversionMetricsByArbitraryDimensions =
  PaginatedGoogleAnalytics4Result<GoogleAnalytics4SpecificConversionMetricsByArbitraryDimensionsRow>;

function listGoogleAnalytics4SpecificConversionMetricsByArbitraryDimensions({
  queryKey: [, { pk, ...params }],
}: QueryFunctionContext<
  {
    dimensions: string[];
    metricFilterDimensions?: string[];
    metricFilterDimensionValues?: string[][];
    ordering: string;
    search: string | undefined;
    page: number;
    pageSize: number;
    pk: number;
  } & SelectionTemporalDataFilter &
    PublicDashboardWidgetFilters
>): Promise<GoogleAnalytics4SpecificConversionMetricsByArbitraryDimensions> {
  return api
    .get(
      `/resources/website_performance/conversions/${pk}/metrics_by_arbitrary_dimensions/`,
      {
        params: {
          ...params,
          metricFilterDimensionValues:
            (params.metricFilterDimensionValues ?? []).length > 0
              ? JSON.stringify(params.metricFilterDimensionValues)
              : undefined,
        },
      },
    )
    .then((response) => response.data);
}

export function useGoogleAnalytics4SpecificConversionMetricsByArbitraryDimensionsQuery({
  dimensions,
  metricFilterDimensions,
  metricFilterDimensionValues,
  ordering,
  search,
  page,
  pageSize,
  pk,
  enabled,
}: {
  dimensions: Ref<string[]>;
  metricFilterDimensions?: Ref<string[]>;
  metricFilterDimensionValues?: string[][] | undefined;
  ordering: Ref<string>;
  search: Ref<string | undefined>;
  page: Ref<number>;
  pageSize: Ref<number>;
  pk: Ref<number>;
  enabled?: Ref<boolean>;
}) {
  const queryKey = [
    "googleAnalytics4SpecificConversionMetricsByArbitraryDimensions",
    {
      ...useDataFilters(["selectionTemporalFilter"]),
      ...usePublicDashboardWidgetQueryFilters(),
      dimensions,
      metricFilterDimensions,
      metricFilterDimensionValues,
      ordering,
      search,
      page,
      pageSize,
      pk,
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn:
        listGoogleAnalytics4SpecificConversionMetricsByArbitraryDimensions,
      staleTime: 60 * 60 * 1000,
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
      enabled,
      retry: (failureCount, error) => {
        if (
          isAxiosError(error) &&
          error.response?.data.nonFieldErrors?.code ===
            "invalid_metrics_or_dimensions"
        ) {
          return false;
        }
        return failureCount < 3;
      },
    }),
  );
}

export interface ResourceConsolidation {
  pk: number;
  universalAnalyticsResource: number;
  googleAnalytics4Resource: number;
  takeoverDate: string;
}

function getResourceConsolidations(): Promise<ResourceConsolidation[]> {
  return api
    .get("/resources/website_performance/consolidations/")
    .then((response) => response.data);
}

export function useResourceConsolidationsQuery() {
  return reactive(
    useQuery({
      queryKey: ["consolidations"],
      queryFn: getResourceConsolidations,
    }),
  );
}

function deleteResourceConsolidation(pk: number): Promise<void> {
  return api
    .delete(`/resources/website_performance/consolidations/${pk}/`)
    .then((response) => response.data);
}

export function useDeleteResourceConsolidationMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: deleteResourceConsolidation,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["consolidations"] });
        queryClient.invalidateQueries({
          queryKey: ["googleAnalyticsConversionConsolidations"],
        });
      },
    }),
  );
}

export function useGoogleAnalytics4ArbitraryDimensionsRequestError({
  error,
  dimensions,
  metrics,
}: {
  error: Ref<Error | null>;
  dimensions: Ref<string[]>;
  metrics: Ref<MetricKey[]>;
}) {
  function join(strings: string[]) {
    let joined = "";
    strings.forEach((string, index) => {
      joined += string;
      if (index < strings.length - 2) {
        joined += ", ";
      } else if (index === strings.length - 2) {
        joined += " and ";
      }
    });
    return joined;
  }

  const errorMessage = computed(() => {
    if (isAxiosError(error.value)) {
      if (
        error.value.response?.data.nonFieldErrors?.code ===
        "invalid_metrics_or_dimensions"
      ) {
        const details = error.value.response.data.nonFieldErrors as {
          message: string;
          metrics: MetricKey[];
          dimensions: string[];
          code: string;
        };
        let message = "Your dimensions and metrics are incompatible.\n";
        if (details.dimensions.length) {
          if (details.dimensions.length === dimensions.value.length) {
            message +=
              "None of your dimensions are compatible, please choose a different set of dimensions.";
          } else {
            message += "Remove these incompatible dimensions: ";
            message += join(
              details.dimensions.map((dimension) => sentenceCase(dimension)),
            );
          }
        } else {
          // Empty metrics indicates that the metrics are static.
          if (metrics.value.length === 0) {
            message += "Please choose a different set of dimensions.";
          } else {
            if (details.metrics.length === metrics.value.length) {
              message +=
                "None of your metrics are compatible, please choose a different set of metrics.";
            } else {
              message += "Remove these incompatible metrics: ";
              message += join(
                details.metrics.map(
                  (metric) => metricDetails[metric].shortName,
                ),
              );
            }
          }
        }
        return message;
      }
    }
    return String(error.value);
  });

  const allowRetry = computed(
    () =>
      !(
        isAxiosError(error.value) &&
        error.value.response?.data.nonFieldErrors?.code ===
          "invalid_metrics_or_dimensions"
      ),
  );

  return { errorMessage, allowRetry };
}
