export interface Page {
  title: string;
  description?: string;
  videoUrl?: string;
}

export interface NewFeaturePopup {
  id: string;
  pages: Page[];
}

export function dismissSelectedPopups(ids: string[]) {
  ids.forEach((id) => {
    localStorage.setItem(`dismissedFeaturePopup-${id}`, "true");
  });
  localStorage.setItem("dismissedSelectedPopups", "true");
}

export const NEW_FEATURE_POP_UP: Record<string, NewFeaturePopup> = {
  "website-analytics-acquisition-tab": {
    id: "onePage",
    pages: [
      {
        title:
          "You can now add and remove columns to sort out all your tables in Fyr!",
        description:
          "Select the number of metrics you wish to see in the table, and drag and drop to organise the columns.",
        videoUrl:
          "https://lightkeeper-dev.ams3.digitaloceanspaces.com/new_feature_video/customize_columns.webm",
      },
    ],
  },
  "ad-performance-overview": {
    id: "twoPages",
    pages: [
      {
        title:
          "You can now add and remove columns to sort out all your tables in Fyr!",
        description:
          "Select the number of metrics you wish to see in the table, and drag and drop to organise the columns.",
        videoUrl:
          "https://lightkeeper-dev.ams3.digitaloceanspaces.com/new_feature_video/customize_columns.webm",
      },
      {
        title:
          "You can now edit the budget of your campaigns directly from Fyr!",
        description:
          "The new changes will also be instantly modified on the channel where the campaign has been originally created.",
        videoUrl:
          "https://lightkeeper-dev.ams3.digitaloceanspaces.com/new_feature_video/Screen%20Recording%202024-06-17%20at%2013.59.51%20(1).webm",
      },
    ],
  },
  "search-term-report": {
    id: "onePage",
    pages: [
      {
        title:
          "You can now add and remove columns to sort out all your tables in Fyr!",
        description:
          "Select the number of metrics you wish to see in the table, and drag and drop to organise the columns.",
        videoUrl:
          "https://lightkeeper-dev.ams3.digitaloceanspaces.com/new_feature_video/customize_columns.webm",
      },
    ],
  },
};
