import api from "@/services/api";
import {
  TimeUnit,
  ResourcesDatafilter,
  TagsDataFilter,
  TemporalDataFilter,
  SeparateResourcesFilter,
  DateRangeSelection,
  SelectionTemporalDataFilter,
  TemporalPeriodsType,
} from "@/services/data_filters";
import { ResourceChannel } from "@/services/resources";
import { computed, ComputedRef, reactive, ref, Ref, unref } from "vue";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import {
  useCalculatedResources,
  useDetachedTemporalFilter,
  useDataFilters,
  useDetachedSelectionTemporalFilter,
  useDetachedTagFilter,
} from "@/store/data_filters";
import { QueryFunctionContext } from "@/utils/typing";
import {
  PublicDashboardWidgetFilters,
  usePublicDashboardWidgetQueryFilters,
} from "@/services/public_dashboard";
import * as lodash from "lodash-es";
import { MetricFilterSet, MetricKey, MetricKeys } from "@/services/metrics";
import {
  CompetitorPost,
  useCompetitorsQuery,
  competitorById,
  Competitor,
} from "@/services/competitor_monitoring";
import { useResourcesQuery } from "@/services/resources";
import { isCompetitorPost } from "@/views/CompetitorMonitor";
import {
  FacebookOrganicResource,
  InstagramOrganicResource,
  Resource,
} from "@/services/resources";
import { filterCriteriaFormatter } from "@/utils/filterCriteriaFormatter";
import { HeatmapWeekdayData } from "@/utils/heatmap";
import { DateTime } from "luxon";

export const SOCIAL_MEDIA_RESOURCE_CHANNELS = [
  ResourceChannel.LINKED_IN_ORGANIC,
  ResourceChannel.FACEBOOK_ORGANIC,
  ResourceChannel.INSTAGRAM_ORGANIC,
  ResourceChannel.YOUTUBE,
  ResourceChannel.TIK_TOK_ORGANIC,
] as const;

export type SocialMediaResourceChannel =
  (typeof SOCIAL_MEDIA_RESOURCE_CHANNELS)[number];

export const SOCIAL_MEDIA_POSTS_SORT_METRICS = [
  "bounceRate",
  "clicks",
  "clickthroughRate",
  "comments",
  "conversions",
  "engagement",
  "engagementRate",
  "impressions",
  "pageViewsPerSession",
  "publishedAt",
  "reactions",
  "shares",
] as const;

export const SOCIAL_MEDIA_POST_METRICS: MetricKey[] = [
  "impressions",
  "clicks",
  "comments",
  "reactions",
  "shares",
  "clickthroughRate",
  "engagement",
  "engagementRate",
];
export type SocialMediaPostMetrics = (typeof socialMediaPostMetrics)[number];

const socialMediaPostMetrics = lodash.pick(
  MetricKeys,
  SOCIAL_MEDIA_POST_METRICS,
);

export interface PageMetricByDateAndPlatformDataset {
  label: string;
  channel: SocialMediaResourceChannel;
  resource: string | null;
  data: (number | null)[];
  period: TemporalPeriodsType;
}

export interface PageMetricByDateAndPlatform {
  labels: string[];
  datasets: PageMetricByDateAndPlatformDataset[];
}

export interface PageMetricsByDateAndPlatform {
  followers: PageMetricByDateAndPlatform;
  impressions: PageMetricByDateAndPlatform;
  clicks: PageMetricByDateAndPlatform;
  clickthroughRate: PageMetricByDateAndPlatform;
  shares: PageMetricByDateAndPlatform;
  comments: PageMetricByDateAndPlatform;
  reactions: PageMetricByDateAndPlatform;
  engagement: PageMetricByDateAndPlatform;
}
export type TemporalPageMetricsByDateAndPlatform = Record<
  TemporalPeriodsType,
  PageMetricsByDateAndPlatform
>;

function listPageMetricsByDateAndPlatform({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    timeUnit: TimeUnit;
  } & SeparateResourcesFilter &
    TemporalDataFilter &
    ResourcesDatafilter &
    PublicDashboardWidgetFilters
>): Promise<TemporalPageMetricsByDateAndPlatform> {
  return api
    .get("/social_media/page_metrics_by_date_and_platform/", { params })
    .then((response) => response.data);
}

const pageMetricsByDateAndPlatformQueryMetrics: MetricKey[] = [
  "followers",
  "impressions",
  "clicks",
  "clickthroughRate",
  "shares",
  "comments",
  "reactions",
  "engagement",
];
export function usePageMetricsByDateAndPlatformQuery({
  selectedResourceIds,
  temporalFilter,
  timeUnit = ref(TimeUnit.DAY),
  enabled,
  useGlobalFilters = true,
}: {
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  timeUnit?: Ref<TimeUnit>;
  enabled?: Ref<boolean>;
  useGlobalFilters?: boolean;
} = {}) {
  const queryKey = [
    "pageMetricsByDateAndPlatformQuery",
    {
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useDataFilters(["separateResources"], useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      timeUnit,
    },
  ] as const;
  return reactive(
    useQuery({ queryKey, queryFn: listPageMetricsByDateAndPlatform, enabled }),
  );
}
export const usePageMetricsByDateAndPlatformQueryWithMetrics = {
  query: usePageMetricsByDateAndPlatformQuery,
  metrics: pageMetricsByDateAndPlatformQueryMetrics,
};

export interface PageMetricsByComparisonPeriod {
  label: "current" | "previous";
  followers: number | null;
  impressions: number | null;
  clicks: number | null;
  clickthroughRate: number | null;
  shares: number | null;
  comments: number | null;
  reactions: number | null;
  engagement: number | null;
  engagementRate: number | null;
}

export interface PageMetricsByComparison {
  currentPeriod: PageMetricsByComparisonPeriod;
  previousPeriod: PageMetricsByComparisonPeriod;
}

function listPageMetricsByComparison({
  queryKey: [, params],
}: QueryFunctionContext<
  TemporalDataFilter & ResourcesDatafilter & PublicDashboardWidgetFilters
>): Promise<PageMetricsByComparison> {
  return api
    .get("/social_media/page_metrics_compared_to_previous_period/", { params })
    .then((response) => response.data);
}

const pageMetricsByComparisonMetrics: MetricKey[] = [
  "followers",
  "impressions",
  "clicks",
  "clickthroughRate",
  "shares",
  "comments",
  "reactions",
  "engagement",
];
export function usePageMetricsByComparisonQuery({
  selectedResourceIds,
  temporalFilter,
  enabled,
  useGlobalFilters = true,
}: {
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  enabled?: Ref<boolean>;
  useGlobalFilters?: boolean;
} = {}) {
  const queryKey = [
    "pageMetricsByComparison",
    {
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
    },
  ] as const;
  return reactive(
    useQuery({ queryKey, queryFn: listPageMetricsByComparison, enabled }),
  );
}
export const usePageMetricsByComparisonQueryWithMetrics = {
  query: usePageMetricsByComparisonQuery,
  metrics: pageMetricsByComparisonMetrics,
};

export interface OpenGraphData {
  link: string;
  url: string;
  type: string | null;
  title: string | null;
  image: string | null;
  description: string | null;
  siteName: string | null;
}

export interface PostAsset {
  mediaType: string;
  mediaUrl: string;
  targetUrl: string;
}

export enum PostAssetImageTypes {
  image = "image",
  photo = "photo",
  carousel_album__image = "carousel_album__image",
  album__image = "album__image",
  album__photo = "album__photo",
  multi_share__share = "multi_share__share",
  multi_share_no_end_card__share = "multi_share_no_end_card__share",
  profile_media = "profile_media",
  profile_media__photo = "profile_media__photo",
}

export enum PostAssetPageUpdateTypes {
  cover_photo = "cover_photo",
  profile_media = "profile_media",
}

export enum PostAssetVideoTypes {
  video = "video",
  reels = "reels",
  carousel_album__video = "carousel_album__video",
}

export enum PostAssetVideoThumbnailTypes {
  video_direct_response__video = "video_direct_response__video",
  animated_image_video__video = "animated_image_video__video",
}

export enum PostAssetLinkTypes {
  link = "link",
}

export enum PostAssetShareTypes {
  share = "share",
}

export enum VideoPostTypes {
  video = "video",
  reels = "reels",
}

export enum CollectionPostTypes {
  carousel = "carousel",
  album = "album",
  multi_share = "multi_share",
  multi_share_no_end_card = "multi_share_no_end_card",
}

export enum ImagePostTypes {
  image = "image",
  photo = "photo",
}

export enum VideoThumbnailPostTypes {
  video_inline = "video_inline",
  video_direct_response = "video_direct_response",
  animated_image_video = "animated_image_video",
}

export enum LinkPostTypes {
  link = "link",
}

export enum SharePostTypes {
  share = "share",
}

export enum FeedPostTypes {
  feed = "feed",
}

export enum PageUpdatePostTypes {
  cover_photo = "cover_photo",
  profile_media = "profile_media",
}

export enum PostType {
  IMAGE = "image",
  VIDEO = "video",
  VIDEO_THUMBNAIL = "video_thumbnail",
  COLLECTION = "collection",
  LINK = "link",
  SHARE = "share",
  FEED = "feed",
  PAGE_UPDATE = "page_update",
  UNKNOWN = "unknown",
}

export interface Post {
  pk: number;
  resource: number;
  channel: SocialMediaResourceChannel;
  externalId: string;
  publishedAt: string | null;
  text: string;
  assets: PostAsset[];
  postType: string;
  destinationUrl: string;
  permalinkUrl: string | null;
  openGraphData: OpenGraphData[];
  isSponsored: boolean;
  impressions: number;
  clicks: number;
  comments: number;
  reactions: number;
  shares: number;
  clickthroughRate: number | null;
  engagement: number;
  engagementRate: number | null;
  pageViewsPerSession: number | null;
  conversions: number | null;
  bounceRate: number | null;
  tags: number[];
  extra?: {
    title?: string;
  };
}

function listPosts({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    filterCriteria?: MetricFilterSet[];
  } & SelectionTemporalDataFilter &
    ResourcesDatafilter &
    TagsDataFilter
>): Promise<Post[]> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);
  return api
    .get("/social_media/posts/", { params: { ...params, ...filterCriteria } })
    .then((response) => response.data);
}

export function usePostsQuery({
  filterCriteria,
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters,
}: {
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
} = {}) {
  const queryKey = [
    "posts",
    {
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      filterCriteria,
    },
  ] as const;
  return reactive(useQuery({ queryKey, queryFn: listPosts }));
}

function getPost({
  queryKey: [, { pk }],
}: QueryFunctionContext<{ pk: number }>): Promise<Post> {
  return api
    .get(`/social_media/posts/${pk}/`)
    .then((response) => response.data);
}

export function usePostQuery({ pk }: { pk: Ref<number> }) {
  const queryClient = useQueryClient();
  const queryKey = ["post", { pk }] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: getPost,
      initialData: () => {
        return queryClient
          .getQueriesData<Post[] | undefined>({ queryKey: ["posts"] })
          .flatMap(([_, posts]) => posts ?? [])
          .find((post) => post.pk === pk.value);
      },
    }),
  );
}

export function determinePostType(
  inputPost: Post | CompetitorPost | ComputedRef<Post | CompetitorPost>,
): ComputedRef<PostType> {
  return computed(() => {
    const post = unref(inputPost);
    const media = post.postType.toLowerCase();

    if (media in ImagePostTypes) {
      return PostType.IMAGE;
    } else if (media in VideoPostTypes) {
      if (post.channel === ResourceChannel.LINKED_IN_ORGANIC) {
        return PostType.LINK;
      } else {
        return PostType.VIDEO;
      }
    } else if (media in LinkPostTypes) {
      return PostType.LINK;
    } else if (media in SharePostTypes) {
      return PostType.SHARE;
    } else if (media in CollectionPostTypes) {
      return PostType.COLLECTION;
    } else if (media in VideoThumbnailPostTypes) {
      return PostType.VIDEO_THUMBNAIL;
    } else if (media in PageUpdatePostTypes) {
      return PostType.PAGE_UPDATE;
    } else if (media in FeedPostTypes) {
      if (post.assets.length > 1) {
        return PostType.COLLECTION;
      } else if (post.assets.length === 1) {
        if (post.assets[0].mediaType.toLowerCase() in PostAssetImageTypes) {
          return PostType.IMAGE;
        } else if (
          post.assets[0].mediaType.toLowerCase() in PostAssetVideoTypes
        ) {
          return PostType.VIDEO;
        }
      }
    } else if (post.openGraphData[0]) {
      return PostType.LINK;
    }
    return PostType.UNKNOWN;
  });
}

export function getSocialMediaProfileImageUrl(
  inputPost: Post | CompetitorPost | ComputedRef<Post | CompetitorPost>,
): ComputedRef<string | undefined> {
  const resources = useResourcesQuery();
  const competitors = useCompetitorsQuery({
    enabled: computed(() => isCompetitorPost(unref(inputPost))),
  });
  return computed(() => {
    const post = unref(inputPost);
    if (isCompetitorPost(post)) {
      const competitor = competitorById(post.competitor, competitors);
      if (post.channel === ResourceChannel.FACEBOOK_ORGANIC) {
        return competitor?.facebookProfileImageUrl ?? undefined;
      } else if (post.channel === ResourceChannel.INSTAGRAM_ORGANIC) {
        return competitor?.instagramProfileImageUrl ?? undefined;
      } else {
        return "";
      }
    } else {
      const resource = resources.data?.find(
        (resource) => resource.pk === (post as Post).resource,
      ) as FacebookOrganicResource | InstagramOrganicResource | undefined;
      return resource?.metadata.profileImageUrl ?? undefined;
    }
  });
}

export function getSocialMediaProfileObject(
  inputPost: Post | CompetitorPost | ComputedRef<Post | CompetitorPost>,
): ComputedRef<Resource | Competitor | undefined> {
  const resources = useResourcesQuery();
  const competitors = useCompetitorsQuery({
    enabled: computed(() => isCompetitorPost(unref(inputPost))),
  });
  return computed(() => {
    const post = unref(inputPost);
    if (isCompetitorPost(post)) {
      return competitorById(post.competitor, competitors);
    }
    return resources.data?.find(
      (resource) => resource.pk === (post as Post).resource,
    );
  });
}

export type PostFrequency = HeatmapWeekdayData;

export function listPostFrequency({
  queryKey: [, params],
}: QueryFunctionContext<
  SelectionTemporalDataFilter & TagsDataFilter & ResourcesDatafilter
>): Promise<PostFrequency> {
  return api
    .get(`/social_media/post_frequency/`, {
      params: { ...params, timeZone: DateTime.local().zoneName },
    })
    .then((response) => response.data);
}

export function usePostFrequencyQuery() {
  const queryKey = [
    "competitorPostFrequency",
    {
      ...useDataFilters([
        "includedResources",
        "selectionTemporalFilter",
        "includedTags",
        "excludedTags",
      ]),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listPostFrequency,
      placeholderData: keepPreviousData,
    }),
  );
}
