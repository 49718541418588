import { CompetitorPost } from "@/services/competitor_monitoring";
import { Post } from "@/services/social_media";
import { DeepReadonly } from "vue";

export function isOrganizationPost(
  post: DeepReadonly<Post | CompetitorPost>,
): post is Post {
  return "resource" in post;
}
export function isCompetitorPost(
  post: DeepReadonly<Post | CompetitorPost>,
): post is CompetitorPost {
  return "competitor" in post;
}
