import { useGtm } from "@gtm-support/vue-gtm";
import * as lodash from "lodash-es";
import { ref, Ref, shallowRef, watch } from "vue";
import { useProfileQuery } from "./user";
import { useAuthStore } from "@/store/auth";
import { sentenceCase } from "@/utils/strings";

export enum GTMCategory {
  // Categories where you typically add, edit, pin, and delete stuff
  MANAGE_DASHBOARDS = "Management - Dashboards",
  MANAGE_TAGS = "Management - Tags",
  MANAGE_RESOURCES = "Management - Resources",
  MANAGE_RESOURCE_GROUPS = "Management - Resource groups",
  MANAGE_USERS = "Management - Users",
  MANAGE_INVITES = "Management - Invites",
  MANAGE_COMPETITORS = "Management - Competitors",
  MANAGE_BUDGETS = "Management - Budgets",
  MANAGE_GOALS = "Management - Goals",
  MANAGE_ANNOTATIONS = "Management - Annotations",
  // Settings concerning the user or the organization (not management of organization-bound resources such as resources, invites, users)
  SETTINGS_ORGANIZAITON = "Settings - Organization",
  SETTINGS_USER = "Settings - User",
  // Datapresentation views, actions mostly related to filtering
  REPORTING_DASHBOARD = "Reporting - Dashboard",
  REPORTING_MARKETING = "Reporting - Marketing",
  REPORTING_SPENDING = "Reporting - Spending",
  REPORTING_SEARCH_TERMS = "Reporting - Search terms",
  REPORTING_SOCIAL_MEDIA = "Reporting - Social media",
  REPORTING_WEBSITE = "Reporting - Website",
  REPORTING_WEBSITE_ANALYTICS = "Reporting - Website analytics",
  REPORTING_SITE_SCORE = "Reporting - Site score",
  REPORTING_PAGE_INSIGHTS = "Reporting - Page insights",
  REPORTING_ECOMMERCE = "Reporting - E-commerce",
  REPORTING_GOALS = "Reporting - Goals",
  REPORTING_RECOMMENDATIONS = "Reporting - Recommendations",
  REPORTING_COMPETITOR_MONITOR = "Reporting - Competitor monitor",
  // Other
  OTHER_CONTACT = "Other - Contact",
  OTHER_ETC = "Other - ETC",
  OTHER_AUTHENTICATION = "Other - Authentication",
  TOOLS_UTM_BILDER = "Tools - UTM-builder",
  TOOLS_CONTENT_LAB = "Tools - Content lab",
}

export enum GTMAction {
  // callable as function names: "gtm.startCreating" logs an event with `action: "Start creating"`
  // General management
  START_CREATING = "startCreating", // initializing a creation process
  START_EDITING = "startEditing",
  START_DELETING = "startDeleting",
  SAVE_CREATE = "saveCreate", // finalizing a creation process
  SAVE_EDIT = "saveEdit",
  SAVE_DELETE = "saveDelete",
  IS_CREATING = "isCreating", // if there are multiple steps needed inbetween initializing and finalization
  IS_EDITING = "isEditing",
  IS_DELETING = "isDeleting",
  PIN = "pin", // TODO: Track start and save?
  UNPIN = "unpin",

  // Resources management
  START_REFRESHING = "startRefreshing",
  START_REVOKING = "startRevoking",
  IS_REFRESHING = "isRefreshing",
  SAVE_REFRESH = "saveRefresh",
  SAVE_REVOKE = "saveRevoke",

  // Users management
  START_PROMOTING = "startPromoting",
  SAVE_PROMOTE = "savePromote",
  START_DEMOTING = "startDemoting",
  SAVE_DEMOTE = "saveDemote",
  START_ACTIVATING = "startActivating",
  SAVE_ACTIVATE = "saveActivate",
  START_DEACTIVATING = "startDeactivating",
  SAVE_DEACTIVATE = "saveDeactivate",

  // Invites management
  START_RESENDING = "startResending",
  SAVE_RESEND = "saveResend",

  // Reporting
  GENERATE_META_DESCRIPTION_SUGGESTIONS = "generateMetaDescriptionSuggestions",
  COPY_META_DESCRIPTION_SUGGESTION = "copyMetaDescriptionSuggestion",
  EDIT_META_DESCRIPTION_SUGGESTION = "editMetaDescriptionSuggestion",

  // Other - Authentication
  REQUEST_PASSWORD_RESET = "requestPasswordReset",
  START_RESETTING_PASSWORD = "startResettingPassword",
  SAVE_RESET_PASSWORD = "saveResetPassword",

  // User settings
  START_CHANGING_PASSWORD = "startChangingPassword",
  SAVE_CHANGE_PASSWORD = "saveChangePassword",
  TOGGLE_COLORBLIND_MODE = "toggleColorblindMode",
  TOGGLE_EMAIL_NOTIFICATIONS = "toggleEmailNotifications",

  // Tools - Content Lab
  GENERATE_WEBSITE_CONTENT_TITLES = "generateWebsiteContentTitles",
  GENERATE_WEBSITE_CONTENT_OUTLINE = "generateWebsiteContentOutline",
  GENERATE_WEBSITE_CONTENT_ARTICLE = "generateWebsiteContentArticle",
  IMPROVE_WEBSITE_CONTENT_ARTICLE = "improveWebsiteContentArticle",
  GENERATE_WEBSITE_CONTENT_SUMMARY = "generateWebsiteContentSummary",
  IMPROVE_WEBSITE_CONTENT_SUMMARY = "improveWebsiteContentSummary",
  GENERATE_WEBSITE_CONTENT_QUESTIONS = "generateWebsiteContentQuestions",
  IMPROVE_UNKNOWN_CONTENT = "improveUnknownContent",
  GENERATE_SOCIAL_MEDIA_POST = "generateSocialMediaPost",
  IMPROVE_SOCIAL_MEDIA_POST = "improveSocialMediaPost",
  // COPY_WEBSITE_CONTENT_ARTICLE = "copyWebsiteContentArticle",
  // COPY_SOCIAL_MEDIA_POST = "copySocialMediaPost",
  // COPY_IMPROVED_UNKNOWN_CONTENT = "copyImprovedUnknownContent",
}

interface GTMEvent {
  category?: GTMCategory;
  action?: string;
  label?: string;
  value?: number;
}

interface GtmEnabling<T extends GTMAction> {
  enabled: Ref<boolean>;
  enable: (actions?: T[]) => void;
  disable: (actions?: T[]) => void;
  disabledActions: Ref<T[]>;
  isDisabled: (action: T) => boolean;
}

function useGtmEnabling<T extends GTMAction>(): GtmEnabling<T> {
  const enabled = ref(true);
  const disabledActions = shallowRef([] as T[]);
  function enable(actions?: T[]): void {
    if (!actions) enabled.value = true;
    else {
      disabledActions.value = lodash.without(disabledActions.value, ...actions);
    }
  }
  function disable(actions?: T[]): void {
    if (!actions) enabled.value = false;
    else {
      disabledActions.value = lodash.uniq([
        ...disabledActions.value,
        ...actions,
      ]);
    }
  }
  function isDisabled(action: T): boolean {
    return !disabledActions.value.includes(action);
  }

  return { disabledActions, enable, enabled, disable, isDisabled };
}

export type TrackingFunctionRegister<T extends GTMAction> = Record<
  T,
  (label?: string, val?: number, force?: boolean) => void
>;

export type TrackingManager<T extends GTMAction> = TrackingFunctionRegister<T> &
  GtmEnabling<T>;

export function track(event: GTMEvent): void {
  const gtm = useGtm();
  if (gtm) gtm.trackEvent({ ...event, isSpecificInteraction: false });
  else console.warn("No GTM instance found. Failed to track event ", event);
}

function createTrackingRegister<T extends GTMAction>(
  actions: readonly T[],
  category: GTMCategory,
): TrackingManager<T> {
  const { enable, disable, enabled, disabledActions, isDisabled } =
    useGtmEnabling<T>();

  function gtmTrack(action: GTMAction, label?: string, val?: number): void {
    track({
      category: category,
      action: sentenceCase(action),
      label: label,
      value: val,
    });
  }

  const register: Partial<TrackingFunctionRegister<T>> = {};

  actions.forEach((action) => {
    register[action] = (label, val, force) => {
      if (force || (enabled.value && !disabledActions.value.includes(action))) {
        gtmTrack(action, label, val);
      }
    };
  });
  return {
    ...(register as TrackingFunctionRegister<T>),
    enable,
    disable,
    enabled,
    disabledActions,
    isDisabled,
  };
}

const manageDashboardActions = [
  GTMAction.START_CREATING,
  GTMAction.START_EDITING,
  GTMAction.IS_CREATING,
  GTMAction.IS_EDITING,
  GTMAction.SAVE_CREATE,
  GTMAction.SAVE_EDIT,
  GTMAction.START_DELETING,
  GTMAction.SAVE_DELETE,
  GTMAction.PIN,
  GTMAction.UNPIN,
] as const;

export function useManageDashboardsTracking(): TrackingManager<
  (typeof manageDashboardActions)[number]
> {
  return createTrackingRegister<(typeof manageDashboardActions)[number]>(
    manageDashboardActions,
    GTMCategory.MANAGE_DASHBOARDS,
  );
}

const manageCompetitorsActions = [
  GTMAction.START_CREATING,
  GTMAction.START_EDITING,
  GTMAction.SAVE_CREATE,
  GTMAction.SAVE_EDIT,
  GTMAction.START_DELETING,
  GTMAction.SAVE_DELETE,
] as const;

export function useManageCompetitorsTracking(): TrackingManager<
  (typeof manageCompetitorsActions)[number]
> {
  return createTrackingRegister<(typeof manageCompetitorsActions)[number]>(
    manageCompetitorsActions,
    GTMCategory.MANAGE_COMPETITORS,
  );
}

const manageResourcesActions = [
  GTMAction.START_CREATING,
  GTMAction.IS_CREATING,
  GTMAction.START_EDITING,
  GTMAction.SAVE_CREATE,
  GTMAction.SAVE_EDIT,
  GTMAction.START_DELETING,
  GTMAction.SAVE_DELETE,
  GTMAction.START_REFRESHING,
  GTMAction.IS_REFRESHING,
  GTMAction.SAVE_REFRESH,
  GTMAction.START_REVOKING,
  GTMAction.SAVE_REVOKE,
] as const;

export function useManageResourcesTracking(): TrackingManager<
  (typeof manageResourcesActions)[number]
> {
  return createTrackingRegister<(typeof manageResourcesActions)[number]>(
    manageResourcesActions,
    GTMCategory.MANAGE_RESOURCES,
  );
}

const manageResourceGroupsActions = [
  GTMAction.START_CREATING,
  GTMAction.IS_CREATING,
  GTMAction.START_EDITING,
  GTMAction.SAVE_CREATE,
  GTMAction.SAVE_EDIT,
  GTMAction.START_DELETING,
  GTMAction.SAVE_DELETE,
] as const;

export function useManageResourceGroupsTracking(): TrackingManager<
  (typeof manageResourceGroupsActions)[number]
> {
  return createTrackingRegister<(typeof manageResourceGroupsActions)[number]>(
    manageResourceGroupsActions,
    GTMCategory.MANAGE_RESOURCE_GROUPS,
  );
}

const manageUsersActions = [
  GTMAction.START_PROMOTING,
  GTMAction.SAVE_PROMOTE,
  GTMAction.START_DEMOTING,
  GTMAction.SAVE_DEMOTE,
  GTMAction.START_ACTIVATING,
  GTMAction.SAVE_ACTIVATE,
  GTMAction.START_DEACTIVATING,
  GTMAction.SAVE_DEACTIVATE,
] as const;

export function useManageUsersTracking(): TrackingManager<
  (typeof manageUsersActions)[number]
> {
  return createTrackingRegister<(typeof manageUsersActions)[number]>(
    manageUsersActions,
    GTMCategory.MANAGE_USERS,
  );
}

const manageInvitesActions = [
  GTMAction.START_CREATING,
  GTMAction.SAVE_CREATE,
  GTMAction.START_DELETING,
  GTMAction.SAVE_DELETE,
  GTMAction.START_RESENDING,
  GTMAction.SAVE_RESEND,
] as const;

export function useManageInvitesTracking(): TrackingManager<
  (typeof manageInvitesActions)[number]
> {
  return createTrackingRegister<(typeof manageInvitesActions)[number]>(
    manageInvitesActions,
    GTMCategory.MANAGE_INVITES,
  );
}

const manageGoalActions = [
  GTMAction.START_CREATING,
  GTMAction.SAVE_CREATE,
  GTMAction.START_DELETING,
  GTMAction.SAVE_DELETE,
  GTMAction.START_EDITING,
  GTMAction.SAVE_EDIT,
  GTMAction.PIN,
  GTMAction.UNPIN,
] as const;

export function useManageGoalsTracking(): TrackingManager<
  (typeof manageGoalActions)[number]
> {
  return createTrackingRegister<(typeof manageGoalActions)[number]>(
    manageGoalActions,
    GTMCategory.MANAGE_GOALS,
  );
}

const manageTagsActions = [
  GTMAction.START_CREATING,
  GTMAction.SAVE_CREATE,
  GTMAction.START_DELETING,
  GTMAction.SAVE_DELETE,
  GTMAction.START_EDITING,
  GTMAction.SAVE_EDIT,
] as const;

export function useManageTagsTracking(): TrackingManager<
  (typeof manageTagsActions)[number]
> {
  return createTrackingRegister<(typeof manageTagsActions)[number]>(
    manageTagsActions,
    GTMCategory.MANAGE_TAGS,
  );
}

const manageBudgetActions = [
  GTMAction.START_CREATING,
  GTMAction.SAVE_CREATE,
  GTMAction.START_DELETING,
  GTMAction.SAVE_DELETE,
  GTMAction.START_EDITING,
  GTMAction.SAVE_EDIT,
] as const;

export function useManageBudgetsTracking(): TrackingManager<
  (typeof manageBudgetActions)[number]
> {
  return createTrackingRegister<(typeof manageBudgetActions)[number]>(
    manageBudgetActions,
    GTMCategory.MANAGE_BUDGETS,
  );
}

const reportingPageInsightsActions = [
  GTMAction.COPY_META_DESCRIPTION_SUGGESTION,
  GTMAction.GENERATE_META_DESCRIPTION_SUGGESTIONS,
  GTMAction.EDIT_META_DESCRIPTION_SUGGESTION,
] as const;

export function usePageInsightsTracking(): TrackingManager<
  (typeof reportingPageInsightsActions)[number]
> {
  return createTrackingRegister<(typeof reportingPageInsightsActions)[number]>(
    reportingPageInsightsActions,
    GTMCategory.REPORTING_PAGE_INSIGHTS,
  );
}

const reportingRecommendationActions = [
  GTMAction.COPY_META_DESCRIPTION_SUGGESTION,
  GTMAction.GENERATE_META_DESCRIPTION_SUGGESTIONS,
  GTMAction.EDIT_META_DESCRIPTION_SUGGESTION,
] as const;

export function useRecommendationsTracking(): TrackingManager<
  (typeof reportingRecommendationActions)[number]
> {
  return createTrackingRegister<
    (typeof reportingRecommendationActions)[number]
  >(reportingPageInsightsActions, GTMCategory.REPORTING_RECOMMENDATIONS);
}

const manageAnnotationsActions = [
  GTMAction.SAVE_DELETE,
  GTMAction.START_CREATING,
  GTMAction.IS_CREATING,
  GTMAction.SAVE_CREATE,
] as const;

export function useManageAnnotationsTracking(): TrackingManager<
  (typeof manageAnnotationsActions)[number]
> {
  return createTrackingRegister<(typeof manageAnnotationsActions)[number]>(
    manageAnnotationsActions,
    GTMCategory.MANAGE_ANNOTATIONS,
  );
}

const userSettingActions = [
  GTMAction.START_CHANGING_PASSWORD,
  GTMAction.SAVE_CHANGE_PASSWORD,
  GTMAction.TOGGLE_COLORBLIND_MODE,
  GTMAction.TOGGLE_EMAIL_NOTIFICATIONS,
] as const;

export function useUserSettingTracking(): TrackingManager<
  (typeof userSettingActions)[number]
> {
  return createTrackingRegister<(typeof userSettingActions)[number]>(
    userSettingActions,
    GTMCategory.SETTINGS_USER,
  );
}

const authenticationActions = [
  GTMAction.REQUEST_PASSWORD_RESET,
  GTMAction.START_RESETTING_PASSWORD,
  GTMAction.SAVE_RESET_PASSWORD,
] as const;

export function useAuthenticationTracking(): TrackingManager<
  (typeof authenticationActions)[number]
> {
  return createTrackingRegister<(typeof authenticationActions)[number]>(
    authenticationActions,
    GTMCategory.OTHER_AUTHENTICATION,
  );
}

const contentLabActions = [
  GTMAction.GENERATE_WEBSITE_CONTENT_TITLES,
  GTMAction.GENERATE_WEBSITE_CONTENT_OUTLINE,
  GTMAction.GENERATE_WEBSITE_CONTENT_ARTICLE,
  GTMAction.IMPROVE_WEBSITE_CONTENT_ARTICLE,
  GTMAction.GENERATE_WEBSITE_CONTENT_SUMMARY,
  GTMAction.IMPROVE_WEBSITE_CONTENT_SUMMARY,
  GTMAction.GENERATE_WEBSITE_CONTENT_QUESTIONS,
  GTMAction.IMPROVE_UNKNOWN_CONTENT,
  GTMAction.GENERATE_SOCIAL_MEDIA_POST,
  GTMAction.IMPROVE_SOCIAL_MEDIA_POST,
  // TODO:
  // GTMAction.COPY_WEBSITE_CONTENT_ARTICLE,
  // GTMAction.COPY_SOCIAL_MEDIA_POST,
  // GTMAction.COPY_IMPROVED_UNKNOWN_CONTENT,
] as const;

export function useContentLabTracking(): TrackingManager<
  (typeof contentLabActions)[number]
> {
  return createTrackingRegister<(typeof contentLabActions)[number]>(
    contentLabActions,
    GTMCategory.TOOLS_CONTENT_LAB,
  );
}

// User settings
export function gtmTrackUserAuthAction(
  action:
    | "Request password reset"
    | "Complete password reset"
    | "Change password",
  label?: string,
): GTMEvent {
  return {
    category: GTMCategory.SETTINGS_USER,
    action: action,
    label: label,
  };
}

// Recommendation
export function gtmRecommendationTaskStatus(
  action: "Complete" | "Dismiss" | "In progress" | "Open",
  label?: string,
): GTMEvent {
  return {
    category: GTMCategory.REPORTING_RECOMMENDATIONS,
    action: action,
    label: label,
  };
}

//Marketing Report
export function gtmSelectMetricMarketing(label?: string): GTMEvent {
  return {
    category: GTMCategory.REPORTING_MARKETING,
    action: "Metric",
    label: label,
  };
}

//Search Term Report
export function gtmSelectMetricSearchTerm(label?: string): GTMEvent {
  return {
    category: GTMCategory.REPORTING_SEARCH_TERMS,
    action: "Metric",
    label: label,
  };
}

//Website Traffic Report
export function gtmSelectMetricWebsiteTraffic(label?: string): GTMEvent {
  return {
    category: GTMCategory.REPORTING_WEBSITE,
    action: "Metric",
    label: label,
  };
}

//Site Score Report
export function gtmSiteScoreAction(label?: string): GTMEvent {
  return {
    category: GTMCategory.REPORTING_SITE_SCORE,
    action: "View",
    label: label,
  };
}

// Date Picker
// TODO: Track which page the datepicker was used on
export function gtmDatePickerAppliedDateRange(label?: string): GTMEvent {
  return {
    category: GTMCategory.OTHER_ETC,
    action: "Apply",
    label: label,
  };
}

//Social Media
export function gtmSelectMetricSocialMedia(label?: string): GTMEvent {
  return {
    category: GTMCategory.REPORTING_SOCIAL_MEDIA,
    action: "Metric",
    label: label,
  };
}

// Action bar filters (except for datepicker)
// TODO: Track filters used on etc pages
export function gtmFilterAction(action: string, label: string): GTMEvent {
  return {
    category: GTMCategory.OTHER_ETC,
    action: action,
    label: label,
  };
}

export function gtmReportingDashboard(
  action: "View custom" | "View default",
  label?: string,
): GTMEvent {
  return {
    category: GTMCategory.REPORTING_DASHBOARD,
    action: action,
    label: label,
  };
}

export function gtmTagItems(
  category: GTMCategory,
  label: "Bulk" | "Single",
): GTMEvent {
  return {
    category: category,
    action: "Tagging",
    label: label,
  };
}

// Search filter
export function gtmSearchFilterUsed(
  category: GTMCategory,
  label?: string,
): GTMEvent {
  return {
    category: category,
    action: "Search",
    label: label,
  };
}

// Landing page
export function gtmContact(action = "Demo request", label?: string): GTMEvent {
  return { category: GTMCategory.OTHER_CONTACT, action: action, label: label };
}

// Currency
export function gtmOrganizationSetting(
  action: "Currency" | "Name",
  label?: string,
): GTMEvent {
  return {
    category: GTMCategory.SETTINGS_ORGANIZAITON,
    action: action,
    label: label,
  };
}

// Setup prompt
export function gtmSetupPrompt(category: GTMCategory): GTMEvent {
  return {
    category: category,
    action: "Setup prompt CTA",
  };
}

// Tool
export function gtmUrlBuilder(action: string, label: string): GTMEvent {
  return {
    category: GTMCategory.TOOLS_UTM_BILDER,
    action: action,
    label: label,
  };
}
export function gtmVideoPlay(category: GTMCategory, label?: string): GTMEvent {
  return {
    category: category,
    action: "Video play",
    label: label,
  };
}

export function useTrackLogin(): void {
  const self = useProfileQuery();
  const gtm = useGtm();
  const authStore = useAuthStore();
  const trackLogin = ref(false);
  watch(
    () => authStore.isAuthed,
    () => {
      if (authStore.isAuthed) {
        trackLogin.value = true;
      }
    },
  );
  watch([() => self.data, trackLogin], () => {
    if (self.data && trackLogin.value) {
      if (gtm) {
        gtm.trackEvent({
          event: "OrganizationID",
          organizationId: self.data.organization,
          userId: self.data.id,
        });
      } else {
        console.warn("No GTM instance found. Failed to track login event");
      }
      trackLogin.value = false;
    }
  });
}

export function useTrack() {
  const gtm = useGtm();
  function track(
    event: string,
    params: Record<string, string> & {
      category?: string;
      label?: string;
      value?: number | string;
    } = {},
  ) {
    // To avoid adding a new tag in GTM for every event,
    // we use the default event name of "interaction"
    // and put the actual event name in `action`.
    // The tag in GTM transforms it so that GA4 receives the proper event name.
    // `isSpecificInteraction` is used to differentiate this from the old tracking
    // where every event ended up as "interaction" in GA4.
    gtm?.trackEvent({ isSpecificInteraction: true, action: event, ...params });
  }
  return track;
}

export function useTrackEnabled() {
  const gtm = useGtm();
  let isTrackingEnabled = true;

  function trackAsEvent(
    event: string,
    params: Record<string, string> & {
      category?: string;
      label?: string;
      value?: number | string;
    } = {},
  ) {
    if (isTrackingEnabled) {
      gtm?.trackEvent({
        isSpecificInteraction: true,
        action: event,
        ...params,
      });
    }
  }

  function setTrackingEnabled(enabled: boolean) {
    isTrackingEnabled = enabled;
  }

  return { trackAsEvent, setTrackingEnabled };
}

export function useTrackCompareMetrics(compareMetrics: Ref<boolean>): void {
  const track = useTrack();

  watch(compareMetrics, () => {
    if (compareMetrics.value) {
      track("click_compare_metrics");
    }
  });
}
