import api from "@/services/api";
import { reactive, computed, ComputedRef, DeepReadonly, Ref, toRef } from "vue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { setTag, setUser } from "@sentry/vue";
import { useAuthStore } from "@/store/auth";
import { useManageUsersTracking } from "@/services/gtm";
import Cookies from "universal-cookie";

export enum UserRole {
  USER = "USER",
  MANAGER = "MANAGER",
}
export const SystemUser = {
  id: -1,
  name: "Fyr",
  role: "System user",
  organization: -1,
  organizationName: "Fyr",
  isActive: true,
} as User;

export interface User {
  id: number;
  name: string;
  role: UserRole | string;
  organization: number;
  organizationName: string;
  isActive: boolean;
}

export interface ExtraOrganiationAccess {
  organization: number;
  role: UserRole;
}

export interface TableColumnMetrics {
  marketingPerformance?: { metrics: string[]; pageSize: number };
  searchTermReport?: { metrics: string[]; pageSize: number };
  acquisition?: { metrics: string[]; pageSize: number };
  pages?: { metrics: string[]; pageSize: number };
  ecommerce?: { metrics: string[]; pageSize: number };
  conversions?: {
    metrics: { account: number; conversions: string[] }[];
    pageSize: number;
  };
}

export interface Profile extends User {
  email: string;
  enabledEmailNotifications: boolean;
  canOnboard: boolean;
  selectedOrganization: number | null;
  extraOrganizationAccesses: ExtraOrganiationAccess[];
  columnPreference?: TableColumnMetrics;
}

export const GET_PROFILE_URL = "/auth/users/me/";

function getProfile(): Promise<Profile> {
  return api.get(GET_PROFILE_URL).then((response) => {
    const user = response.data;
    const external = !isInternalEmail(user.email);
    setUser({
      id: String(user.id),
      external,
      organization: user.selectedOrganization ?? user.organization,
    });
    setTag("is_external_user", external);
    setTag("organization.id", user.selectedOrganization ?? user.organization);
    const cookies = new Cookies();
    cookies.set("is-external-user", external, { path: "/" });
    cookies.set(
      "organizationId",
      user.selectedOrganization ?? user.organization,
      { path: "/" },
    );
    cookies.set("userId", user.id, { path: "/" });
    return user;
  });
}

export function useProfileQuery() {
  const authStore = useAuthStore();
  return reactive(
    useQuery({
      queryKey: ["profile"],
      queryFn: getProfile,
      enabled: toRef(authStore, "isAuthed"),
    }),
  );
}

export const LIST_USERS_URL = "/auth/users/";

function listUsers(): Promise<User[]> {
  return api.get(LIST_USERS_URL).then((response) => response.data);
}

export function useUsersQuery() {
  return reactive(useQuery({ queryKey: ["users"], queryFn: listUsers }));
}

export type UpdateUserAction = "promote" | "demote" | "activate" | "deactivate";

function updateUser({
  id,
  action,
}: {
  id: number;
  action: UpdateUserAction;
}): Promise<User> {
  return api
    .post(`/auth/users/${id}/${action}/`)
    .then((response) => response.data);
}

export function useUpdateUserMutation() {
  const queryClient = useQueryClient();
  const gtmUsers = useManageUsersTracking();
  return reactive(
    useMutation({
      mutationFn: updateUser,
      onError: (_error, vars) => {
        if (vars.action === "promote") gtmUsers.savePromote("Error");
        if (vars.action === "demote") gtmUsers.saveDemote("Error");
        if (vars.action === "activate") gtmUsers.saveActivate("Error");
        if (vars.action === "deactivate") gtmUsers.saveDeactivate("Error");
      },
      onSuccess: (newUser, vars) => {
        if (vars.action === "promote") gtmUsers.savePromote("Success");
        if (vars.action === "demote") gtmUsers.saveDemote("Success");
        if (vars.action === "activate") gtmUsers.saveActivate("Success");
        if (vars.action === "deactivate") gtmUsers.saveDeactivate("Success");
        queryClient.setQueryData<User[]>(
          ["users"],
          (users) =>
            users?.map((user) => (user.id === newUser.id ? newUser : user)),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
      },
    }),
  );
}

function createUser(payload: {
  name: string;
  password: string;
  invite: number;
  token: string;
}): Promise<User> {
  return api.post("/auth/users/", payload).then((response) => response.data);
}

export function useCreateUserMutation() {
  return reactive(
    useMutation({
      mutationFn: createUser,
    }),
  );
}

function joinOrganization(payload: {
  invite: number;
  token: string;
}): Promise<void> {
  return api
    .post("/auth/join_organization/", payload)
    .then((response) => response.data);
}

export function useJoinOrganizationMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: joinOrganization,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["profile"] });
        queryClient.invalidateQueries({ queryKey: ["organizations"] });
      },
    }),
  );
}

function updateProfile(payload: {
  name?: string;
  enabledEmailNotifications?: boolean;
  selectedOrganization?: number | null;
  columnPreference?: TableColumnMetrics;
}): Promise<Profile> {
  return api
    .patch(`/auth/users/me/`, payload)
    .then((response) => response.data);
}

export function useUpdateProfileMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: updateProfile,
      onSuccess: (profile) => {
        queryClient.setQueryData<User>(["profile"], profile);
        queryClient.setQueryData<User[]>(
          ["users"],
          (users) =>
            users?.map((user) => (user.id === profile.id ? profile : user)),
        );
      },
    }),
  );
}

export function useUpdateSelectedOrganization(): (
  organizationId: number | null,
) => Promise<void> {
  const self = useProfileQuery();
  const updateProfileMutation = useUpdateProfileMutation();
  const queryClient = useQueryClient();
  return function updateSelectedOrganization(
    organizationId: number | null,
  ): Promise<void> {
    if (organizationId === self.data?.organization) {
      organizationId = null;
    }
    if (organizationId !== self.data?.selectedOrganization) {
      return updateProfileMutation
        .mutateAsync({
          selectedOrganization: organizationId,
        })
        .then(() => {
          queryClient.resetQueries();
        });
    } else {
      return Promise.resolve();
    }
  };
}

export function useIsManager(): ComputedRef<boolean> {
  const profile = useProfileQuery();
  return computed(() => profile.data?.role === UserRole.MANAGER);
}

export function useIsSelf(user: Ref<DeepReadonly<User>>): ComputedRef<boolean> {
  const profile = useProfileQuery();
  return computed(() => profile.data?.id === user.value.id);
}

export function isInternalEmail(email: string): boolean {
  return email.endsWith("@lighthouse8.com") || email.endsWith("@fyr.ai");
}

export function useIsInternalUser(): ComputedRef<boolean> {
  const profile = useProfileQuery();
  return computed(() =>
    profile.data ? isInternalEmail(profile.data.email) : false,
  );
}

export function isRestrictedAccessibleUser(email: string): boolean {
  return isInternalEmail(email) || email.endsWith("@try.no");
}

export function useIsRestrictedAccessibleUser(): ComputedRef<boolean> {
  const profile = useProfileQuery();
  return computed(() =>
    profile.data ? isRestrictedAccessibleUser(profile.data.email) : false,
  );
}

export function getUserById(
  users: User[] | undefined,
  id: number,
): User | undefined {
  return users?.find((user) => user.id === id);
}

export function useUserById(id: Ref<number>): ComputedRef<User | undefined> {
  const users = useUsersQuery();

  return computed(() => {
    if (id.value < 1) return SystemUser;
    return getUserById(users.data, id.value);
  });
}
