import { MetricKey } from "@/services/metrics";

export interface HighlightedWord {
  word: string;
  color?: string;
}

export enum TableSortDirection {
  ASC = "asc",
  DESC = "desc",
}

/* WORD HIGHLIGHTING: This is a copy of
   https://github.com/bvaughn/highlight-words-core/blob/eb170f8a78c7926b613e72733267f3243696113c/src/utils.js#L172
   ...with some typecasting, eslint-ignore, and morphing the regex lookup
*/
export interface Chunk {
  highlight: boolean;
  start: number;
  end: number;
}

function escapeRegExpFn(string: string): string {
  // TODO:
  // eslint-disable-next-line no-useless-escape
  return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

/**
 * Creates an array of chunk objects representing both higlightable and non highlightable pieces of text that match each search word.
 * @return Array of "chunks" (where a Chunk is { start:number, end:number, highlight:boolean })
 */
export function findAll({
  autoEscape,
  caseSensitive = false,
  findChunks = defaultFindChunks,
  sanitize,
  searchWords,
  textToHighlight,
}: {
  autoEscape?: boolean;
  caseSensitive?: boolean;
  findChunks?: typeof defaultFindChunks;
  sanitize?: typeof defaultSanitize;
  searchWords: string[];
  textToHighlight: string;
}): Chunk[] {
  return fillInChunks({
    chunksToHighlight: combineChunks({
      chunks: findChunks({
        autoEscape,
        caseSensitive,
        sanitize,
        searchWords,
        textToHighlight,
      }),
    }),
    totalLength: textToHighlight ? textToHighlight.length : 0,
  });
}

/**
 * Takes an array of {start:number, end:number} objects and combines chunks that overlap into single chunks.
 * @return {start:number, end:number}[]
 */
export function combineChunks({ chunks }: { chunks: Chunk[] }): Chunk[] {
  const ch = chunks
    .sort((first, second) => first.start - second.start)
    .reduce((processedChunks: Chunk[], nextChunk) => {
      // First chunk just goes straight in the array...
      if (processedChunks.length === 0) {
        return [nextChunk];
      } else {
        // ... subsequent chunks get checked to see if they overlap...
        const prevChunk = processedChunks.pop() as Chunk;
        if (nextChunk.start <= prevChunk.end) {
          // It may be the case that prevChunk completely surrounds nextChunk, so take the
          // largest of the end indeces.
          const endIndex = Math.max(prevChunk.end, nextChunk.end);
          processedChunks.push({
            highlight: false,
            start: prevChunk.start,
            end: endIndex,
          });
        } else {
          processedChunks.push(prevChunk, nextChunk);
        }
        return processedChunks;
      }
    }, []);

  return ch;
}

/**
 * Examine text for any matches.
 * If we find matches, add them to the returned array as a "chunk" object ({start:number, end:number}).
 * @return {start:number, end:number}[]
 */
export function defaultFindChunks({
  autoEscape,
  caseSensitive,
  sanitize = defaultSanitize,
  searchWords,
  textToHighlight,
}: {
  autoEscape?: boolean;
  caseSensitive?: boolean;
  sanitize?: typeof defaultSanitize;
  searchWords: string[];
  textToHighlight: string;
}): Chunk[] {
  textToHighlight = sanitize(textToHighlight);

  return searchWords
    .filter((searchWord) => searchWord) // Remove empty words
    .reduce((chunks: Chunk[], searchWord) => {
      searchWord = sanitize(searchWord);

      if (autoEscape) {
        searchWord = escapeRegExpFn(searchWord);
      }

      const regex = new RegExp(
        `(?:(?<![\\w\\p{L}])(?=[\\w\\p{L}])|(?<=[\\w\\p{L}])(?![\\w\\p{L}]))${searchWord}(?:(?<![\\w\\p{L}])(?=[\\w\\p{L}])|(?<=[\\w\\p{L}])(?![\\w\\p{L}]))`,
        caseSensitive ? "gu" : "gui",
      );

      let match;
      while ((match = regex.exec(textToHighlight))) {
        const start = match.index;
        const end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: false, start, end });
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }

      return chunks;
    }, []);
}
// Allow the findChunks to be overridden in findAll,
// but for backwards compatibility we export as the old name
export { defaultFindChunks as findChunks };

/**
 * Given a set of chunks to highlight, create an additional set of chunks
 * to represent the bits of text between the highlighted text.
 * @param chunksToHighlight {start:number, end:number}[]
 * @param totalLength number
 * @return {start:number, end:number, highlight:boolean}[]
 */
export function fillInChunks({
  chunksToHighlight,
  totalLength,
}: {
  chunksToHighlight: Chunk[];
  totalLength: number;
}): Chunk[] {
  const allChunks: Chunk[] = [];
  function append(start: number, end: number, highlight: boolean): void {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        highlight,
      });
    }
  }

  if (chunksToHighlight.length === 0) {
    append(0, totalLength, false);
  } else {
    let lastIndex = 0;
    chunksToHighlight.forEach((chunk) => {
      append(lastIndex, chunk.start, false);
      append(chunk.start, chunk.end, true);
      lastIndex = chunk.end;
    });
    append(lastIndex, totalLength, false);
  }
  return allChunks;
}

function defaultSanitize(string: string): string {
  return string;
}

export interface StatCard {
  metric: MetricKey;
  value: number | null | undefined;
  previousValue: number | null | undefined;
  tooltip?: string | undefined;
}
