import api from "@/services/api";
import {
  ChannelDataFilter,
  TimeUnit,
  ResourcesDatafilter,
  TagsDataFilter,
  TemporalDataFilter,
  SelectionTemporalDataFilter,
  TemporalPeriodsType,
  DateRangeSelection,
} from "@/services/data_filters";
import { ResourceChannel } from "@/services/resources";
import { computed, ComputedRef, reactive, ref, Ref } from "vue";
import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/vue-query";
import {
  useDataFilters,
  useDetachedSelectionTemporalFilter,
  useShareOfSearchCategoriesFilter,
  useShareOfSearchCompetitorsFilter,
  useShareOfSearchCountriesFilter,
  useShareOfSearchKeywordsFilter,
} from "@/store/data_filters";
import { OpenGraphData, PostAsset } from "@/services/social_media";
import { useOrganizationQuery } from "@/services/organization";
import { HeatmapWeekdayData } from "@/utils/heatmap";
import { useManageCompetitorsTracking } from "@/services/gtm";
import {
  TimeSeriesDataset,
  ResponseTimeSeriesDatasets,
} from "@/utils/datasets";
import { QueryFunctionContext } from "@/utils/typing";
import * as lodash from "lodash-es";
import { MetricFilterSet, MetricKeys } from "@/services/metrics";
import { filterCriteriaFormatter } from "@/utils/filterCriteriaFormatter";
import { PaginatedResponse, useResetPage } from "./utils";
import {
  usePublicDashboardQueryFilters,
  usePublicDashboardWidgetQueryFilters,
} from "./public_dashboard";
import { DateTime } from "luxon";
const gtm = useManageCompetitorsTracking();

export interface Competitor {
  pk: number;
  name: string;
  createdAt: string;
  modifiedAt: string;
  manuallyFetchedAt: string | null;
  canFetchManually: boolean;
  user: number;
  facebookPage: string;
  facebookProfileImageUrl: string;
  instagramProfile: string;
  instagramProfileImageUrl: string;
  alternativeNames: string[];
}

export const COMPETITOR_MONITOR_CHANNELS = [
  ResourceChannel.FACEBOOK_ORGANIC,
  ResourceChannel.INSTAGRAM_ORGANIC,
] as const;

export type CompetitorMonitorChannel =
  (typeof COMPETITOR_MONITOR_CHANNELS)[number];

export const COMPETITOR_POSTS_SORT_METRICS = [
  "comments",
  "publishedAt",
  "reactions",
  "shares",
] as const;

export interface CompetitorPost {
  pk: number;
  channel: CompetitorMonitorChannel;
  competitor: number;
  externalId: string;
  publishedAt: string | null;
  text: string;
  assets: PostAsset[];
  postType: string;
  destinationUrl: string;
  permalinkUrl: string;
  likes: number | null;
  comments: number | null;
  reactions: number | null;
  shares: number | null;
  tags: number[];
  openGraphData: OpenGraphData[];
}

export interface CompetitorFollowersByDate extends ResponseTimeSeriesDatasets {
  datasets: Required<
    Pick<TimeSeriesDataset, "label" | "data" | "total" | "period">
  >[];
}
export type TemporalCompetitorFollowersByDate = Record<
  TemporalPeriodsType,
  CompetitorFollowersByDate
>;

export interface CompetitorFollowers {
  competitor: number | null; // null means your own
  followers: number | null;
}

export type CompetitorPostFrequency = HeatmapWeekdayData;

export interface CompetitorMetricsByComparisonData {
  postFrequency: number;
  posts: number;
  followerGrowth: number | null;
}

export interface CompetitorMetricsByComparison {
  yours: CompetitorMetricsByComparisonData;
  theirs: CompetitorMetricsByComparisonData;
}

export interface CompetitorPostTypeMetricsRow<
  Type extends "link" | "image" | "video" | "total",
> {
  type: Type;
  facebookCount: number;
  facebookRatio: number | null;
  instagramCount: number;
  instagramRatio: number | null;
  totalCount: number;
  totalRatio: number | null;
}

export type CompetitorPostTypeMetrics = [
  CompetitorPostTypeMetricsRow<"link">,
  CompetitorPostTypeMetricsRow<"image">,
  CompetitorPostTypeMetricsRow<"video">,
  CompetitorPostTypeMetricsRow<"total">,
];

export type CompetitorPostMetrics = (typeof competitorPostMetrics)[number];

const competitorPostMetrics = lodash.pick(MetricKeys, [
  "comments",
  "likes",
  "shares",
]);

function createCompetitor(payload: {
  name: string;
  facebookPage?: string;
  instagramProfile?: string;
  alternativeNames?: string[];
}): Promise<Competitor> {
  return api
    .post("/competitor_monitoring/competitors/", payload)
    .then((response) => response.data);
}

export function useCreateCompetitorMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: createCompetitor,
      onError: () => gtm.saveCreate("Error"),
      onSuccess: (newCompetitor) => {
        gtm.saveCreate("Success");
        queryClient.setQueryData<Competitor[]>(
          ["competitors"],
          (competitors) => [...(competitors ?? []), newCompetitor],
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["competitors"] });
        queryClient.invalidateQueries({ queryKey: ["competitorPosts"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorFollowersByDate"],
        });
        queryClient.invalidateQueries({ queryKey: ["competitorFollowers"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostFrequency"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorMetricsByComparison"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostTypeMetrics"],
        });
      },
    }),
  );
}

export type UpdateCompetitorPayload = Pick<Competitor, "pk" | "name"> &
  Partial<
    Pick<Competitor, "facebookPage" | "instagramProfile" | "alternativeNames">
  >;

function updateCompetitor(
  competitor: UpdateCompetitorPayload,
): Promise<Competitor> {
  return api
    .put(`/competitor_monitoring/competitors/${competitor.pk}/`, competitor)
    .then((response) => response.data);
}

export function useUpdateCompetitorMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: updateCompetitor,
      onError: () => gtm.saveEdit("Error"),
      onSuccess: (newCompetitor) => {
        gtm.saveEdit("Success");
        queryClient.setQueryData<Competitor[]>(
          ["competitors"],
          (competitors) =>
            competitors?.map((competitor) =>
              competitor.pk === newCompetitor.pk ? newCompetitor : competitor,
            ),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["competitors"] });
        queryClient.invalidateQueries({ queryKey: ["competitorPosts"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorFollowersByDate"],
        });
        queryClient.invalidateQueries({ queryKey: ["competitorFollowers"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostFrequency"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorMetricsByComparison"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostTypeMetrics"],
        });
      },
    }),
  );
}

function listCompetitors(): Promise<Competitor[]> {
  return api
    .get("/competitor_monitoring/competitors/")
    .then((response) => response.data);
}

export function useCompetitorsQuery({
  enabled,
}: { enabled?: Ref<boolean> } = {}) {
  return reactive(
    useQuery({ queryKey: ["competitors"], queryFn: listCompetitors, enabled }),
  );
}

function deleteCompetitor(competitorId: number): Promise<void> {
  return api
    .delete(`/competitor_monitoring/competitors/${competitorId}/`)
    .then((response) => response.data);
}

export function useDeleteCompetitorMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: deleteCompetitor,
      onError: () => gtm.saveDelete("Error"),
      onSuccess: (_, pk) => {
        gtm.saveDelete("Success");
        queryClient.setQueryData<Competitor[]>(
          ["competitors"],
          (competitors) =>
            competitors?.filter((competitor) => competitor.pk !== pk),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["competitors"] });
        queryClient.invalidateQueries({ queryKey: ["competitorPosts"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorFollowersByDate"],
        });
        queryClient.invalidateQueries({ queryKey: ["competitorFollowers"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostFrequency"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorMetricsByComparison"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostTypeMetrics"],
        });
        queryClient.invalidateQueries({ queryKey: ["competitorPost"] });
      },
    }),
  );
}

function listCompetitorPosts({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    filterCriteria?: MetricFilterSet[];
  } & SelectionTemporalDataFilter &
    ChannelDataFilter &
    TagsDataFilter
>): Promise<CompetitorPost[]> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);

  return api

    .get("/competitor_monitoring/competitor_posts/", {
      params: { ...params, ...filterCriteria },
    })

    .then((response) => response.data);
}

export function useCompetitorPostsQuery({
  filterCriteria,
}: {
  filterCriteria?: Ref<MetricFilterSet[]>;
} = {}) {
  const queryKey = [
    "competitorPosts",
    {
      ...useDataFilters([
        "channel",
        "selectionTemporalFilter",
        "includedTags",
        "excludedTags",
      ]),
      filterCriteria,
    },
  ] as const;

  return reactive(useQuery({ queryKey, queryFn: listCompetitorPosts }));
}

function getCompetitorPost({
  queryKey: [, { pk }],
}: QueryFunctionContext<{ pk: number }>): Promise<CompetitorPost> {
  return api
    .get(`/competitor_monitoring/competitor_posts/${pk}/`)
    .then((response) => response.data);
}

export function useCompetitorPostQuery({ pk }: { pk: Ref<number> }) {
  const queryClient = useQueryClient();
  const queryKey = ["competitorPost", { pk }] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: getCompetitorPost,
      initialData: () => {
        return queryClient
          .getQueriesData<CompetitorPost[] | undefined>({
            queryKey: ["competitorPosts"],
          })
          .flatMap(([_, posts]) => posts ?? [])
          .find((post) => post.pk === pk.value);
      },
    }),
  );
}

function listCompetitorFollowersByDate({
  queryKey: [, params],
}: QueryFunctionContext<
  TemporalDataFilter &
    ChannelDataFilter &
    ResourcesDatafilter & {
      cumulative: boolean;
      timeUnit: TimeUnit;
    }
>): Promise<TemporalCompetitorFollowersByDate> {
  return api
    .get("/competitor_monitoring/followers_by_date/", { params })
    .then((response) => response.data);
}

export function useCompetitorFollowersByDateQuery({
  cumulative,
  timeUnit = ref(TimeUnit.DAY),
}: {
  cumulative: Ref<boolean>;
  timeUnit: Ref<TimeUnit>;
}) {
  const queryKey = [
    "competitorFollowersByDate",
    {
      ...useDataFilters(["channel", "includedResources", "temporalFilter"]),
      cumulative,
      timeUnit,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorFollowersByDate,
      placeholderData: keepPreviousData,
    }),
  );
}

function listCompetitorFollowers({
  queryKey: [, params],
}: QueryFunctionContext<ChannelDataFilter & ResourcesDatafilter>): Promise<
  CompetitorFollowers[]
> {
  return api
    .get("/competitor_monitoring/followers/", { params })
    .then((response) => response.data);
}

export function useCompetitorFollowersQuery() {
  const queryKey = [
    "competitorFollowers",
    useDataFilters(["channel", "includedResources"]),
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorFollowers,
      placeholderData: keepPreviousData,
    }),
  );
}

export function listCompetitorPostFrequency({
  queryKey: [, { competitorId, ...params }],
}: QueryFunctionContext<
  {
    competitorId: number;
  } & SelectionTemporalDataFilter &
    ChannelDataFilter &
    TagsDataFilter
>): Promise<CompetitorPostFrequency> {
  return api
    .get(`/competitor_monitoring/competitors/${competitorId}/post_frequency/`, {
      params: { ...params, timeZone: DateTime.local().zoneName },
    })
    .then((response) => response.data);
}

export function useCompetitorPostFrequencyQuery({
  competitorId,
}: {
  competitorId: Ref<number | null>;
}) {
  const queryKey = [
    "competitorPostFrequency",
    {
      competitorId: competitorId as Ref<number>,
      ...useDataFilters([
        "channel",
        "selectionTemporalFilter",
        "includedTags",
        "excludedTags",
      ]),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorPostFrequency,
      placeholderData: keepPreviousData,
      enabled: computed(() => competitorId.value !== null),
    }),
  );
}

export function listCompetitorMetricsByComparison({
  queryKey: [, { competitorId, ...params }],
}: QueryFunctionContext<
  { competitorId: number } & TemporalDataFilter &
    ChannelDataFilter &
    TagsDataFilter
>): Promise<CompetitorMetricsByComparison> {
  return api
    .get(
      `/competitor_monitoring/competitors/${competitorId}/metrics_by_comparison/`,
      { params },
    )
    .then((response) => response.data);
}

export function useCompetitorMetricsByComparisonQuery({
  competitorId,
}: {
  competitorId: Ref<number>;
}) {
  const queryKey = [
    "competitorMetricsByComparison",
    {
      competitorId,
      ...useDataFilters([
        "channel",
        "temporalFilter",
        "includedTags",
        "excludedTags",
        "includedResources",
      ]),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorMetricsByComparison,
      placeholderData: keepPreviousData,
    }),
  );
}

export function useCompetitorPostTypeMetricsQuery({
  competitorId,
}: {
  competitorId: Ref<number>;
}) {
  const queryKey = [
    "competitorPostTypeMetrics",
    {
      competitorId,
      ...useDataFilters([
        "channel",
        "temporalFilter",
        "includedTags",
        "excludedTags",
      ]),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorMetricsByComparison,
      placeholderData: keepPreviousData,
    }),
  );
}

export function useCanAddCompetitor(): ComputedRef<boolean> {
  const organization = useOrganizationQuery();
  const competitors = useCompetitorsQuery();
  return computed(() => {
    if (!organization.data || !competitors.data) {
      return false;
    }
    return (
      competitors.data.length <
      organization.data.subscriptionLimits.competitorLimit
    );
  });
}

export function competitorById(
  id: number,
  competitors: ReturnType<typeof useCompetitorsQuery>,
): Competitor | undefined {
  return competitors.data?.find((competitor) => competitor.pk === id);
}

export interface ShareOfSearchByCompetitor {
  // null for yourself.
  pk: number | null;
  name: string;
  months: { month: string; date: string; searches: number | null }[];
}

function listShareOfSearchByCompetitor({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    competitors: (number | null)[] | undefined;
    categories: number[] | undefined;
    countries: number[] | undefined;
    keywords: number[] | undefined;
  } & SelectionTemporalDataFilter
>): Promise<
  PaginatedResponse<ShareOfSearchByCompetitor> & {
    months: { month: string; date: string }[];
  }
> {
  return api
    .get("competitor_monitoring/share_of_search_by_competitor/", { params })
    .then((response) => response.data);
}

export function useShareOfSearchByCompetitor<
  T = PaginatedResponse<ShareOfSearchByCompetitor> & {
    months: { month: string; date: string }[];
  },
>({
  enabled,
  shareOfSearchCategories,
  shareOfSearchKeywords,
  shareOfSearchCountries,
  shareOfSearchCompetitors,
  temporalFilter,
  select,
}: {
  enabled?: Ref<boolean>;
  shareOfSearchCategories?: Ref<number[]>;
  shareOfSearchKeywords?: Ref<number[]>;
  shareOfSearchCountries?: Ref<number[]>;
  shareOfSearchCompetitors?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  select?: (
    sosResponse: PaginatedResponse<ShareOfSearchByCompetitor> & {
      months: { month: string; date: string }[];
    },
  ) => T;
} = {}) {
  const queryKey = [
    "shareOfSearchByCompetitor",
    {
      ...useDetachedSelectionTemporalFilter(temporalFilter),
      ...useShareOfSearchCategoriesFilter(shareOfSearchCategories),
      ...useShareOfSearchKeywordsFilter(shareOfSearchKeywords),
      ...useShareOfSearchCountriesFilter(shareOfSearchCountries),
      ...useShareOfSearchCompetitorsFilter(shareOfSearchCompetitors),
      ...usePublicDashboardWidgetQueryFilters(),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchByCompetitor,
      enabled,
      select,
    }),
  );
}

function useShareOfSearchByCompetitorLineChartQuery(
  options: Parameters<typeof useShareOfSearchByCompetitor>[0],
) {
  return useShareOfSearchByCompetitor({
    ...options,
    select: (
      sosResponse: PaginatedResponse<ShareOfSearchByCompetitor> & {
        months: { month: string; date: string }[];
      },
    ) => {
      return {
        labels: sosResponse.months?.map((month) => month.date),
        datasets: sosResponse?.results?.map(
          (competitor: ShareOfSearchByCompetitor): TimeSeriesDataset => {
            return {
              label: competitor.name,
              data: competitor.months.map((m) => m.searches),
              period: "selection",
            };
          },
        ),
      };
    },
  });
}

export const useShareOfSearchByCompetitorLineChartQueryWithMetrics = {
  query: useShareOfSearchByCompetitorLineChartQuery,
  metrics: [],
};

function useShareOfSearchByCompetitorTableQuery(
  options: Parameters<typeof useShareOfSearchByCompetitor>[0],
) {
  return useShareOfSearchByCompetitor({
    ...options,
    select: undefined,
  });
}

export const useShareOfSearchByCompetitorTableQueryWithMetrics = {
  query: useShareOfSearchByCompetitorTableQuery,
  metrics: [],
};

export interface ShareOfSearchByCategory {
  pk: number;
  name: string;
  competitors: {
    // null for yourself.
    pk: number | null;
    name: string;
    months: { month: string; date: string; searches: number | null }[];
  }[];
}

function listShareOfSearchByCategory({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    competitors: (number | null)[] | undefined;
    categories: number[] | undefined;
    countries: number[] | undefined;
    keywords: number[] | undefined;
    page: number;
    pageSize: number;
  } & SelectionTemporalDataFilter
>): Promise<
  PaginatedResponse<ShareOfSearchByCategory> & {
    months: { month: string; date: string }[];
  }
> {
  return api
    .get("competitor_monitoring/share_of_search_by_category/", { params })
    .then((response) => response.data);
}

export function useShareOfSearchByCategory({
  page,
  pageSize,
  enabled,
  shareOfSearchCategories,
  shareOfSearchKeywords,
  shareOfSearchCountries,
  shareOfSearchCompetitors,
}: {
  page: Ref<number>;
  pageSize: Ref<number>;
  enabled?: Ref<boolean>;
  shareOfSearchCategories?: Ref<number[]>;
  shareOfSearchKeywords?: Ref<number[]>;
  shareOfSearchCountries?: Ref<number[]>;
  shareOfSearchCompetitors?: Ref<number[]>;
}) {
  const queryKey = [
    "shareOfSearchByCategory",
    {
      ...useDataFilters(["selectionTemporalFilter"]),
      ...useShareOfSearchCategoriesFilter(shareOfSearchCategories),
      ...useShareOfSearchKeywordsFilter(shareOfSearchKeywords),
      ...useShareOfSearchCountriesFilter(shareOfSearchCountries),
      ...useShareOfSearchCompetitorsFilter(shareOfSearchCompetitors),
      ...usePublicDashboardWidgetQueryFilters(),
      page,
      pageSize,
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchByCategory,
      placeholderData: keepPreviousData,
      enabled,
    }),
  );
}

export interface ShareOfSearchByKeyword {
  pk: number;
  text: string;
  categories: string[];
  competitors: {
    // null for yourself.
    pk: number | null;
    name: string;
    months: { month: string; date: string; searches: number | null }[];
  }[];
}

function listShareOfSearchByKeyword({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    competitors: (number | null)[] | undefined;
    categories: number[] | undefined;
    countries: number[] | undefined;
    keywords: number[] | undefined;
    search: string | undefined;
    page: number;
    pageSize: number;
  } & SelectionTemporalDataFilter
>): Promise<
  PaginatedResponse<ShareOfSearchByKeyword> & {
    months: { month: string; date: string }[];
  }
> {
  return api
    .get("competitor_monitoring/share_of_search_by_keyword/", { params })
    .then((response) => response.data);
}

export function useShareOfSearchByKeyword({
  search,
  page,
  pageSize,
  enabled,
  shareOfSearchCompetitors,
  shareOfSearchCategories,
  shareOfSearchCountries,
  shareOfSearchKeywords,
}: {
  search?: Ref<string>;
  page: Ref<number>;
  pageSize: Ref<number>;
  enabled?: Ref<boolean>;
  shareOfSearchCompetitors?: Ref<number[]>;
  shareOfSearchCategories?: Ref<number[]>;
  shareOfSearchCountries?: Ref<number[]>;
  shareOfSearchKeywords?: Ref<number[]>;
}) {
  const queryKey = [
    "shareOfSearchByKeyword",
    {
      ...useDataFilters(["selectionTemporalFilter"]),
      ...useShareOfSearchCategoriesFilter(shareOfSearchCategories),
      ...useShareOfSearchKeywordsFilter(shareOfSearchKeywords),
      ...useShareOfSearchCountriesFilter(shareOfSearchCountries),
      ...useShareOfSearchCompetitorsFilter(shareOfSearchCompetitors),
      ...usePublicDashboardWidgetQueryFilters(),
      search,
      page,
      pageSize,
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchByKeyword,
      placeholderData: keepPreviousData,
      enabled,
    }),
  );
}

export interface ShareOfSearchCategory {
  pk: number;
  name: string;
}

function listShareOfSearchCategories(): Promise<ShareOfSearchCategory[]> {
  return api
    .get("competitor_monitoring/share_of_search_categories/")
    .then((response) => response.data);
}

export function useShareOfSearchCategories({
  enabled = ref(true),
}: { enabled?: Ref<boolean> } = {}) {
  const queryKey = [
    "shareOfSearchCategories",
    { ...usePublicDashboardQueryFilters() },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchCategories,
      enabled,
    }),
  );
}

export interface ShareOfSearchConfigKeyword {
  text: string;
  categories: string[];
}

export interface ShareOfSearchKeyword extends ShareOfSearchConfigKeyword {
  pk: number;
}

function listShareOfSearchKeywords(): Promise<ShareOfSearchKeyword[]> {
  return api
    .get("competitor_monitoring/share_of_search_keywords/")
    .then((response) => response.data);
}

export function useShareOfSearchKeywords({
  enabled = ref(true),
}: { enabled?: Ref<boolean> } = {}) {
  const queryKey = [
    "shareOfSearchKeywords",
    { ...usePublicDashboardQueryFilters() },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchKeywords,
      enabled,
    }),
  );
}

export interface ShareOfSearchCountry {
  pk: number;
  name: string;
}

function listShareOfSearchCountries(): Promise<ShareOfSearchCountry[]> {
  return api
    .get("competitor_monitoring/share_of_search_countries/")
    .then((response) => response.data);
}

export function useShareOfSearchCountries({
  enabled = ref(true),
}: {
  enabled?: Ref<boolean>;
} = {}) {
  const queryKey = [
    "shareOfSearchCountries",
    { ...usePublicDashboardQueryFilters() },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchCountries,
      enabled,
    }),
  );
}

export interface ShareOfSearchConfiguration {
  competitors: {
    pk: number | null;
    alternativeNames: string[];
  }[];
  keywords: { text: string; categories: string[] }[];
  countries: string[];
}

function configureShareOfSearch(
  configuration: ShareOfSearchConfiguration,
): Promise<void> {
  return api.post(
    "competitor_monitoring/configure_share_of_search/",
    configuration,
  );
}

export function useConfigureShareOfSearchMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: configureShareOfSearch,
      onSuccess: (): void => {
        queryClient.invalidateQueries({
          queryKey: ["shareOfSearchCategories"],
        });
        queryClient.invalidateQueries({ queryKey: ["shareOfSearchKeywords"] });
        queryClient.invalidateQueries({ queryKey: ["shareOfSearchCountries"] });
        queryClient.invalidateQueries({ queryKey: ["competitors"] });
        queryClient.invalidateQueries({ queryKey: ["organization"] });
        queryClient.invalidateQueries({
          queryKey: ["shareOfSearchByCategory"],
        });
        queryClient.invalidateQueries({ queryKey: ["shareOfSearchByKeyword"] });
        queryClient.invalidateQueries({
          queryKey: ["shareOfSearchByCompetitor"],
        });
      },
    }),
  );
}
