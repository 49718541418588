import { MetricFilterSet } from "@/services/metrics";
import * as lodash from "lodash-es";
export function filterCriteriaFormatter(
  filterCriteria?: MetricFilterSet[],
): Record<string, number> {
  const formattedCriteria = filterCriteria
    ? Object.fromEntries(
        filterCriteria.map((filterCriterion) => [
          `${filterCriterion.relationship}_${lodash.snakeCase(
            filterCriterion.metric,
          )}`,
          filterCriterion.value,
        ]),
      )
    : {};
  return formattedCriteria;
}
