import api from "@/services/api";
import { SelectionTemporalDataFilter, TimeUnit } from "@/services/data_filters";
import { RecommendationType } from "@/services/recommendations/recommendations";
import { RecommendationTask } from "@/services/recommendations/tasks";
import { useDataFilters } from "@/store/data_filters";
import { computed, reactive, ComputedRef, toRef } from "vue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { DateTime } from "luxon";
import { useAnnotationsStore } from "@/store/annotations";
import { QueryFunctionContext } from "@/utils/typing";
import { useAuthStore } from "@/store/auth";

export interface BaseAnnotation {
  start: string;
  end: string | null;
}

export interface BaseStoredAnnotation extends BaseAnnotation {
  pk: number;
  organization: number;
  user: number;
  duration: EventDuration;
}

export interface UserAnnotation extends BaseStoredAnnotation {
  description: string;
  channel: AnnotationChannel.USER;
  type: UserEventType;
  feedItem?: number | null;
}

export interface UserAnnotationPayload extends BaseAnnotation {
  description: string;
  type: UserEventType;
  feedItem?: number | null;
}

// Annotation types

export enum AutomatedAnnotationType {
  CAMPAIGN_STATUS_CHANGED = "Campaign status changed",
  CHANNEL_TRAFFIC_INCREASE = "Channel traffic increase",
  ORGANIC_POST_PUBLISHED = "Organic post published",
}

export enum EventDuration {
  SINGLE_DAY = "Single-day event",
  MULTI_DAY = "Multi-day event",
  CONTINUOUS = "Ongoing event",
}

export enum AnnotationChannel {
  // The "root" category of an Annotation - the first 5 are created by K8
  FACEBOOK_ADS = "Facebook Ads",
  FACEBOOK_ORGANIC = "Facebook Organic",
  INSTAGRAM_ORGANIC = "Instagram Organic",
  GOOGLE_ADS = "Google Ads",
  GOOGLE_ANALYTICS = "Google Analytics",
  GOOGLE_ANALYTICS_4 = "Google Analytics 4",
  GOOGLE_BIG_QUERY = "Google BigQuery",
  GOOGLE_BIG_QUERY_ANALYTICS = "Google BigQuery Analytics",
  YOUTUBE = "YouTube",
  MERCHANT_CENTER = "Merchant Center",
  LINKED_IN_ADS = "LinkedIn Ads",
  LINKED_IN_ORGANIC = "LinkedIn Organic",
  SNAPCHAT_ADS = "Snapchat Ads",
  XANDR = "Xandr",
  BING_ADS = "Bing Ads",
  TIK_TOK_ADS = "TikTok Ads",
  TIK_TOK_ORGANIC = "TikTok Organic",
  USER = "User added event",
}

export enum UserEventType {
  COMPETITOR = "Competitor",
  GLOBAL_EVENT = "Global event",
  LOCAL_EVENT = "Local event",
  NEWSLETTER = "Newsletter",
  NEWSPAPER_MENTION = "Newspaper mention",
  PHYSICAL_PROMOTION = "Physical promotion",
  RADIO_MENTION = "Radio mention",
  SOCIAL_MEDIA_COMPETITION = "Social media competition",
  DISPLAY_MARKETING = "Display marketing",
  TV_MENTION = "TV mention",
  WEBSITE_UPDATE = "Website update",
  CAMPAIGN_CHANGES = "Campaign changes",
  OTHER = "Other event",
}

// Automated annotation details

export interface CampaignMetricChangedAnnotationDetails {
  campaignId: string;
  campaignName: string;
  change: number;
  direction: "increase" | "decrease";
  metric: string;
}

export interface CampaignStatusChangedAnnotationDetails {
  campaignId: string;
  campaignName: string;
  isActive: boolean;
}

export interface CampaignStatusChangedAnnotation extends BaseStoredAnnotation {
  type: AutomatedAnnotationType.CAMPAIGN_STATUS_CHANGED;
  details: CampaignStatusChangedAnnotationDetails;
  channel:
    | AnnotationChannel.FACEBOOK_ADS
    | AnnotationChannel.GOOGLE_ADS
    | AnnotationChannel.LINKED_IN_ADS
    | AnnotationChannel.SNAPCHAT_ADS
    | AnnotationChannel.XANDR
    | AnnotationChannel.BING_ADS
    | AnnotationChannel.TIK_TOK_ADS;
}

export interface RecommendationTaskVerifiedAnnotationDetails {
  recommendationTaskId: number;
  recommendationTaskRecommendationName: RecommendationType;
  recommendationTaskExternalId: string;
  recommendationTaskDetails: RecommendationTask["details"];
  userId: number | null;
}

export interface ChannelTrafficIncreaseAnnotationDetails {
  channel: string;
  users: number;
  previousUsers: number;
}

export interface ChannelTrafficIncreaseAnnotation extends BaseStoredAnnotation {
  type: AutomatedAnnotationType.CHANNEL_TRAFFIC_INCREASE;
  details: ChannelTrafficIncreaseAnnotationDetails;
  channel:
    | AnnotationChannel.GOOGLE_ANALYTICS
    | AnnotationChannel.GOOGLE_ANALYTICS_4;
}

export interface OrganicPostPublishedAnnotation extends BaseStoredAnnotation {
  type: AutomatedAnnotationType.ORGANIC_POST_PUBLISHED;
  details: Record<string, never>;
  channel:
    | AnnotationChannel.FACEBOOK_ORGANIC
    | AnnotationChannel.INSTAGRAM_ORGANIC
    | AnnotationChannel.LINKED_IN_ORGANIC
    | AnnotationChannel.YOUTUBE
    | AnnotationChannel.TIK_TOK_ORGANIC;
  post: number | null;
}

export type AutomatedAnnotation =
  | CampaignStatusChangedAnnotation
  | ChannelTrafficIncreaseAnnotation
  | OrganicPostPublishedAnnotation;

export type Annotation = AutomatedAnnotation | UserAnnotation;

// Api consumers

function createAnnotation(
  annotation: UserAnnotationPayload,
): Promise<UserAnnotation> {
  return api
    .post("/annotations/user_annotations/", annotation)
    .then((response) => response.data);
}

export function useCreateAnnotationMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: createAnnotation,
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["annotations", "annotationCountsPerDate"],
        });
      },
    }),
  );
}

export const LIST_USER_ANNOTATIONS_URL = "/annotations/user_annotations/";

export function listUserAnnotations(
  params: SelectionTemporalDataFilter,
): Promise<UserAnnotation[]> {
  const eventTypes = new Set(Object.values(UserEventType));
  return api
    .get<UserAnnotation[]>(LIST_USER_ANNOTATIONS_URL, { params })
    .then((response) => {
      return response.data.filter((annotation) =>
        eventTypes.has(annotation.type),
      );
    });
}

export const LIST_AUTOMATED_ANNOTATIONS_URL =
  "/annotations/automated_annotations/";

export function listAutomatedAnnotations(
  params: SelectionTemporalDataFilter,
): Promise<AutomatedAnnotation[]> {
  const annotationTypes = new Set(Object.values(AutomatedAnnotationType));
  const annotationChannels = new Set(Object.values(AnnotationChannel));
  return api
    .get<AutomatedAnnotation[]>(LIST_AUTOMATED_ANNOTATIONS_URL, {
      params,
    })
    .then((response) => {
      return response.data.filter(
        (annotation) =>
          annotationTypes.has(annotation.type) &&
          annotationChannels.has(annotation.channel),
      );
    });
}

function listAnnotations({
  queryKey: [, params],
}: QueryFunctionContext<SelectionTemporalDataFilter>): Promise<Annotation[]> {
  return Promise.all([
    listUserAnnotations(params),
    listAutomatedAnnotations(params),
  ]).then(([userAnnotations, automatedAnnotations]) => [
    ...userAnnotations,
    ...automatedAnnotations,
  ]);
}

export function useAnnotationsQuery() {
  const temporalFilter = useDataFilters(["selectionTemporalFilter"]);
  const dataFilters = {
    ...temporalFilter,
    // We ensure that if the user selects a date range that ends yesterday or later,
    // we also include today's and future annotations.
    selection_to: computed(() =>
      DateTime.fromISO(temporalFilter.selection_to.value) >=
      DateTime.local().minus({ days: 1 }).startOf("day")
        ? null
        : temporalFilter.selection_to.value,
    ),
  };
  const queryKey = ["annotations", dataFilters] as const;
  const authStore = useAuthStore();
  return reactive(
    useQuery({
      queryKey,
      queryFn: listAnnotations,
      enabled: toRef(authStore, "isAuthed"),
    }),
  );
}

export function useAnnotationsForClickedDate(): ComputedRef<Annotation[]> {
  const annotationsStore = useAnnotationsStore();
  const annotations = useAnnotationsQuery();
  return computed(() => {
    return (
      annotations.data?.filter((e) => {
        const timeUnit = annotationsStore.timeUnit;
        return (
          timeUnit &&
          timeUnit !== TimeUnit.WEEKDAY &&
          DateTime.fromISO(e.start).startOf(timeUnit).toISODate() ===
            annotationsStore.clickedDate
        );
      }) ?? []
    );
  });
}

function deleteUserAnnotation(pk: number): Promise<void> {
  return api
    .delete(`/annotations/user_annotations/${pk}/`)
    .then((response) => response.data);
}

export function useDeleteUserAnnotationMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: deleteUserAnnotation,
      onSuccess: (_, pk) => {
        queryClient.setQueriesData<Annotation[]>(
          { queryKey: ["annotations"] },
          (annotations) =>
            annotations?.filter(
              (annotation: Annotation) =>
                annotation.channel !== AnnotationChannel.USER ||
                annotation.pk !== pk,
            ),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["annotations", "annotationCountsPerDate"],
        });
      },
    }),
  );
}

function deleteAutomatedAnnotation(pk: number): Promise<void> {
  return api
    .delete(`/annotations/automated_annotations/${pk}/`)
    .then((response) => response.data);
}

export function useDeleteAutomatedAnnotationMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: deleteAutomatedAnnotation,
      onSuccess: (_, pk) => {
        queryClient.setQueriesData<Annotation[]>(
          { queryKey: ["annotations"] },
          (annotations) =>
            annotations?.filter(
              (annotation: Annotation) =>
                annotation.channel === AnnotationChannel.USER ||
                annotation.pk !== pk,
            ),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["annotations", "annotationCountsPerDate"],
        });
      },
    }),
  );
}

function getAnnotationCountsPerDate({
  queryKey: [, params],
}: QueryFunctionContext<SelectionTemporalDataFilter>): Promise<
  Record<string, number>
> {
  return api
    .get("/annotations/annotation_counts_per_day/", {
      params,
    })
    .then((response) => response.data);
}

export function useAnnotationCountsPerDateQuery() {
  const queryKey = [
    "annotationCountsPerDate",
    { ...useDataFilters(["selectionTemporalFilter"]) },
  ] as const;
  const authStore = useAuthStore();
  return reactive(
    useQuery({
      queryKey,
      queryFn: getAnnotationCountsPerDate,
      enabled: toRef(authStore, "isAuthed"),
    }),
  );
}
