<template>
  <BasePopupMessage allow-overflow :show="absolutelyShowMessage">
    <div class="flex items-start p-4">
      <div class="shrink-0">
        <Avatar :user="SystemUser" size="size-12" />
      </div>
      <div class="ml-3 w-0 flex-1 pt-0.5">
        <p class="text-sm font-bold text-gray-700">Missing Channels</p>
        <div
          v-if="notDismissedMissingResources.length > 1"
          class="mt-1 text-sm text-gray-600"
        >
          <p>
            Get even more insight by adding these channels to your organization:
          </p>
          <ul class="list-inside list-disc">
            <li v-for="channel in notDismissedMissingResources" :key="channel">
              {{ channelDetails[channel].humanName }}
            </li>
          </ul>
        </div>
        <p
          v-else-if="notDismissedMissingResources.length === 1"
          class="mt-1 text-sm text-gray-600"
        >
          This report can be improved by adding a
          <span class="font-bold">{{
            channelDetails[notDismissedMissingResources[0]].humanName
          }}</span>
          account.
        </p>
        <div class="mt-3 flex items-center space-x-7">
          <RouterLink
            class="inline-link text-sm font-semibold leading-none"
            :to="
              notDismissedMissingResources.length === 1
                ? {
                    name: 'select-channel',
                    query: {
                      channel: notDismissedMissingResources[0],
                    },
                  }
                : {
                    name: 'select-channel',
                    query: { includedChannels: notDismissedMissingResources },
                  }
            "
          >
            {{
              notDismissedMissingResources.length > 1
                ? "Add accounts"
                : "Add account"
            }}
          </RouterLink>
        </div>
      </div>
      <div class="ml-4 flex shrink-0">
        <button
          class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
          @click="dismissForever"
        >
          <span class="sr-only">Close</span>
          <Icon name="times" class="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </BasePopupMessage>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import BasePopupMessage from "@/components/Core/PopupMessages/BasePopupMessage.vue";
import Icon from "@/components/Core/Icon.vue";
import {
  channelDetails,
  ResourceChannel,
  useResourcesQuery,
  hiddenChannels,
} from "@/services/resources";
import { ResourceStatus } from "@/utils/resources";
import Avatar from "@/components/Core/Avatar.vue";
import { SystemUser, useProfileQuery } from "@/services/user";
import * as lodash from "lodash-es";

import { useCookies } from "@vueuse/integrations/useCookies";
import { DateTime } from "luxon";

const props = defineProps({
  requiredResources: {
    type: Array as PropType<readonly ResourceChannel[]>,
    default: () => [],
  },
  canShow: { type: Boolean, default: false },
});

const self = useProfileQuery();
const activeOrganization = computed(() => {
  return self.data?.selectedOrganization ?? self.data?.organization;
});
const hideMessage = ref(false);

const cookies = useCookies();

const dismissedMissingResourcePopup = computed(() => {
  return (
    cookies.get(`dismissedMissingResourcePopup-${activeOrganization.value}`) ??
    []
  );
});

const missingResources = computed(() => {
  return props.requiredResources.filter(
    (channel) =>
      resourceStatus(channel) === ResourceStatus.MISSING &&
      ![
        ...hiddenChannels,
        ResourceChannel.XANDR,
        ResourceChannel.C_ALT,
        ResourceChannel.KOBLER,
        ResourceChannel.DV_360,
      ].includes(channel),
  );
});

const notDismissedMissingResources = computed(() => {
  return missingResources.value.filter((resource) => {
    return !dismissedMissingResourcePopup.value?.includes(resource);
  });
});

function closeMessage(): void {
  hideMessage.value = true;
}

function dismissForever(): void {
  if (activeOrganization.value) {
    cookies.set(
      `dismissedMissingResourcePopup-${activeOrganization.value}`,
      lodash.union(missingResources.value, notDismissedMissingResources.value),
      {
        expires: DateTime.now().plus({ year: 10 }).toJSDate(),
        path: "/",
      },
    );
  }
  closeMessage();
}

const resources = useResourcesQuery();

function resourceStatus(channel: ResourceChannel): ResourceStatus {
  let status: ResourceStatus = ResourceStatus.OK;
  const channelResources =
    resources.data?.filter((r) => r.channel === channel) ?? [];
  if (channelResources.length === 0) {
    status = ResourceStatus.MISSING;
  }
  return status;
}

const absolutelyShowMessage = computed(() => {
  return (
    notDismissedMissingResources.value.length > 0 &&
    missingResources.value.length > 0 &&
    props.canShow &&
    !hideMessage.value
  );
});
</script>
