<template>
  <Combobox
    v-slot="{ open }"
    :model-value="currentOrganization"
    :default-value="selectedOrganizationDropdownItems[0]"
    aria-label="Change organization"
    @update:model-value="(org) => updateSelectedOrganization(org)"
    @focusin="focused = true"
    @focusout="focused = false"
  >
    <div class="relative">
      <div
        class="mr-auto cursor-default overflow-hidden rounded-md tracking-wide transition-all duration-300"
        :class="{
          'w-full': focused || mobile,
          'w-48': !focused && !mobile,
          'float-right': !mobile,
        }"
      >
        <div class="relative">
          <ComboboxInput
            class="mr-3 rounded-md border-none py-2 pl-3 leading-5 tracking-wide transition-all duration-150"
            :class="
              focused
                ? 'bg-white text-gray-900 w-full'
                : 'bg-gray-800 text-gray-200 cursor-pointer truncate w-full md:w-44 md:text-right bg-transparent'
            "
            :display-value="
              (i) => {
                return (i as Organization | undefined)?.name ?? 'None';
              }
            "
            @change="query = $event.target.value"
            @focus="$event.target.select()"
          />
          <div
            class="absolute inset-y-0 right-0 flex items-center pr-2"
            :class="focused ? 'text-gray-900' : 'text-gray-200'"
          >
            <Icon
              name="angle-down"
              class="h-4 w-4 transition-all duration-300"
              :class="{ 'rotate-180': open }"
            />
          </div>
        </div>
      </div>
      <Transition name="fade-100" @after-leave="query = ''">
        <div v-show="focused">
          <ComboboxOptions
            static
            class="custom-table-scroll absolute z-30 max-h-screen-75 w-full overflow-auto rounded-md bg-white tracking-wide shadow-lg ring-1 ring-gray-400 focus:outline-none"
            :class="{ 'top-10': focused && !mobile, 'bottom-10': mobile }"
          >
            <div
              v-if="
                selectedOrganizationDropdownItems.length === 0 &&
                query.length > 0
              "
              class="relative cursor-default select-none px-4 py-2 text-gray-700"
            >
              No matches
            </div>
            <ComboboxOption
              v-for="organization in selectedOrganizationDropdownItems"
              v-else
              :key="organization.value"
              v-slot="{ selected, active }"
              :value="organization.value"
              as="template"
            >
              <li
                class="relative flex cursor-pointer select-none items-center gap-2 p-2 pr-4"
                :class="{
                  'bg-burgundy-50 text-burgundy-900': active,
                  'text-gray-700': !active,
                }"
              >
                <span
                  v-if="organization.value === currentOrganization?.id"
                  class="flex items-center text-purple-900"
                >
                  <Icon name="check" class="h-4 w-4" />
                </span>
                <span v-else class="h-4 w-4" />
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ organization.label }}
                </span>
                <span
                  v-if="organization.value === primaryOrganization"
                  class="rounded-lg px-2 text-xs text-purple-900 ring-1 ring-purple-800"
                >
                  Main
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </div>
      </Transition>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { useOrganizationsQuery } from "@/services/organization";
import {
  useProfileQuery,
  useUpdateSelectedOrganization,
} from "@/services/user";

import { useFuse } from "@/utils/arrays";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import Icon from "@/components/Core/Icon.vue";
import { Organization } from "@/services/organization";

defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
});
const focused = ref(false);
const self = useProfileQuery();
const query = ref("");

const primaryOrganization = computed(() => {
  return self.data?.organization;
});

const organizations = useOrganizationsQuery();

const filteredOrganizations = useFuse(
  computed(() => organizations.data ?? []),
  query,
  {
    keys: ["name"],
    threshold: 0.3,
  },
);

const currentOrganization = computed(() => {
  const currentOrg = self.data?.selectedOrganization ?? self.data?.organization;
  return organizations.data?.find(
    (organization) => organization.id === currentOrg,
  );
});

const selectedOrganizationDropdownItems = computed(
  () =>
    filteredOrganizations.value?.map((organization) => ({
      label: organization.name,
      value: organization.id,
    })),
);

const updateSelectedOrganization = useUpdateSelectedOrganization();
</script>
