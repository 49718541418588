import { ResourceChannel } from "@/services/resources";
import { DateTime } from "luxon";
import { AvailablePresets, ComparisonPreset } from "@/services/data_filters";
import * as lodash from "lodash-es";

// String key to the dataFiltersStore[key] to v-model to
export type RadioFilterStoreKeys =
  | "recommendationStatus"
  | "countryDataType"
  | "searchTermDataType"
  | "socialMediaSortByMetric"
  | "competitorMonitorSortByMetric"
  | "marketingMetricSet"
  | "waPagesSortMetric"
  | "websiteAnalyticsMetricSet"
  | "websiteAnalyticsPagesMetricSet";

// DATEPICKER
// TODO: Unit test presets
const startMonths = [1, 4, 7, 10];
const endMonths = [3, 6, 9, 12];

function getQuarter(
  q: number,
  y: number = DateTime.local().year,
): { start: DateTime; end: DateTime } {
  const start = DateTime.fromObject({
    year: y,
    month: startMonths[q - 1],
  }).startOf("month");
  const end = DateTime.fromObject({
    year: y,
    month: endMonths[q - 1],
  }).endOf("month");
  return { start, end };
}

function getQuartersAgo(
  q: number,
  referenceDay: DateTime,
): {
  range: { start: DateTime; end: DateTime };
  label: string;
} {
  const start = referenceDay.minus({ quarters: q }).startOf("quarter");
  const end = start.endOf("quarter");
  return {
    range: {
      start: start,
      end: modifyEndDateIfCurrentQuarter(end.quarter, end, referenceDay),
    },
    label: `Q${start.quarter} - ${start.year}`,
  };
}

function isDayInQuarter(q: number, referenceDay: DateTime): boolean {
  return (
    getQuarter(q).start <= referenceDay && referenceDay <= getQuarter(q).end
  );
}

function modifyEndDateIfCurrentQuarter(
  q: number,
  end: DateTime,
  referenceDay: DateTime,
): DateTime {
  return isDayInQuarter(q, referenceDay) ? referenceDay : end;
}
export function getMonthsAgo(
  monthsAgo: number,
  referenceDay: DateTime,
): DateTime {
  return referenceDay.minus({ months: monthsAgo }).startOf("month");
}

// Tucks the year on the month name if the month is in a different year than the reference day
export function getMonthName(dt: DateTime, referenceDay: DateTime): string {
  if (referenceDay.year !== dt.year) {
    return lodash.capitalize(dt.monthLong) + " " + dt.year;
  } else return lodash.capitalize(dt.monthLong);
}

function getSamePeriodAYearAgoComparisonPreset({
  referenceDay,
  dd,
  mm,
  startOf,
  endOf,
}: {
  referenceDay: DateTime;
  dd?: number;
  mm?: number;
  startOf?: "month" | "day";
  endOf?: "month" | "day";
}): ComparisonPreset {
  return {
    label: "Same period a year ago",
    range: () => {
      const lastYear = referenceDay.minus({ years: 1 });
      const end = lastYear.endOf(endOf ?? "day");
      const start = end
        .minus({ months: mm ?? 0, days: dd ?? 0 })
        .startOf(startOf ?? "day");
      return { start, end };
    },
  };
}
function getPreviousNDaysAgoComparisonPreset(
  n: number,
  referenceDay: DateTime,
): ComparisonPreset {
  return {
    label: `Previous ${n} days`,
    range: () => {
      const end = referenceDay.minus({ days: n }).endOf("day");
      const start = end.minus({ days: n - 1 }).startOf("day");
      return { start, end };
    },
  };
}
export function DateRangePresets(): AvailablePresets {
  const now = DateTime.local();
  const yesterday = now.minus({ days: 1 });
  return {
    today: {
      label: () => "Today",
      range: () => {
        const start = now.startOf("day");
        const end = start.endOf("day");
        return { start, end };
      },
      comparisonPresets: {
        yesterday: {
          label: "Yesterday",
          range: () => {
            const start = yesterday.startOf("day");
            const end = start.endOf("day");
            return { start, end };
          },
        },
        sameDayLastWeek: {
          label: "Same day last week",
          range: () => {
            const start = now.minus({ days: 7 }).startOf("day");
            const end = start.endOf("day");
            return { start, end };
          },
        },
        sameDayLastMonth: {
          label: "Same day last month",
          range: () => {
            const start = now.minus({ months: 1 }).startOf("day");
            const end = start.endOf("day");
            return { start, end };
          },
        },
        sameDayAYearAgo: {
          label: "Same day a year ago",
          range: () => {
            const start = now.minus({ years: 1 }).startOf("day");
            const end = start.endOf("day");
            return { start, end };
          },
        },
      },
    },
    yesterday: {
      label: () => "Yesterday",
      range: () => {
        const start = yesterday.startOf("day");
        const end = start.endOf("day");
        return { start, end };
      },
      comparisonPresets: {
        dayBeforeYesterday: {
          label: "Day before yesterday",
          range: () => {
            const start = yesterday.minus({ days: 1 }).startOf("day");
            const end = start.endOf("day");
            return { start, end };
          },
        },
        sameDayPreviousWeek: {
          label: "Same day previous week",
          range: () => {
            const start = yesterday.minus({ days: 7 }).startOf("day");
            const end = start.endOf("day");
            return { start, end };
          },
        },
        sameDayPreviousMonth: {
          label: "Same day previous month",
          range: () => {
            const start = yesterday.minus({ months: 1 }).startOf("day");
            const end = start.endOf("day");
            return { start, end };
          },
        },
        sameDayPreviousYear: {
          label: "Same day previous year",
          range: () => {
            const start = yesterday.minus({ years: 1 }).startOf("day");
            const end = start.endOf("day");
            return { start, end };
          },
        },
      },
    },
    last7days: {
      label: () => "Last 7 days",
      range: () => {
        const end = yesterday.endOf("day");
        const start = end.minus({ days: 6 }).startOf("day");
        return { start, end };
      },
      comparisonPresets: {
        previous7days: getPreviousNDaysAgoComparisonPreset(7, yesterday),
        samePeriodAYearAgo: getSamePeriodAYearAgoComparisonPreset({
          referenceDay: yesterday,
          dd: 6,
        }),
      },
    },
    last30days: {
      label: () => "Last 30 days",
      range: () => {
        const end = yesterday.endOf("day");
        const start = end.minus({ days: 29 }).startOf("day");
        return { start, end };
      },
      comparisonPresets: {
        previous30days: getPreviousNDaysAgoComparisonPreset(30, yesterday),
        samePeriodAYearAgo: getSamePeriodAYearAgoComparisonPreset({
          referenceDay: yesterday,
          dd: 29,
        }),
      },
    },
    last60days: {
      label: () => "Last 60 days",
      range: () => {
        const end = yesterday.endOf("day");
        const start = end.minus({ days: 59 });
        return { start, end };
      },
      comparisonPresets: {
        previous60days: getPreviousNDaysAgoComparisonPreset(60, yesterday),
        samePeriodAYearAgo: getSamePeriodAYearAgoComparisonPreset({
          referenceDay: yesterday,
          dd: 59,
        }),
      },
    },
    last90days: {
      label: () => "Last 90 days",
      range: () => {
        const end = yesterday.endOf("day");
        const start = end.minus({ days: 89 }).startOf("day");
        return { start, end };
      },
      comparisonPresets: {
        previous90days: getPreviousNDaysAgoComparisonPreset(90, yesterday),
        samePeriodAYearAgo: getSamePeriodAYearAgoComparisonPreset({
          referenceDay: yesterday,
          dd: 89,
        }),
      },
    },
    thisMonth: {
      label: () => "This month",
      range: () => {
        const end = now.endOf("day");
        const start = end.startOf("month");
        return { start, end };
      },
      comparisonPresets: {
        previousMonth: {
          label: getMonthName(getMonthsAgo(1, now), now),
          range: () => {
            const start = getMonthsAgo(1, now);
            const end = start.endOf("month");
            return { start, end };
          },
        },
        sameMonthAYearAgo: {
          label: getMonthName(getMonthsAgo(12, now), now),
          range: () => {
            const start = getMonthsAgo(12, now);
            const end = start.endOf("month");
            return { start, end };
          },
        },
        samePeriodLastMonth: {
          label: "Same period last month",
          range: () => {
            const end = now.endOf("day").minus({ months: 1 });
            const start = end.startOf("month");
            return { start, end };
          },
        },
        samePeriodAYearAgo: getSamePeriodAYearAgoComparisonPreset({
          referenceDay: now,
          startOf: "month",
          endOf: "day",
        }),
      },
    },
    lastMonth: {
      label: () => "Last month",
      range: () => {
        const start = getMonthsAgo(1, now);
        const end = start.endOf("month");
        return { start, end };
      },
      comparisonPresets: {
        previousMonth: {
          label: getMonthName(getMonthsAgo(2, now), now),
          range: () => {
            const start = getMonthsAgo(2, now);
            const end = start.endOf("month");
            return { start, end };
          },
        },
        sameMonthAYearAgo: {
          label: getMonthName(getMonthsAgo(13, now), now),
          range: () => {
            const start = getMonthsAgo(13, now);
            const end = start.endOf("month");
            return { start, end };
          },
        },
      },
    },
    currentQuarter: {
      label: () => getQuartersAgo(0, now).label,
      range: () => getQuartersAgo(0, now).range,
      comparisonPresets: {
        previousQuarter: {
          label: "Previous quarter",
          range: () => {
            return {
              start: getQuartersAgo(0, now)
                .range.start.minus({ quarters: 1 })
                .startOf("quarter"),
              end: getQuartersAgo(0, now)
                .range.end.minus({ quarters: 1 })
                .endOf("quarter"),
            };
          },
        },
        previousYear: {
          label: "Same quarter last year",
          range: () => {
            return {
              start: getQuartersAgo(0, now)
                .range.start.minus({ years: 1 })
                .startOf("quarter"),
              end: getQuartersAgo(0, now)
                .range.end.minus({ years: 1 })
                .endOf("quarter"),
            };
          },
        },
        samePeriodLastQuarter: {
          label: "Same period last quarter",
          range: () => {
            return {
              start: getQuartersAgo(0, now)
                .range.start.minus({ quarters: 1 })
                .startOf("day"),
              end: now.minus({ quarters: 1 }).endOf("day"),
            };
          },
        },
        samePeriodLastyear: {
          label: "Same period last year",
          range: () => {
            return {
              start: getQuartersAgo(0, now)
                .range.start.minus({ years: 1 })
                .startOf("day"),
              end: now.minus({ years: 1 }).endOf("day"),
            };
          },
        },
      },
    },
    qMinus1: {
      label: () => getQuartersAgo(1, now).label,
      range: () => getQuartersAgo(1, now).range,
      comparisonPresets: {
        previousQuarter: {
          label: "Previous quarter",
          range: () => {
            return {
              start: getQuartersAgo(1, now)
                .range.start.minus({ quarters: 1 })
                .startOf("quarter"),
              end: getQuartersAgo(1, now)
                .range.end.minus({ quarters: 1 })
                .endOf("quarter"),
            };
          },
        },
        previousYear: {
          label: "Same quarter last year",
          range: () => {
            return {
              start: getQuartersAgo(1, now).range.start.minus({
                years: 1,
              }),
              end: getQuartersAgo(1, now).range.end.minus({ years: 1 }),
            };
          },
        },
      },
    },
    qMinus2: {
      label: () => getQuartersAgo(2, now).label,
      range: () => getQuartersAgo(2, now).range,
      comparisonPresets: {
        previousQuarter: {
          label: "Previous quarter",
          range: () => {
            return {
              start: getQuartersAgo(2, now).range.start.minus({
                quarters: 1,
              }),
              end: getQuartersAgo(2, now).range.end.minus({
                quarters: 1,
              }),
            };
          },
        },
        previousYear: {
          label: "Same quarter last year",
          range: () => {
            return {
              start: getQuartersAgo(2, now).range.start.minus({
                years: 1,
              }),
              end: getQuartersAgo(2, now).range.end.minus({ years: 1 }),
            };
          },
        },
      },
    },
    qMinus3: {
      label: () => getQuartersAgo(3, now).label,
      range: () => getQuartersAgo(3, now).range,
      comparisonPresets: {
        previousQuarter: {
          label: "Previous quarter",
          range: () => {
            return {
              start: getQuartersAgo(3, now).range.start.minus({
                quarters: 1,
              }),
              end: getQuartersAgo(3, now).range.end.minus({
                quarters: 1,
              }),
            };
          },
        },
        previousYear: {
          label: "Same quarter last year",
          range: () => {
            return {
              start: getQuartersAgo(3, now).range.start.minus({
                years: 1,
              }),
              end: getQuartersAgo(3, now).range.end.minus({ years: 1 }),
            };
          },
        },
      },
    },
    thisYear: {
      label: () => "This year",
      range: () => {
        const end = now.endOf("day");
        const start = end.startOf("year");
        return { start, end };
      },
      comparisonPresets: {
        previousYear: {
          label: "Last year",
          range: () => {
            const end = now.minus({ years: 1 }).endOf("year");
            const start = end.startOf("year");
            return { start, end };
          },
        },
        samePeriodPreviousYear: {
          label: "Same period last year",
          range: () => {
            const end = now.minus({ years: 1 });
            const start = end.startOf("year");
            return { start, end };
          },
        },
      },
    },
  };
}

export enum AdNetwork {
  MESSENGER = "messenger",
  FB_UNKNOWN = "unknown", // TODO: Separate this from the LinkedIn unknown to avoid filter collision
  FACEBOOK = "facebook",
  AUDIENCE_NETWORK = "audience_network",
  INSTAGRAM = "instagram",
  YOUTUBE_WATCH = "YOUTUBE_WATCH",
  MIXED = "MIXED",
  YOUTUBE_SEARCH = "YOUTUBE_SEARCH",
  SEARCH_PARTNERS = "SEARCH_PARTNERS",
  CONTENT = "CONTENT",
  SEARCH = "SEARCH",
  LI_UNKNOWN = "UNKNOWN",
  OFF_SITE = "OFF_SITE",
  ON_SITE = "ON_SITE",
  SNAPCHAT = "Snapchat",
  AOL_SEARCH = "AOL Search",
  AUDIENCE = "Audience",
  BING_AND_YAHOO_SEARCH = "Bing and Yahoo! search",
  MICROSOFT_SITES_AND_SELECT_TRAFFIC = "Microsoft sites and select traffic",
}

export function getChannelNetworks(channel: ResourceChannel): AdNetwork[] {
  switch (channel) {
    case ResourceChannel.FACEBOOK_ADS:
      return [
        AdNetwork.MESSENGER,
        AdNetwork.FB_UNKNOWN,
        AdNetwork.FACEBOOK,
        AdNetwork.AUDIENCE_NETWORK,
        AdNetwork.INSTAGRAM,
      ];
    case ResourceChannel.GOOGLE_ADS:
      return [
        AdNetwork.YOUTUBE_WATCH,
        AdNetwork.MIXED,
        AdNetwork.YOUTUBE_SEARCH,
        AdNetwork.SEARCH_PARTNERS,
        AdNetwork.CONTENT,
        AdNetwork.SEARCH,
      ];
    case ResourceChannel.LINKED_IN_ADS:
      return [AdNetwork.OFF_SITE, AdNetwork.LI_UNKNOWN, AdNetwork.ON_SITE];
    case ResourceChannel.SNAPCHAT_ADS:
      return [AdNetwork.SNAPCHAT];
    case ResourceChannel.BING_ADS:
      return [
        AdNetwork.AOL_SEARCH,
        AdNetwork.AUDIENCE,
        AdNetwork.BING_AND_YAHOO_SEARCH,
        AdNetwork.MICROSOFT_SITES_AND_SELECT_TRAFFIC,
      ];
  }
  return [];
}
export function getNetworkChannel(network: AdNetwork): ResourceChannel {
  return {
    [AdNetwork.MESSENGER]: ResourceChannel.FACEBOOK_ADS,
    [AdNetwork.FB_UNKNOWN]: ResourceChannel.FACEBOOK_ADS,
    [AdNetwork.FACEBOOK]: ResourceChannel.FACEBOOK_ADS,
    [AdNetwork.AUDIENCE_NETWORK]: ResourceChannel.FACEBOOK_ADS,
    [AdNetwork.INSTAGRAM]: ResourceChannel.FACEBOOK_ADS,
    [AdNetwork.YOUTUBE_WATCH]: ResourceChannel.GOOGLE_ADS,
    [AdNetwork.MIXED]: ResourceChannel.GOOGLE_ADS,
    [AdNetwork.YOUTUBE_SEARCH]: ResourceChannel.GOOGLE_ADS,
    [AdNetwork.SEARCH_PARTNERS]: ResourceChannel.GOOGLE_ADS,
    [AdNetwork.CONTENT]: ResourceChannel.GOOGLE_ADS,
    [AdNetwork.SEARCH]: ResourceChannel.GOOGLE_ADS,
    [AdNetwork.OFF_SITE]: ResourceChannel.LINKED_IN_ADS,
    [AdNetwork.LI_UNKNOWN]: ResourceChannel.LINKED_IN_ADS,
    [AdNetwork.ON_SITE]: ResourceChannel.LINKED_IN_ADS,
    [AdNetwork.SNAPCHAT]: ResourceChannel.SNAPCHAT_ADS,
    [AdNetwork.AOL_SEARCH]: ResourceChannel.BING_ADS,
    [AdNetwork.AUDIENCE]: ResourceChannel.BING_ADS,
    [AdNetwork.BING_AND_YAHOO_SEARCH]: ResourceChannel.BING_ADS,
    [AdNetwork.MICROSOFT_SITES_AND_SELECT_TRAFFIC]: ResourceChannel.BING_ADS,
  }[network];
}
