import api from "@/services/api";
import { baseUrl } from "@/services/api";
import { TimeUnit, TemporalDataFilter } from "@/services/data_filters";
import { useDataFilters } from "@/store/data_filters";
import { reactive, Ref } from "vue";
import { useQuery } from "@tanstack/vue-query";
import {
  TimeSeriesDataset,
  ResponseTimeSeriesDatasets,
} from "@/utils/datasets";
import { QueryFunctionContext } from "@/utils/typing";
import {
  PublicDashboardFilters,
  usePublicDashboardWidgetQueryFilters,
} from "@/services/public_dashboard";

export function getLighthouseReportViewerLink(reportId: number): string {
  return `/lighthouse/viewer/?jsonurl=${baseUrl}/api/lighthouse/reports/${reportId}/data/`;
}

export interface LighthousePageReport {
  pk: number;
  date: string;
  organization: number;
  seoScore: number | null;
  performanceScore: number | null;
  accessibilityScore: number | null;
  bestPracticesScore: number | null;
  url: string;
  page: number;
}
export interface LighthouseAveragePageScoreDataset
  extends Pick<TimeSeriesDataset, "label" | "data"> {
  label: "seo" | "accessibility" | "best_practices" | "performance";
}
export interface LighthouseAveragePageScoreDatasets
  extends Pick<ResponseTimeSeriesDatasets, "labels"> {
  datasets: LighthouseAveragePageScoreDataset[];
}

export interface LighthouseLatestAveragePageScores {
  date: string | null;
  avgSeoScore: number | null;
  avgPerformanceScore: number | null;
  avgAccessibilityScore: number | null;
  avgBestPracticesScore: number | null;
}

function listLatestLighthousePageReports({
  queryKey: [, params],
}: QueryFunctionContext<{ pageId: number | undefined }>): Promise<
  LighthousePageReport[]
> {
  return api
    .get("/lighthouse/latest_reports/", { params })
    .then((response) => response.data);
}

export function useLatestLighthousePageReportsQuery({
  pageId,
}: {
  pageId?: Ref<number>;
} = {}) {
  const queryKey = ["latestLighthousePageReports", { pageId }] as const;
  return reactive(
    useQuery({ queryKey, queryFn: listLatestLighthousePageReports }),
  );
}

function listAverageLighthousePageScoresByDate({
  queryKey: [, params],
}: QueryFunctionContext<
  { timeUnit: TimeUnit; pageId: number | undefined } & TemporalDataFilter
>): Promise<LighthouseAveragePageScoreDatasets> {
  return api
    .get("/lighthouse/average_scores/", { params })
    .then((response) => response.data);
}

export function useAverageLighthousePageScoresByDateQuery({
  timeUnit,
  pageId,
}: {
  timeUnit: Ref<TimeUnit>;
  pageId: Ref<number | undefined>;
}) {
  const queryKey = [
    "averageLighthousePageScoresByDateQuery",
    {
      ...useDataFilters(["temporalFilter"]),
      timeUnit,
      pageId,
    },
  ] as const;
  return reactive(
    useQuery({ queryKey, queryFn: listAverageLighthousePageScoresByDate }),
  );
}

function getLatestAverageLighthousePageScores({
  queryKey: [, params],
}: QueryFunctionContext<
  { pageId: number | undefined } & PublicDashboardFilters
>): Promise<LighthouseLatestAveragePageScores> {
  return api
    .get("/lighthouse/latest_average_scores/", { params })
    .then((response) => response.data);
}

export function useLatestAverageLighthousePageScoreQuery({
  enabled,
  pageId,
}: {
  enabled?: Ref<boolean>;
  pageId?: Ref<number>;
} = {}) {
  const queryKey = [
    "latestAverageLighthouseScore",
    { ...usePublicDashboardWidgetQueryFilters(), pageId },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: getLatestAverageLighthousePageScores,
      enabled,
    }),
  );
}
