import { NavigationTab } from "@/components/Navigation";
import { IconName } from "@/services/icons";
import { ResourceChannel } from "@/services/resources";
import { RouteRecordRaw } from "vue-router";

export const websiteRoutes: RouteRecordRaw[] = [
  {
    path: "website-analytics",
    name: "website-analytics",
    redirect: { name: "website-analytics-acquisition-tab" },
    meta: {
      title: "Website Analytics Report",
      updated: "2022-12-16",
      text: "Website Analytics",
      description:
        "Unlock comprehensive website analytics, including SEO, traffic, and user experience insights, all in one place",
      icon: "chart-box",
      helpDeskPath: "website-analytics",
    },
    component: () =>
      import("@/views/WebsiteAnalytics/WebsiteAnalyticsReportView.vue"),
    children: [
      {
        path: "acquisition",
        name: "website-analytics-acquisition-tab",
        meta: { title: "Acquisition | Website Analytics" },
        component: () =>
          import("@/views/WebsiteAnalytics/WebsiteAnalyticsAcquisitionTab.vue"),
      },
      {
        path: "pages",
        name: "website-analytics-pages-tab",
        meta: { title: "Pages | Website Analytics" },
        component: () =>
          import("@/views/WebsiteAnalytics/WebsiteAnalyticsPagesTab.vue"),
      },
      {
        path: "conversions",
        name: "website-analytics-conversions-tab",
        meta: { title: "Conversions | Website Analytics" },
        component: () =>
          import("@/views/WebsiteAnalytics/WebsiteAnalyticsConversionsTab.vue"),
      },
      {
        path: "conversions/:pk",
        name: "website-analytics-conversion-details",
        meta: { title: "Conversion Details | Website Analytics" },
        component: () =>
          import(
            "@/views/WebsiteAnalytics/WebsiteAnalyticsConversionDetails.vue"
          ),
      },
    ],
  },
  {
    path: "site-score",
    name: "site-score",
    meta: {
      title: "Site Score",
      text: "Site Score",
      description: "See how you can improve your website experience",
      icon: "browser",
      helpDeskPath: "site-score",
    },
    component: () => import("@/views/SiteScore/SiteScoreReport.vue"),
  },
  {
    path: "page-insights",
    name: "page-insights",
    redirect: { name: "page-insights-search" },
    meta: {
      title: "Page Insights",
      text: "Page Insights",
      added: "2022-03-10",
      icon: "search" as IconName,
      description:
        "Get detailed analytics on SEO, traffic and improvement potential for all your web pages",
      requiredResources: [
        ResourceChannel.GOOGLE_ANALYTICS_4,
        ResourceChannel.GOOGLE_SEARCH_CONSOLE,
      ],
    },
    component: () => import("@/views/PageInsights/PageInsightsBase.vue"),
    children: [
      {
        path: "",
        name: "page-insights-search",
        component: () =>
          import("@/views/PageInsights/PageInsightsSearchPages.vue"),
      },
      {
        path: ":pk",
        name: "page-insights-base",
        redirect: { name: "page-insights-content" },
        children: [
          {
            path: "content",
            name: "page-insights-content",
            meta: { title: "Content" },
            component: () =>
              import("@/views/PageInsights/PageInsightsContentTab.vue"),
          },
          {
            path: "history",
            name: "page-history",
            meta: { title: "History" },
            component: () =>
              import("@/views/PageInsights/PageInsightsHistoryTab.vue"),
          },
          {
            path: "recommendations",
            name: "page-insights-recommendations",
            meta: { title: "Recommendations" },
            component: () =>
              import("@/views/PageInsights/PageInsightsRecommendationsTab.vue"),
          },
          {
            path: "acquisition",
            name: "page-insights-acquisition",
            meta: {
              title: "Acquisition",
              // Ensures that filters are preserved when navigating from website analytics.
              text: "Website Analytics",
              requiredResources: [ResourceChannel.GOOGLE_ANALYTICS_4],
            },
            component: () =>
              import("@/views/PageInsights/PageInsightsAcquisitionTab.vue"),
          },
          {
            path: "metadata",
            name: "page-insights-metadata",
            meta: { title: "Metadata" },
            component: () =>
              import("@/views/PageInsights/PageInsightMetadataTab.vue"),
          },
          {
            path: "site-score",
            name: "page-insights-site-score",
            meta: {
              title: "Site score",
            },
            component: () =>
              import("@/views/PageInsights/PageInsightsSiteScoreTab.vue"),
          },
        ],
      },
    ],
  },
];

export const websiteTabs: NavigationTab[] = [
  {
    name: "Acquisition",
    href: "website-analytics-acquisition-tab",
  },
  {
    name: "Pages",
    href: "website-analytics-pages-tab",
  },
  {
    name: "Conversions and events",
    href: "website-analytics-conversions-tab",
    added: "2022-12-16",
  },
  {
    name: "Site Score",
    href: "site-score",
  },
  {
    name: "Page Insights",
    href: "page-insights",
  },
];
