<template>
  <nav class="sticky top-0 z-50 w-full bg-burgundy-900 font-artex">
    <div class="mx-auto w-full px-2 xl:container">
      <div class="flex h-16 items-center justify-between">
        <div class="relative flex items-center">
          <div
            v-if="showMenuPopup"
            class="absolute inset-0 z-10 h-full w-full bg-black/50"
          ></div>
          <RouterLink
            :to="{ name: 'feed' }"
            aria-label="Home"
            class="-mt-0.5 ml-4 mr-8 flex gap-2 lg:ml-0"
            :class="{
              'relative z-50 rounded-lg border-2 border-gray-200 p-2':
                guidedMenuItem === 'feed' && showMenuPopup,
            }"
          >
            <img alt="" src="@/assets/Logomark.png" class="m-auto h-6 w-6" />
            <p class="font-artex text-lg text-white">Fyr</p>
          </RouterLink>
          <div v-if="authStore.isAuthed" class="hidden lg:block">
            <div class="ml-2 flex space-x-1 lg:space-x-4">
              <NavbarLinkDropdown
                :links="marketingRoutes"
                text="Analytics"
                :class="{
                  'relative z-50 rounded-lg border-2 border-gray-200':
                    guidedMenuItem === 'Analytics',
                }"
              />
              <NavbarLinkDropdown
                :links="insightsRoutes"
                text="Insights"
                :direction="bp.greater.lg ? 'right' : 'left'"
                :class="{
                  'relative z-50 rounded-lg border-2 border-gray-200':
                    guidedMenuItem === 'Insights',
                }"
              />
              <NavbarLinkDropdown
                :links="toolsRoutes"
                text="Content lab"
                :direction="bp.greater.lg ? 'right' : 'left'"
                :class="{
                  'relative z-50 rounded-lg border-2 border-gray-200':
                    guidedMenuItem === 'Content lab',
                }"
              />
              <NavbarLinkDropdown
                :links="salesRoutes"
                text="Budget"
                :direction="bp.greater.lg ? 'right' : 'left'"
                :class="{
                  'relative z-50 rounded-lg border-2 border-gray-200':
                    guidedMenuItem === 'Budget',
                }"
              />
              <NavbarLinkDropdown
                :links="reportingRoutes"
                text="Reporting"
                :class="{
                  'relative z-50 rounded-lg border-2 border-gray-200':
                    guidedMenuItem === 'Reporting',
                }"
              />
            </div>
          </div>
        </div>
        <div class="hidden lg:block">
          <div v-if="authStore.isAuthed" class="flex items-center gap-x-1">
            <SelectOrganizationDropdown v-if="showSelectOrganizationDropdown" />
            <NotificationDropdown />
            <NavbarLinkDropdown
              :links="filteredSettingsRoutes"
              text=""
              direction="left"
            >
              <!-- <NavbarLinkDropdown :links="settingRoutes" text="" direction="left"> -->
              <template #avatar>
                <Avatar :user="self.data" />
              </template>
              <template #extra-items>
                <MenuItem v-slot="{ active }">
                  <button
                    class="flex w-full rounded-b-md px-4 pb-3 pt-2 transition duration-300 hover:cursor-pointer hover:bg-burgundy-50"
                    :class="{ 'outline-none ring-2': active }"
                    @click="logOut()"
                  >
                    <Icon
                      name="house-leave-solid"
                      class="mt-1 h-5 w-6 text-gray-600"
                    />
                    <span class="ml-4 w-full text-left leading-tight">
                      <p>Sign out</p>
                      <p class="mt-1 text-sm text-gray-700">
                        Back to the login screen
                      </p>
                    </span>
                  </button>
                </MenuItem>
              </template>
            </NavbarLinkDropdown>
          </div>
        </div>

        <div v-if="authStore.isAuthed" class="mr-2 flex space-x-4 lg:hidden">
          <!-- Mobile menu button -->
          <RouterLink
            :to="{ name: 'notifications' }"
            class="relative inline-flex items-center justify-center rounded-md p-1 text-gray-400 hover:bg-burgundy-700 hover:text-white focus:bg-burgundy-700 focus:text-white focus:outline-none"
          >
            <Icon name="bell" class="h-5 w-5" />
            <div
              v-if="hasUnreadNotifications"
              class="absolute right-0.5 top-0.5 h-2 w-2 rounded-full bg-red-600"
            />
            <div
              v-if="hasUnreadNotifications"
              class="absolute right-0.5 top-0.5 h-2 w-2 animate-ping rounded-full bg-red-600"
            />
            <span class="sr-only">Unread notifications</span>
          </RouterLink>
          <button
            class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-burgundy-700 hover:text-white focus:bg-burgundy-700 focus:text-white focus:outline-none"
            :aria-label="showMobileMenu ? 'Hide menu' : 'Show menu'"
            :aria-expanded="showMobileMenu"
            aria-controls="mobile-menu"
            @click="toggleMobileMenu"
          >
            <!-- Menu open: "hidden", Menu closed: "block" -->
            <Icon v-if="!showMobileMenu" name="bars" class="h-5 w-5" />
            <!-- Menu open: "block", Menu closed: "hidden" -->
            <Icon v-if="showMobileMenu" name="times" class="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>

    <!--
      Mobile menu, toggle classes based on menu state.
      Open: "block", closed: "hidden"
    -->
    <div
      v-if="authStore.isAuthed"
      id="mobile-menu"
      class="fixed inset-x-0 bottom-0 top-16 z-50 overflow-y-auto bg-burgundy-900 lg:hidden"
      :class="{ block: showMobileMenu, hidden: !showMobileMenu }"
    >
      <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
        <div class="ml-2 flex flex-col space-x-1 md:flex-row lg:space-x-4">
          <NavbarLinkDropdown
            :links="marketingRoutes"
            text="Analytics"
            @hide-menu="hideMobileMenu"
          />
          <NavbarLinkDropdown
            :links="insightsRoutes"
            text="Insights"
            :direction="bp.greater.lg ? 'right' : 'left'"
            @hide-menu="hideMobileMenu"
          />
          <NavbarLinkDropdown
            :links="toolsRoutes"
            text="Content lab"
            :direction="bp.greater.lg ? 'right' : 'left'"
            @hide-menu="hideMobileMenu"
          />
          <NavbarLinkDropdown
            :links="salesRoutes"
            text="Budget"
            :direction="bp.greater.lg ? 'right' : 'left'"
            @hide-menu="hideMobileMenu"
          />
          <NavbarLinkDropdown
            :links="reportingRoutes"
            text="Reporting"
            @hide-menu="hideMobileMenu"
          />
        </div>
      </div>
      <div class="mt-2 border-gray-700 pb-3 pt-4 md:mt-0 md:border-t">
        <div
          class="flex items-center space-x-3 px-5"
          @click="profileDropdown = !profileDropdown"
        >
          <div class="shrink-0">
            <Avatar :user="self.data" />
          </div>
          <div class="space-y-1">
            <div class="flex leading-none text-white">
              {{ self.data?.name }}
              <Icon
                v-if="showMobileMenu"
                name="angle-down"
                class="-mb-0.5 ml-1 h-4 w-4 self-center transition"
                :class="{ 'rotate-180': profileDropdown }"
              />
            </div>
            <div class="text-sm leading-none text-gray-400">
              {{ self.data?.email }}
            </div>
          </div>
        </div>
        <div v-if="profileDropdown" class="ml-2 mt-3 space-y-1 px-2 md:ml-0">
          <RouterLink
            :to="{ name: 'profile' }"
            active-class="bg-gray-900"
            class="block rounded-md px-3 py-1 text-white opacity-80 hover:bg-gray-700 focus:bg-gray-700 focus:text-white focus:outline-none md:text-gray-200"
          >
            Profile
          </RouterLink>
          <RouterLink
            :to="{ name: 'settings' }"
            active-class="bg-purple-900"
            class="block rounded-md px-3 py-1 text-gray-200 hover:bg-purple-700 focus:bg-purple-700 focus:text-white focus:outline-none"
          >
            {{ organization.data?.name || "Organization" }}
          </RouterLink>
          <div
            v-if="showSelectOrganizationDropdown"
            class="hidden md:block"
            @click.stop
          >
            <p class="pl-3 pt-2 text-xs text-gray-200">Switch organization</p>
            <SelectOrganizationDropdown mobile />
          </div>

          <button
            class="block w-full rounded-md px-3 py-1 text-left text-gray-200 hover:bg-gray-700 focus:bg-gray-700 focus:text-white focus:outline-none"
            @click="logOut"
          >
            Sign out
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="newVersion"
      class="flex w-full flex-col items-center justify-center gap-2 py-1 pb-4 text-white sm:hidden"
    >
      <h1 class="text-lg font-semibold leading-none">
        <span> A new version of Fyr is available! </span>
        <span>&#x1F389;</span>
      </h1>
      <p class="leading-none">
        Please refresh the page to get the latest updates
      </p>
      <button
        class="flex w-60 items-center justify-center gap-2 rounded-md bg-green-500 px-2 py-1 text-lg font-semibold tracking-wide text-white hover:bg-green-600 focus:outline-none focus:ring"
        @click="emitRefresh"
      >
        Refresh
      </button>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import NavbarLinkDropdown from "@/components/Navigation/NavbarLinkDropdown.vue";
import Avatar from "@/components/Core/Avatar.vue";
import Icon from "@/components/Core/Icon.vue";
import { marketingRoutes } from "@/router/marketing_routes";
import { insightsRoutes } from "@/router/insights_routes";
import { salesRoutes } from "@/router/sales_routes";
import { settingRoutes } from "@/router/setting_routes";
import { toolsRoutes } from "@/router/tools_routes";
import { useAuthStore } from "@/store/auth";
import NotificationDropdown from "@/components/Core/NotificationDropdown.vue";
import { useNotificationsQuery } from "@/services/notifications";
import {
  useOrganizationQuery,
  useOrganizationsQuery,
} from "@/services/organization";
import { useProfileQuery } from "@/services/user";
import { useBp } from "@/utils/breakpoints";
import { reportingRoutes } from "@/router/reporting_routes";
import { RouteRecordRaw } from "vue-router";
import SelectOrganizationDropdown from "@/components/Core/SelectOrganizationDropdown.vue";
import { MenuItem } from "@headlessui/vue";

defineProps({
  newVersion: { type: Boolean, default: false },
  showMenuPopup: { type: Boolean, default: false },
  guidedMenuItem: { type: String, default: "" },
});
const emit = defineEmits({
  "refresh-page": () => true,
});
const bp = useBp();
const authStore = useAuthStore();
const self = useProfileQuery();
const showMobileMenu = ref(false);
const profileDropdown = ref(false);
const organization = useOrganizationQuery();
function toggleMobileMenu(): void {
  showMobileMenu.value = !showMobileMenu.value;
}

if (window.innerWidth > 768) {
  profileDropdown.value = true;
}
function hideMobileMenu(): void {
  showMobileMenu.value = false;
}
function emitRefresh(): void {
  emit("refresh-page");
}
const notifications = useNotificationsQuery();
const hasUnreadNotifications = computed(() => {
  return notifications.data?.some((n) => n.readStatus === "UNREAD") ?? false;
});
function logOut(): void {
  authStore.logout();
}
const filteredSettingsRoutes = computed(() => {
  return settingRoutes.filter((route: RouteRecordRaw) => {
    if (route.meta && "requiredPermissions" in route.meta) {
      if (self.data) {
        return route.meta.requiredPermissions?.every((permission) => {
          return !(
            permission in self.data &&
            !self.data[permission as keyof typeof self.data]
          );
        });
      }
    } else {
      return true;
    }
  });
});

const organizations = useOrganizationsQuery();

const showSelectOrganizationDropdown = computed(
  () => organizations.data && organizations.data.length > 1,
);
</script>
