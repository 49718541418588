import { MetricFilterSet, MetricKey } from "@/services/metrics";
import { ResourceChannel } from "@/services/resources";
import { computed, reactive, ref, Ref } from "vue";
import api from "@/services/api";
import {
  SelectionTemporalDataFilter,
  ResourcesDatafilter,
  TimeUnit,
  TagsDataFilter,
  AdNetworkFilter,
  PKFilter,
  DateRangeSelection,
  ConversionFilter,
} from "@/services/data_filters";
import {
  useCalculatedResources,
  useConversionGroups,
  useConversions,
  useDataFilters,
  useDetachedSelectionTemporalFilter,
  useDetachedTagFilter,
} from "@/store/data_filters";
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/vue-query";
import {
  AggregatedMarketingMetrics,
  ComparisonMarketingMetrics,
  MarketingMetrics,
} from "@/services/marketing";
import { useResetPage } from "@/services/utils";
import { ResponseTimeSeriesDatasets } from "@/utils/datasets";
import { QueryFunctionContext } from "@/utils/typing";
import { usePublicDashboardWidgetQueryFilters } from "./public_dashboard";
import { filterCriteriaFormatter } from "@/utils/filterCriteriaFormatter";

export enum AdStatusAction {
  PAUSE = "Pause",
  ENABLE = "Enable",
}

export enum AdStatus {
  PAUSED = "Paused",
  ENABLED = "Enabled",
}

export enum AdContentType {
  // SoMe FB, LinkedIn, Snapchat
  IMAGE = "image",
  MULTI_IMAGE = "multiple_images",
  VIDEO = "video",
  CAROUSEL = "carousel",
  DYNAMIC_IMAGE = "dynamic_image",
  DYNAMIC_VIDEO = "dynamic_video",

  // Google search
  RSA = "RSA",
  ETA = "ETA",
  SHOPPING = "shopping",
  // TEXT = "text", // unsupported

  // Google discovery ads
  DISCOVERY_MULTI_ASSET_AD = "discovery_multi_asset",
  DISCOVERY_CAROUSEL_AD = "discovery_carousel",

  // Google/YouTube video ads
  RESPONSIVE_VIDEO = "responsive_video",
  BUMPER_VIDEO = "bumper_video",
  OUTSTREAM_VIDEO = "outstream_video",
  NON_SKIPPABLE_IN_STREAM_VIDEO = "non_skippable_in_stream_video",
  SKIPPABLE_IN_STREAM_VIDEO = "skippable_in_stream_video",
  IN_FEED_VIDEO = "in_feed_video",
}

export enum EditAdContentType {
  GOOGLE_RSA = "GOOGLE_RSA",
}

export enum GoogleAdsAdStrength {
  PENDING = "PENDING",
  POOR = "POOR",
  AVERAGE = "AVERAGE",
  GOOD = "GOOD",
  EXCELLENT = "EXCELLENT",
}

export interface BaseAd extends MarketingMetrics, ComparisonMarketingMetrics {
  pk: number;
  name: string;
  externalId: string;
  resource: number;
  campaign: number;
  adGroup: number;
  channel: ResourceChannel;
  status: AdStatus | null;
  metricsByDate?: Record<MetricKey, ResponseTimeSeriesDatasets>;
  tags: number[];
  socialMediaPage: number | null; // null for Google Search Ads
  googleAdsAdStrength: GoogleAdsAdStrength | ""; // blank for unknown strengths and other channels
  // networks: AdNetwork[]
  // availableActions: AdStatusAction[];
  hasCampaignBudget: boolean;
  hasAdGroupBudget: boolean;
}

export interface SocialMediaPage {
  pk: number;
  name: string;
  imageSrc: string;
  url: string;
}

export interface GoogleAdHeadline {
  text: string;
  pinned: 1 | 2 | 3 | null;
}

export interface GoogleAdDescription {
  text: string;
  pinned: 1 | 2 | null;
}

export type GoogleAdsAd = Omit<BaseAd, "channel"> & {
  channel: ResourceChannel.GOOGLE_ADS;
};

export type BingAdsAd = Omit<BaseAd, "channel"> & {
  channel: ResourceChannel.BING_ADS;
};

export interface SearchAdUrlContent {
  url: string | null;
  displayUrl: string;
  path1?: string;
  path2?: string;
}

export interface SocialMediaAdBaseContent {
  text: string;
  title?: string;
  description?: string;
  destination?: string;
  callToAction?: string;
  caption?: string;
  url?: string; // snapchat uses url instead of destination
  headline?: string; // snapchat uses headline instead of title
  brandName?: string; // snapchat uses brandname instead of caption
}
export interface SocialMediaDynamicAdBaseContent {
  texts: string;
  titles?: string[];
  descriptions?: string[];
  destinations?: string[];
  callToActions?: string[];
  captions?: string[];
}

export interface SocialMediaImageContent extends SocialMediaAdBaseContent {
  imageUrl: string;
}
export interface SocialMediaVideoContent extends SocialMediaImageContent {
  videoUrl?: string;
  videoThumbnailUrl?: string;
}
export interface SocialMediaDynamicImageContent
  extends SocialMediaDynamicAdBaseContent {
  imageUrls: string[];
}
export interface SocialMediaDynamicVideoContent
  extends SocialMediaDynamicImageContent {
  videoUrls?: string[];
  videoThumbnailUrls?: string[];
}
export type SocialMediaTextOnlyContent = SocialMediaAdBaseContent;
export interface SocialMediaCarouselContent {
  cards: (SocialMediaImageContent | SocialMediaVideoContent)[];
}

export interface SearchTextAdContent<T1 = string, T2 = string>
  extends SearchAdUrlContent {
  headline1: T1;
  description1: T2;
  description2: T2;
}

export interface SearchEtaContent<T1 = string, T2 = string>
  extends SearchTextAdContent<T1, T2> {
  headline2: T1;
  headline3: T1;
}
export interface SearchRsaContent
  extends SearchEtaContent<GoogleAdHeadline, GoogleAdDescription> {
  headline4?: GoogleAdHeadline;
  headline5?: GoogleAdHeadline;
  headline6?: GoogleAdHeadline;
  headline7?: GoogleAdHeadline;
  headline8?: GoogleAdHeadline;
  headline9?: GoogleAdHeadline;
  headline10?: GoogleAdHeadline;
  headline11?: GoogleAdHeadline;
  headline12?: GoogleAdHeadline;
  headline13?: GoogleAdHeadline;
  headline14?: GoogleAdHeadline;
  headline15?: GoogleAdHeadline;
  description3?: GoogleAdDescription;
  description4?: GoogleAdDescription;
}
export interface SocialMediaVideoAd extends BaseAd {
  contentType: AdContentType.VIDEO;
  content: SocialMediaVideoContent;
}
export interface SocialMediaImageAd extends BaseAd {
  contentType: AdContentType.IMAGE;
  content: SocialMediaImageContent;
}
export interface SocialMediaDynamicImageAd extends BaseAd {
  contentType: AdContentType.DYNAMIC_IMAGE;
  content: SocialMediaDynamicImageContent;
}
export interface SocialMediaDynamicVideoAd extends BaseAd {
  contentType: AdContentType.DYNAMIC_VIDEO;
  content: SocialMediaDynamicVideoContent;
}

export interface SocialMediaCarouselAd extends BaseAd {
  contentType: AdContentType.CAROUSEL;
  content: SocialMediaCarouselContent;
}

export interface GoogleSearchRsa extends GoogleAdsAd {
  contentType: AdContentType.RSA;
  content: SearchRsaContent;
}
export interface GoogleSearchEta extends GoogleAdsAd {
  contentType: AdContentType.ETA;
  content: SearchEtaContent;
}
export interface GoogleShoppingAd extends GoogleAdsAd {
  contentType: AdContentType.SHOPPING;
  content: Record<string, never>;
  product: {
    image: string;
    name: string;
    price: number | null;
    salePrice: number | null;
    categories: string;
  };
}

export interface GoogleBaseVideoAdContent extends SearchAdUrlContent {
  videoId: string;
}
export interface GoogleResponsiveVideoAdContent
  extends GoogleBaseVideoAdContent {
  callToAction: string | null;
  longHeadline: string;
  description: string;
  headline: string | null;
}

export type GoogleBumperVideoAdContent = GoogleBaseVideoAdContent;

export interface GoogleOutstreamVideoAdContent
  extends GoogleBaseVideoAdContent {
  headline: string;
  description: string;
}

export interface GoogleNonSkippableInstreamVideoAdContent
  extends GoogleBaseVideoAdContent {
  headline: string;
  callToAction: string;
}

export type GoogleSkippableInStreamVideoAdContent =
  GoogleNonSkippableInstreamVideoAdContent;

export interface GoogleInFeedVideoAdContent extends GoogleBaseVideoAdContent {
  headline: string;
  description1: string;
  description2: string;
}

export interface GoogleResponsiveVideoAd extends GoogleAdsAd {
  contentType: AdContentType.RESPONSIVE_VIDEO;
  content: GoogleResponsiveVideoAdContent;
}
export interface GoogleBumperVideoAd extends GoogleAdsAd {
  contentType: AdContentType.BUMPER_VIDEO;
  content: GoogleBumperVideoAdContent;
}
export interface GoogleOutstreamVideoAd extends GoogleAdsAd {
  contentType: AdContentType.OUTSTREAM_VIDEO;
  content: GoogleOutstreamVideoAdContent;
}
export interface GoogleNonSkippableInstreamVideoAd extends GoogleAdsAd {
  contentType: AdContentType.NON_SKIPPABLE_IN_STREAM_VIDEO;
  content: GoogleNonSkippableInstreamVideoAdContent;
}
export interface GoogleSkippableInStreamVideoAd extends GoogleAdsAd {
  contentType: AdContentType.SKIPPABLE_IN_STREAM_VIDEO;
  content: GoogleSkippableInStreamVideoAdContent;
}
export interface GoogleInFeedVideoAd extends GoogleAdsAd {
  contentType: AdContentType.IN_FEED_VIDEO;
  content: GoogleInFeedVideoAdContent;
}

export interface GoogleDiscoveryMultiAssetAdContent extends SearchAdUrlContent {
  // Arrays indicate different options, but only one is shown at a time.
  headlines: string[];
  descriptions: string[];
  businessName: string;
  callToAction: string;
  logoImages: string[];
  images: string[];
}

export interface GoogleDiscoveryMultiAssetAd extends GoogleAdsAd {
  contentType: AdContentType.DISCOVERY_MULTI_ASSET_AD;
  content: GoogleDiscoveryMultiAssetAdContent;
}

export interface GoogleDiscoveryCarouselAdContent extends SearchAdUrlContent {
  headline: string;
  description: string;
  businessName: string;
  logoImage: string;
  cards: {
    headline: string;
    callToAction: string;
    // Arrays indicate different options, but only one is shown at a time.
    images: string[];
  }[];
}

export interface GoogleDiscoveryCarouselAd extends GoogleAdsAd {
  contentType: AdContentType.DISCOVERY_CAROUSEL_AD;
  content: GoogleDiscoveryCarouselAdContent;
}

export type GenericGoogleVideoAd =
  | GoogleResponsiveVideoAd
  | GoogleBumperVideoAd
  | GoogleOutstreamVideoAd
  | GoogleNonSkippableInstreamVideoAd
  | GoogleSkippableInStreamVideoAd
  | GoogleInFeedVideoAd;

export type GenericGoogleSearchAd =
  | GoogleSearchRsa
  | GoogleSearchEta
  | GoogleShoppingAd;

export interface BingSearchRsa extends BingAdsAd {
  contentType: AdContentType.RSA;
  content: SearchRsaContent;
}
export interface BingSearchEta extends BingAdsAd {
  contentType: AdContentType.ETA;
  content: SearchEtaContent;
}

export type GenericBingSearchAd = BingSearchRsa | BingSearchEta;

export type GenericSocialMediaAd =
  | SocialMediaVideoAd
  | SocialMediaImageAd
  | SocialMediaDynamicImageAd
  | SocialMediaDynamicVideoAd
  | SocialMediaCarouselAd;

export interface AdWithoutPreview extends BaseAd {
  contentType: "";
  content: Record<string, never>;
}

export type Ad =
  | GenericSocialMediaAd
  | GenericGoogleSearchAd
  | GenericGoogleVideoAd
  | GoogleDiscoveryMultiAssetAd
  | GoogleDiscoveryCarouselAd
  | GenericBingSearchAd
  | AdWithoutPreview;

// Type assertion functions
// Google search
export function isGoogleSearchEta(ad: Ad): ad is GoogleSearchEta {
  return (
    ad.channel === ResourceChannel.GOOGLE_ADS &&
    ad.contentType === AdContentType.ETA
  );
}
export function isGoogleSearchRsa(ad: Ad): ad is GoogleSearchRsa {
  return (
    ad.channel === ResourceChannel.GOOGLE_ADS &&
    ad.contentType === AdContentType.RSA
  );
}
export function isGoogleSearchAd(ad: Ad): ad is GenericGoogleSearchAd {
  return isGoogleSearchEta(ad) || isGoogleSearchRsa(ad);
}

export function isBingSearchEta(ad: Ad): ad is BingSearchEta {
  return (
    ad.channel === ResourceChannel.BING_ADS &&
    ad.contentType === AdContentType.ETA
  );
}
export function isBingSearchRsa(ad: Ad): ad is BingSearchRsa {
  return (
    ad.channel === ResourceChannel.BING_ADS &&
    ad.contentType === AdContentType.RSA
  );
}
export function isBingSearchAd(ad: Ad): ad is GenericBingSearchAd {
  return isBingSearchEta(ad) || isBingSearchRsa(ad);
}

// Social media
export function isSocialMediaAd(ad: Ad): ad is GenericSocialMediaAd {
  return [
    ResourceChannel.FACEBOOK_ADS,
    ResourceChannel.LINKED_IN_ADS,
    ResourceChannel.SNAPCHAT_ADS,
    ResourceChannel.TIK_TOK_ADS,
  ].includes(ad.channel);
}
export function isSocialMediaSingleImageAd(ad: Ad): ad is SocialMediaImageAd {
  return isSocialMediaAd(ad) && ad.contentType === AdContentType.IMAGE;
}
export function isSocialMediaCarouselAd(ad: Ad): ad is SocialMediaCarouselAd {
  return isSocialMediaAd(ad) && ad.contentType === AdContentType.CAROUSEL;
}
export function isSocialMediaVideoAd(ad: Ad): ad is SocialMediaVideoAd {
  return isSocialMediaAd(ad) && ad.contentType === AdContentType.VIDEO;
}
export function isSocialMediaDynamicImageAd(
  ad: Ad,
): ad is SocialMediaDynamicImageAd {
  return isSocialMediaAd(ad) && ad.contentType === AdContentType.DYNAMIC_IMAGE;
}
export function isSocialMediaDynamicVideoAd(
  ad: Ad,
): ad is SocialMediaDynamicVideoAd {
  return isSocialMediaAd(ad) && ad.contentType === AdContentType.DYNAMIC_VIDEO;
}

// Google video
export function isGoogleVideoAd(ad: Ad): ad is GenericGoogleVideoAd {
  return "videoId" in ad.content && ad.channel === ResourceChannel.GOOGLE_ADS;
}
export function isGoogleResponsiveVideoAd(
  ad: Ad,
): ad is GoogleResponsiveVideoAd {
  return (
    isGoogleVideoAd(ad) && ad.contentType === AdContentType.RESPONSIVE_VIDEO
  );
}
export function isGoogleBumperVideoAd(ad: Ad): ad is GoogleBumperVideoAd {
  return isGoogleVideoAd(ad) && ad.contentType === AdContentType.BUMPER_VIDEO;
}
export function isGoogleSkippableInStreamVideoAd(
  ad: Ad,
): ad is GoogleSkippableInStreamVideoAd {
  return (
    isGoogleVideoAd(ad) &&
    ad.contentType === AdContentType.SKIPPABLE_IN_STREAM_VIDEO
  );
}
export function isGoogleNonSkippableInstreamVideoAd(
  ad: Ad,
): ad is GoogleNonSkippableInstreamVideoAd {
  return (
    isGoogleVideoAd(ad) &&
    ad.contentType === AdContentType.NON_SKIPPABLE_IN_STREAM_VIDEO
  );
}
export function isGoogleOutstreamVideoAd(ad: Ad): ad is GoogleOutstreamVideoAd {
  return (
    isGoogleVideoAd(ad) && ad.contentType === AdContentType.OUTSTREAM_VIDEO
  );
}
export function isGoogleInFeedVideoAd(ad: Ad): ad is GoogleInFeedVideoAd {
  return isGoogleVideoAd(ad) && ad.contentType === AdContentType.IN_FEED_VIDEO;
}

export function isGoogleShoppingAd(ad: Ad): ad is GoogleShoppingAd {
  return "product" in ad && ad.product !== null;
}

export function isGoogleDiscoveryMultiAssetAd(
  ad: Ad,
): ad is GoogleDiscoveryMultiAssetAd {
  return (
    ad.channel === ResourceChannel.GOOGLE_ADS &&
    ad.contentType === AdContentType.DISCOVERY_MULTI_ASSET_AD
  );
}

export interface AdResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Ad[];
  pageTotals?: AggregatedMarketingMetrics;
  overallTotals?: AggregatedMarketingMetrics;
}

type AdsParams = {
  page?: number;
  ordering: string[];
  search: string;
  pageSize: number;
  includeTotals: boolean;
  includeMetricsByDate: boolean;
  adGroup: number | undefined;
  campaign: number | undefined;
  timeUnit: TimeUnit;
  filterCriteria?: MetricFilterSet[];
} & SelectionTemporalDataFilter &
  ResourcesDatafilter &
  TagsDataFilter &
  AdNetworkFilter &
  ConversionFilter &
  PKFilter;

function listAds({
  queryKey: [, params],
  ...context
}:
  | QueryFunctionContext<AdsParams, number | undefined>
  | QueryFunctionContext<AdsParams>): Promise<AdResponse> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);
  return api
    .get("/resources/marketing/ads/", {
      params: {
        page: "pageParam" in context ? context.pageParam : params.page,
        ...params,
        ...filterCriteria,
      },
    })
    .then((response) => response.data);
}

export const BASE_AD_METRICS: MetricKey[] = [
  "cost",
  "impressions",
  "clicks",
  "totalClicks",
  "conversions",
  "value",
  "returnOnAdSpend",
  "returnOnAdSpendRatio",
  "costPerConversion",
  "costPerClick",
  "clickthroughRate",
  "costPerMilli",
  "shares",
  "engagement",
  "comments",
  "initialVideoViews",
  "videoViews25Pct",
  "videoViews50Pct",
  "videoViews75Pct",
  "videoViews100Pct",
  "metaThruplays",
  "youtubeViews",
  "averageVideoWatchTime",
  "costPerInitialVideoView",
  "costPerMetaThruplay",
  "youtubeViewRate",
];

export function useAdsQuery({
  page,
  ordering,
  search,
  pageSize,
  includeTotals,
  includeMetricsByDate,
  adGroup,
  campaign,
  timeUnit = ref(TimeUnit.DAY),
  pks,
  enabled,
  filterCriteria,
  selectedResourceIds,
  conversionGroups,
  conversions,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
}: {
  page: Ref<number>;
  ordering: Ref<string[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  includeTotals: Ref<boolean>;
  includeMetricsByDate: Ref<boolean>;
  adGroup: Ref<number | undefined>;
  campaign?: Ref<number | undefined>;
  timeUnit: Ref<TimeUnit>;
  pks?: Ref<number[]>;
  enabled?: Ref<boolean>;
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  conversionGroups?: Ref<number[]>;
  conversions?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
}) {
  const queryKey = [
    "ads",
    {
      page,
      ordering,
      search,
      pageSize,
      includeTotals,
      includeMetricsByDate,
      campaign,
      adGroup,
      timeUnit,
      pks,
      filterCriteria,
      ...useDataFilters(
        ["subcategory", "conversionGroup", "conversion"],
        useGlobalFilters,
      ),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      ...useConversionGroups(conversionGroups, useGlobalFilters),
      ...useConversions(conversions, useGlobalFilters),
    },
  ] as const;
  useResetPage(page, queryKey, [
    "timeUnit",
    "includeTotals",
    "includeMetricsByDate",
  ]);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listAds,
      enabled: computed(() => pageSize.value > 0 && (enabled?.value ?? true)),
      placeholderData: (previousData) =>
        page.value > 1 ? previousData : undefined,
    }),
  );
}

export const useAdsQueryWithMetrics = {
  query: useAdsQuery,
  metrics: BASE_AD_METRICS,
};

export function useAdsInfiniteQuery({
  ordering,
  search,
  pageSize,
  includeTotals,
  includeMetricsByDate,
  adGroup,
  campaign,
  enabled,
  timeUnit = ref(TimeUnit.DAY),
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
}: {
  ordering: Ref<string[]>;
  search: Ref<string>;
  pageSize: Ref<number>;
  includeTotals: Ref<boolean>;
  includeMetricsByDate: Ref<boolean>;
  adGroup: Ref<number | undefined>;
  campaign?: Ref<number | undefined>;
  enabled?: Ref<boolean>;
  timeUnit: Ref<TimeUnit>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
}) {
  const queryKey = [
    "infiniteAds",
    {
      ordering,
      search,
      pageSize,
      includeTotals,
      includeMetricsByDate,
      campaign,
      adGroup,
      timeUnit,
      ...useDataFilters(
        ["subcategory", "conversionGroup", "conversion"],
        useGlobalFilters,
      ),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
    },
  ] as const;
  return reactive(
    useInfiniteQuery({
      queryKey,
      queryFn: listAds,
      enabled,
      placeholderData: keepPreviousData,
      initialPageParam: undefined,
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length + 1 : undefined,
    }),
  );
}

function listSocialMediaPages({
  queryKey: [, { accountId }],
}: QueryFunctionContext<{ accountId: number }>): Promise<SocialMediaPage[]> {
  return api
    .get(`/resources/marketing/accounts/${accountId}/social_media_pages/`)
    .then((response) => response.data);
}

export function useSocialMediaPagesQuery({
  accountId,
}: {
  accountId: Ref<number>;
}) {
  const queryKey = ["socialMediaPages", { accountId }] as const;
  return reactive(useQuery({ queryKey, queryFn: listSocialMediaPages }));
}
