<template>
  <div class="fixed bottom-0 right-0 z-50 mb-3 mr-5">
    <Menu
      as="div"
      class="relative inline-block text-left"
      @mouseenter="open = true"
      @mouseleave="open = false"
      @focusout="open = false"
      @focusin="open = true"
    >
      <Transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div v-show="open">
          <MenuItems
            static
            class="mb-2 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            <div class="p-1">
              <MenuItem
                v-for="(item, index) in menuItems"
                :key="index"
                v-slot="{ active }"
              >
                <a
                  v-if="item.link"
                  :class="[
                    active ? 'bg-purple-100 text-purple-800' : 'text-gray-900',
                  ]"
                  class="group flex w-full items-center rounded-md p-2 text-sm"
                  :href="item.link"
                  target="_blank"
                  @click="item.action"
                  @click.middle="item.action"
                >
                  <Icon :name="item.icon" class="mr-2 inline w-4" />{{
                    item.label
                  }}
                </a>
                <button
                  v-else
                  :class="[
                    active ? 'bg-purple-100 text-purple-800' : 'text-gray-900',
                  ]"
                  class="group flex w-full items-center rounded-md p-2 text-sm"
                  @click="item.action"
                >
                  <Icon :name="item.icon" class="mr-2 inline w-4" />{{
                    item.label
                  }}
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </div>
      </Transition>
      <div class="flex justify-end">
        <MenuButton
          class="flex h-10 w-10 items-center justify-center rounded-full bg-burgundy-900 shadow"
        >
          <span class="text-xl text-purple-200" aria-label="Open help menu"
            >?</span
          >
        </MenuButton>
      </div>
    </Menu>
  </div>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import Icon from "@/components/Core/Icon.vue";
import { useRoute } from "vue-router";
import { computed, ComputedRef, inject, ref, Ref } from "vue";
import GuidedTour from "@/components/Onboarding/GuidedTour.vue";
import { IconName } from "@/services/icons";
import { GUIDED_TOURS } from "@/utils/guidedTours";
import { useTrack } from "@/services/gtm";

interface MenuItemInterface {
  label: string;
  icon: IconName;
  action?: () => void;
  link?: string;
}

const guidedTourRef = inject("guidedTourRef") as Ref<
  InstanceType<typeof GuidedTour>
>;
const open = ref(false);
const route = useRoute();
const helpDeskLink = computed(() => {
  if (route.matched.some((r) => r.meta.helpDeskPath)) {
    return `https://fyr.ai/help-center/${String(route.meta.helpDeskPath)}`;
  }
  return "https://fyr.ai/help-center/";
});
const steps = computed(() => GUIDED_TOURS[route.name?.toString() ?? ""] ?? []);
const track = useTrack();
const menuItems: ComputedRef<MenuItemInterface[]> = computed(() => {
  const returnValue: MenuItemInterface[] = [
    {
      label: "Help Desk",
      icon: "book-solid",
      link: helpDeskLink.value,
      action: () => {
        track("click_help_center");
      },
    },
  ];
  if (steps.value.length > 0) {
    returnValue.push({
      label: "Guided Tour",
      icon: "info-circle",
      action: () => {
        track("guided_tour");
        guidedTourRef.value?.start();
      },
    });
  }

  return returnValue;
});
</script>
